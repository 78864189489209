import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';

import LoyalFansPromo from 'components/orderDialog/LoyalFansPromo';
import { formatLargeNumber } from 'utils/helpers';
import styles from 'styles/components/OrderDialog.module.scss';

const OrderDetails = ({
  inventoryItem,
  digitalSignature,
  network,
  space,
  assets,
  description,
  displayName,
}) => (
  <div className={styles.orderContainer}>
    <div className={styles.content}>
      <div className={styles.networkImgContainer}>
        <Image
          src={network.active_icon_url}
          width={60}
          height={60}
          alt={network.name}
          unoptimized
        />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h2 style={{ marginLeft: 10 }}>{space.name}</h2>

          <div
            style={{
              fontSize: 11,
              opacity: 0.8,
              fontFamily: 'Avenir Black',
              letterSpacing: 2.75,
              marginLeft: 10,
            }}
            className="color-main"
          >
            {network.name.toUpperCase()}
          </div>
        </div>
      </div>

      {network?.name === 'LoyalFans' && <LoyalFansPromo />}

      {digitalSignature && assets?.length > 0 && (
        <span className={styles.signatureDescription}>
          Image(s) above for illustrative purposes only.
        </span>
      )}

      <h2 className={styles.titleDetails}>{displayName}</h2>

      {!digitalSignature && (
        <div className={styles.info}>
          {network.followers > 0 && (
            <div>
              <span>Followers:</span>{' '}
              <span className="color-main">{formatLargeNumber(network.followers)}</span>
            </div>
          )}

          {inventoryItem?.min_guaranteed_views > 0 && (
            <div>
              <span>Minimum guaranteed views:</span>{' '}
              <span className="color-main">
                {formatLargeNumber(inventoryItem?.min_guaranteed_views)}
              </span>
            </div>
          )}

          {inventoryItem?.average_views > 0 && (
            <div>
              <span>Average views:</span>{' '}
              <span className="color-main">{formatLargeNumber(inventoryItem?.average_views)}</span>
            </div>
          )}
        </div>
      )}

      {space.description && (
        <p className={styles.description}>{description || space.description}</p>
      )}
    </div>
  </div>
);

OrderDetails.propTypes = {
  inventoryItem: PropTypes.object,
  digitalSignature: PropTypes.bool,
  network: PropTypes.object,
  space: PropTypes.object,
  assets: PropTypes.array,
  description: PropTypes.string,
};

OrderDetails.defaultProps = {
  digitalSignature: false,
  inventoryItem: {},
  network: {},
  space: {},
  assets: [],
  description: '',
};

export default OrderDetails;
