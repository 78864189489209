import React from 'react';
import { motion } from 'framer-motion';

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const AnimateList = ({ children, ...props }) => (
  <motion.div variants={variants} initial="closed" animate="open" {...props}>
    {children}
  </motion.div>
);

export default AnimateList;
