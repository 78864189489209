import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, InputLabel, TextField } from '@material-ui/core';
import { NumericFormat } from 'react-number-format';
import pluralize from 'pluralize';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { formatPrice, isValid } from 'utils/helpers';
import { closeModal } from 'features/utilsSlice';
import { addInventoryItem, closeInventoryItemModal } from 'features/influencerSetupSlice';
import { setExtendPriceChange } from 'utils/localStorage';
import useModalOpen from 'hooks/useModalOpen';
import PlusIcon from 'public/icons/plus-circle.svg';
import MinusIcon from 'public/icons/minus-circle.svg';
import TransitionDialog from 'components/TransitionDialog';
import BottomButtons from 'components/BottomButtons';
import styles from 'styles/components/UpdatePriceModal.module.scss';

const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
      height: '90%',
      top: '5%',
      borderRadius: 4,
      marginBottom: 'auto',
    },

    [theme.breakpoints.up('lg')]: {
      width: '40%',
    },

    [theme.breakpoints.up('xl')]: {
      top: '20%',
      width: '30%',
      height: '50%',
    },
  },

  paperFullScreenDialog: {
    width: '100%',
  },
}));

const noMargin = { margin: 0 };

const UpdatePriceModal = ({ selectedInventoryItem, correctedPrice, setCorrectedPrice }) => {
  const [percent, setPercent] = useState(0);
  const [changedInventoryItems, setChangedInventoryItems] = useState([]);

  const open = useModalOpen('Update Price');
  const dispatch = useDispatch();
  const classes = useStyles();
  const { currentInventorySpace, currentInfluencerNetwork, currentInventory } = useSelector(
    state => state.influencerSetup
  );

  const inventoryItems = get(currentInventory, 'inventory_items', []);
  const networkName = currentInventorySpace?.network?.name;
  const currentPrice = selectedInventoryItem.price;

  const handleClose = () => {
    dispatch(closeModal('Update Price'));
    setCorrectedPrice(0);
    setExtendPriceChange(true);
  };

  const formatLiveTime = item => {
    if (currentInventorySpace?.offer) {
      return item.live_time_duration === 1 && item.live_time_unit === 'hour' ? 'Hourly' : 'Daily';
    }

    if (item.live_time_unit === 'none') return `1 ${currentInventorySpace?.name}`;

    return `${item.live_time_duration} ${pluralize(item.live_time_unit, item.live_time_duration)} ${
      item.live_time_unit !== 'time' ? 'live' : ''
    }`;
  };

  const getTotalValue = price => {
    const percentOfPrice = (+price * Math.abs(percent)) / 100;
    const addedTotalPrice = +price + percentOfPrice;
    const subtractedTotalPrice = +price - percentOfPrice;
    const totalPrice = percent > 0 ? addedTotalPrice : subtractedTotalPrice;

    return Math.round(totalPrice);
  };

  const validate = inventoryItem => {
    if (!currentInventorySpace?.offer) return isValid(inventoryItem, ['description']);

    return (
      isValid(inventoryItem, ['description']) &&
      +inventoryItem.max_offer_price > +inventoryItem.min_offer_price
    );
  };

  const saveInventoryItems = () => {
    inventoryItems
      .filter(i => i.id !== selectedInventoryItem.id)
      .map(inventoryItem => {
        dispatch(
          addInventoryItem({
            ...inventoryItem,
            price:
              changedInventoryItems.includes(inventoryItem.id) && inventoryItem.changedPrice
                ? inventoryItem.changedPrice
                : getTotalValue(+inventoryItem.price),
          })
        );
      });

    dispatch(closeInventoryItemModal());
    setChangedInventoryItems([]);
    handleClose();
  };

  const overridePrice = (item, price) => {
    dispatch(
      addInventoryItem({
        ...item,
        changedPrice: price,
      })
    );

    if (changedInventoryItems.includes(item.id)) return;

    setChangedInventoryItems(prev => [...prev, item.id]);
  };

  useEffect(() => {
    const newPercent = ((correctedPrice - currentPrice) / currentPrice) * 100;

    setPercent(+newPercent.toFixed(1));
  }, [currentPrice, correctedPrice]);

  if (isEmpty(selectedInventoryItem) || !selectedInventoryItem) return null;

  return (
    <TransitionDialog
      open={open}
      handleClose={handleClose}
      paperProps={{ className: classes.paper }}
      dialogStyles={classes.paperFullScreenDialog}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <h3>Extend price change?</h3>
        </div>

        <div className={styles.info}>
          <p>
            You have chosen to {correctedPrice > currentPrice ? 'increase' : 'decrease'} the{' '}
            {networkName} {currentInventorySpace?.name} '{formatLiveTime(selectedInventoryItem)}'
            price. We can change the price of all of your other {networkName}{' '}
            {currentInventorySpace?.name} items by the same ratio if you want?
          </p>

          <div className={styles.inputContainer}>
            <InputLabel style={noMargin}>% Percentage change</InputLabel>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={() => {
                  setPercent(prevState => +(prevState - 1).toFixed(1));
                  setChangedInventoryItems([]);
                }}
              >
                <MinusIcon />
              </IconButton>

              <NumericFormat
                variant="outlined"
                value={percent}
                className={styles.input}
                customInput={TextField}
                decimalScale={1}
                suffix="%"
                disabled
                prefix={percent > 0 ? '+' : ''}
              />

              <IconButton
                onClick={() => {
                  setPercent(prevState => +(prevState + 1).toFixed(1));
                  setChangedInventoryItems([]);
                }}
              >
                <PlusIcon />
              </IconButton>
            </div>
          </div>
        </div>

        <div className={styles.inventoryItems}>
          {[...inventoryItems]
            .filter(i => i.id !== selectedInventoryItem.id)
            .map((item, index) =>
              item['_destroy'] ? null : (
                <div key={index} className={styles.priceOption}>
                  <div className={styles.availability}>
                    <Image
                      src={currentInfluencerNetwork?.network?.active_icon_url}
                      width={30}
                      height={30}
                      alt="network"
                      unoptimized
                    />

                    <h4>{formatLiveTime(item)}</h4>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }} className={styles.numbers}>
                    <span>
                      {currentInventorySpace?.offer
                        ? `${formatPrice(item.min_offer_price)} - ${formatPrice(
                            item.max_offer_price
                          )}`
                        : formatPrice(Math.round(item.price))}
                    </span>

                    <span
                      style={percent > 0 ? { color: '#57c88c' } : { color: '#ff4b4b' }}
                      className={styles.percentage}
                    >
                      {percent}%
                    </span>

                    <NumericFormat
                      variant="outlined"
                      allowNegative={false}
                      value={
                        changedInventoryItems.includes(item.id)
                          ? item.changedPrice
                          : getTotalValue(item.price)
                      }
                      onValueChange={({ floatValue }) => {
                        overridePrice(item, floatValue);

                        if (!changedInventoryItems.includes(item.id)) {
                          setChangedInventoryItems(prev => [...prev, item.id]);
                        }
                      }}
                      className={styles.input}
                      customInput={TextField}
                      prefix="$"
                    />
                  </div>
                </div>
              )
            )}
        </div>
      </div>

      <BottomButtons
        text="Skip"
        textContained="Done"
        handleClick={saveInventoryItems}
        handleClose={handleClose}
        disabled={!validate(selectedInventoryItem)}
        sticky
      />
    </TransitionDialog>
  );
};

UpdatePriceModal.propTypes = {
  selectedInventoryItem: PropTypes.object.isRequired,
  correctedPrice: PropTypes.number.isRequired,
  setCorrectedPrice: PropTypes.func.isRequired,
};

export default UpdatePriceModal;
