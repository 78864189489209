import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { closeModal } from 'features/utilsSlice';
import useModalOpen from 'hooks/useModalOpen';
import CongratulationsImage from 'public/images/congratulations.svg';
import TransitionDialog from './TransitionDialog';
import styles from 'styles/components/Congratulations.module.scss';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    backgroundColor: '#f5f0ff',
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      width: '70%',
      height: '95%',
      borderRadius: 10,
    },

    [theme.breakpoints.up('md')]: {
      width: '80%',
    },

    [theme.breakpoints.up('lg')]: {
      borderRadius: 10,
    },
  },

  paperFullWidth: {
    [theme.breakpoints.up('md')]: {
      margin: '0 auto',
      width: '60%',
      height: '95%',
      borderRadius: 10,
    },

    [theme.breakpoints.up('lg')]: {
      width: '40%',
      margin: '0 auto',
    },
  },
}));

const noBottomMargin = { marginBottom: 0 };

const InfluencerResponseDialog = () => {
  const open = useModalOpen('Influencer Response');
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal('Payment'));
    dispatch(closeModal('Cart'));
    dispatch(closeModal('Influencer Response'));
  };

  const fullWidth = { width: '100%' };

  return (
    <TransitionDialog
      open={open}
      handleClose={handleClose}
      paperProps={{ className: classes.paper }}
      dialogStyles={classes.paperFullWidth}
      desktopDirection="up"
      direction="up"
      noCloseIcon
    >
      <div className={styles.congratulations}>
        <div className={styles.title}>
          <CongratulationsImage />

          <h2 style={noBottomMargin}>Waiting for the response</h2>

          <p style={{ margin: '30px 0' }}>
            Influencer will have up to 48 hours to accept or reject this gig offer. You will be
            notified when they respond.
          </p>
        </div>

        <div className={styles.buttons}>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            style={fullWidth}
            fullWidth
          >
            Ok
          </Button>
        </div>
      </div>
    </TransitionDialog>
  );
};

export default InfluencerResponseDialog;
