import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, InputAdornment, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { postRequest } from 'utils/api';
import { setCart } from 'features/cartSlice';
import TransitionDialog from 'components/TransitionDialog';
import PinIcon from 'public/icons/pin.svg';
import styles from 'styles/components/cart/DiscountCodeDialog.module.scss';

const useStyles = makeStyles({
  paper: {
    height: '40%',
    marginTop: 'auto',
  },

  root: {
    height: '100%',
  },
});

const DiscountCodeDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const cart = useSelector(state => state.cart);

  const applyDiscountCode = () => {
    postRequest({
      endpoint: `carts/${cart.id}/cart_discount_codes`,
      showErrors: false,
      data: { code },
    })
      .then(response => {
        dispatch(setCart(response.data));
        handleClose();
      })
      .catch(() => {
        setError('Invalid discount code');
      });
  };

  return (
    <TransitionDialog
      open={open}
      handleClose={handleClose}
      paperProps={{ className: classes.paper }}
      direction="up"
      noCloseIcon
    >
      <div className={styles.discountCodeDialog}>
        <div className={styles.header}>
          <h3>Discount Code</h3>
        </div>

        <div className={styles.input}>
          <TextField
            placeholder="Enter influencer discount code"
            variant="outlined"
            className={classes.root}
            value={code}
            onChange={e => {
              setCode(e.target.value);
              if (e.target.value === '') setError('');
            }}
            onKeyUp={e => {
              if (e.key === 'Enter') applyDiscountCode();
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PinIcon width={14} height={14} alt="Pin" />
                </InputAdornment>
              ),
            }}
            autoFocus
            error={!!error}
            helperText={error}
          />
        </div>

        <div className={styles.buttons}>
          <Button onClick={handleClose} color="secondary" fullWidth>
            Cancel
          </Button>

          <Button variant="contained" color="primary" fullWidth onClick={applyDiscountCode}>
            Apply
          </Button>
        </div>
      </div>
    </TransitionDialog>
  );
};

export default DiscountCodeDialog;
