import { createSlice } from '@reduxjs/toolkit';

export const leaderboardFiltersSlice = createSlice({
  name: 'leaderboardFilters',
  initialState: {
    networkId: null,
    time: 'This year',
    stat: 'Gigs completed',
  },

  reducers: {
    setNetworkIdFilter: (state, action) => {
      state.networkId = action.payload;
    },

    setTimeFilter: (state, action) => {
      state.time = action.payload;
    },

    setStatFilter: (state, action) => {
      state.stat = action.payload;
    },
  },
});

export const { setNetworkIdFilter, setTimeFilter, setStatFilter } = leaderboardFiltersSlice.actions;

export default leaderboardFiltersSlice.reducer;
