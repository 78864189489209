import { useEffect } from 'react';
import { useRouter } from 'next/router';
import IconMainContainer from 'components/IconMainContainer';
import useIsAgency from 'hooks/useIsAgency';
import Toast from 'utils/toast';

const agencyPaths = [
  '/',
  '/dashboard',
  '/dashboard/this-week',
  '/dashboard/recently-completed',
  '/dashboard/awaiting-approval',
  '/payments',
  '/access-confirmation',
  '/notifications',
  '/agency-offers',
  '/agency-outgoing-offers',
  '/agency-invites',
  '/manage-clients',
  '/agency-wallet',
  '/agency-discover',
  '/gigs/[id]',
  '/m/[username]',
  '/terms',
];

const useAgencyRedirect = () => {
  const isAgency = useIsAgency();
  const router = useRouter();

  useEffect(() => {
    if (isAgency && !agencyPaths.includes(router.pathname)) {
      router.push('/dashboard');
      Toast(
        'error',
        'Please switch to the appropriate user from your dashboard to access this page.',
        {
          icon: <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />,
        }
      );
    }
  }, [isAgency, router]);
};

export default useAgencyRedirect;
