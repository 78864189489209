import React, { useEffect, useState, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  InputLabel,
  InputAdornment,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { postRequest } from 'utils/api';
import { getUuid } from 'utils/localStorage';
import { countryToFlag } from 'utils/helpers';
import massPayCountries from 'utils/massPayCountries';
import useCheckoutSuccess from 'hooks/useCheckoutSuccess';
import ActionCable from 'components/ActionCable/Cable';
import toast from 'utils/toast.jsx';
import IconMainContainer from 'components/IconMainContainer';
import CalendarIcon from 'public/icons/calendar.svg';
import PasswordIcon from 'public/icons/form/password.svg';
import User from 'public/icons/form/user.svg';
import styles from 'styles/components/cart/PaymentDialog.module.scss';

const useStyles = makeStyles({
  root: {
    marginBottom: 7,
    fontFamily: 'Avenir Medium',
    color: '#323643',
    letterSpacing: '-0.09px',
  },

  invalid: {
    border: '1px solid #ff4b4b',
    backgroundColor: '#fff',
  },
});

const CardForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fieldsReady, setFieldsReady] = useState(false);
  const [fieldValidity, setFieldValidity] = useState({
    name: false,
    zip: false,
    country: false,
    cvv: false,
    ccnumber: false,
    ccexp: false,
    recaptcha: process.env.NODE_ENV !== 'production',
  });
  const [touched, setTouched] = useState({
    name: false,
    zip: false,
    country: false,
  });
  const classes = useStyles();
  const cart = useSelector(state => state.cart);
  const isLoggedIn = useSelector(state => state.auth.loggedIn);
  const checkedRef = useRef(false);
  const nameRef = useRef();
  const zipRef = useRef();
  const countryRef = useRef();
  const recaptchaRef = useRef();
  const buttonDisabled = Object.values(fieldValidity).some(value => !value);
  const handleCheckoutSuccess = useCheckoutSuccess('card');

  const handleSubmit = useCallback(
    response => {
      setIsSubmitting(true);

      const recaptcha = recaptchaRef.current?.getValue();

      if (response.token) {
        const { number, exp, type } = response.card;
        const last4 = number.slice(-4);
        const uuid = getUuid();

        const endpoint = isLoggedIn
          ? `carts/${cart.id}/checkout?async=true`
          : `carts/${uuid}/guest_checkout`;

        postRequest({
          endpoint,
          data: {
            token: response.token,
            payment_method: 'card',
            recaptcha_token: recaptcha,
            save_card: checkedRef.current.checked,
            card: {
              last4,
              exp,
              type,
              billing_details: {
                name: nameRef.current.value,
                zip: zipRef.current.value,
                country: countryRef.current,
              },
            },
          },
          successMessage: '',
          errorMessage:
            'There was an error during the checkout. Please try again or contact support.',
        })
          .then(() => {
            if (isLoggedIn) {
              // Do nothing, we're waiting for the WebSocket update
            } else {
              handleCheckoutSuccess({ cart_id: cart.id });
            }
          })
          .catch(() => setIsSubmitting(false));
      } else {
        toast('error', 'There was an error validating your payment method.', {
          icon: <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />,
        });
        setIsSubmitting(false);
      }
    },
    [cart.id, isLoggedIn, handleCheckoutSuccess]
  );

  useEffect(() => {
    if (!window.CollectJS) return;

    window.CollectJS.configure({
      variant: 'inline',
      styleSniffer: true,
      callback: handleSubmit,
      customCss: {
        'border-radius': '20px',
        'border-width': '1px',
        'font-size': '16px',
        'margin-bottom': '20px',
        'font-family': 'Avenir-Roman',
        border: '1px solid #c8ccd9',
        height: '40px',
        color: '#323643',
        padding: '10px 14px 10px 35px',
      },
      placeholderCss: {
        color: '#c8ccd9',
      },
      focusCss: {
        'border-color': '#5b00db',
        'border-width': '2px',
      },
      invalidCss: {
        border: '2px solid #ff4b4b',
        'background-color': '#fff',
      },
      validationCallback: (field, status) => {
        if (status) {
          setFieldValidity(prev => ({ ...prev, [field]: true }));
        } else {
          setFieldValidity(prev => ({ ...prev, [field]: false }));
        }
      },
      fieldsAvailableCallback: () => {
        setFieldsReady(true);
        nameRef.current.focus();
      },
    });
  }, [handleSubmit]);

  return (
    <>
      <ActionCable
        channel="CheckoutChannel"
        onReceived={({ type, payload }) => {
          if (type === 'success') {
            handleCheckoutSuccess(payload);
          } else {
            console.log('Checkout error', payload);
            setIsSubmitting(false);
            toast(
              'error',
              payload?.errors?.[0] ||
                'There was an error during the checkout. Please try again or contact support.',
              {
                icon: (
                  <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />
                ),
              }
            );
          }
        }}
      />

      {!fieldsReady && <CircularProgress style={{ marginTop: 15, marginTop: 15 }} />}

      <FormGroup
        className={clsx(styles.form, { [styles.hidden]: !fieldsReady })}
        style={!isLoggedIn ? { paddingBottom: 120 } : {}}
      >
        <InputLabel className={classes.root} required>
          Name on card
        </InputLabel>

        <TextField
          inputRef={nameRef}
          name="name"
          type="text"
          placeholder="Name"
          variant="outlined"
          color="primary"
          required
          onBlur={() => setTouched(prev => ({ ...prev, name: true }))}
          onChange={e => {
            setFieldValidity(prev => ({ ...prev, name: !!e.target.value }));
          }}
          error={!fieldValidity.name && touched.name}
          InputProps={{
            classes: { root: 'card-name-input-invalid' },
            startAdornment: (
              <InputAdornment position="start">
                <User width={14} />
              </InputAdornment>
            ),
          }}
        />

        <InputLabel className={classes.root} required>
          Card number
        </InputLabel>

        <div className={styles.input}>
          <PasswordIcon />

          <div id="ccnumber" />
        </div>

        <InputLabel className={classes.root} required>
          Expiration date
        </InputLabel>

        <div className={styles.input}>
          <CalendarIcon width={16} height={16} className={styles.calendar} />
          <div id="ccexp" />
        </div>

        <InputLabel className={classes.root} required>
          Security Code
        </InputLabel>

        <div className={styles.input}>
          <PasswordIcon />
          <div id="cvv" />
        </div>

        <InputLabel className={classes.root} required>
          Zip code
        </InputLabel>

        <TextField
          inputRef={zipRef}
          name="zip"
          type="text"
          placeholder="Zip code"
          variant="outlined"
          color="primary"
          required
          onBlur={() => setTouched(prev => ({ ...prev, zip: true }))}
          onChange={e => {
            setFieldValidity(prev => ({ ...prev, zip: !!e.target.value }));
          }}
          error={!fieldValidity.zip && touched.zip}
          InputProps={{
            classes: { root: 'card-name-input-invalid' },
          }}
        />

        <InputLabel className={classes.root} required>
          Country
        </InputLabel>

        <Autocomplete
          options={massPayCountries}
          autoHighlight
          autoComplete={false}
          fullWidth
          getOptionLabel={option => option.name || ''}
          getOptionSelected={(option, value) =>
            option.three_letter_code === value.three_letter_code
          }
          onChange={(_, option) => {
            countryRef.current = option?.three_letter_code || '';
            setFieldValidity(prev => ({ ...prev, country: !!option }));
          }}
          renderOption={option => (
            <>
              <span>{countryToFlag(option.code)}</span>
              {option.name} ({option.code})
            </>
          )}
          renderInput={params => (
            <TextField
              variant="outlined"
              {...params}
              placeholder="Choose a country"
              error={!fieldValidity.country && touched.country}
              onBlur={() => setTouched(prev => ({ ...prev, country: true }))}
              inputProps={{
                style: { marginTop: '-18px' },
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />

        {process.env.NODE_ENV === 'production' && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}
            onChange={token => {
              if (token) {
                setFieldValidity(prev => ({ ...prev, recaptcha: true }));
              }
            }}
          />
        )}

        {isLoggedIn && (
          <FormControlLabel
            control={<Checkbox color="primary" inputRef={checkedRef} name="card" />}
            label="Save my payment information for my next purchase"
            style={{ marginBottom: 120 }}
          />
        )}

        <div className={styles.bottom}>
          <Button
            type="submit"
            id="payButton"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitting || buttonDisabled}
            onClick={() => setIsSubmitting(true)}
          >
            {isSubmitting ? <CircularProgress color="primary" /> : 'Complete Payment'}
          </Button>
        </div>
      </FormGroup>
    </>
  );
};

export default CardForm;
