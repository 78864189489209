import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loggedIn: false,
    currentUserChecked: false,
    currentUser: {},
    readyToCheck: false,
  },

  reducers: {
    setCurrentUser: (state, action) => {
      state.loggedIn = true;
      state.currentUser = action.payload;
    },

    signIn: (state, action) => {
      const { initial = false, ...currentUser } = action.payload;

      // State is up-to-date and we need to stop the Root component effect from trying to override it.
      if (initial && state.loggedIn) return state;

      state.loggedIn = true;
      state.currentUserChecked = true;
      state.currentUser = currentUser;
    },

    signOut: state => {
      state.loggedIn = false;
      state.currentUserChecked = false;
      state.currentUser = {};
    },

    setCurrentUserChecked: (state, action) => {
      state.currentUserChecked = action.payload;
    },

    updateCurrentUser: (state, action) => {
      state.currentUser = { ...state.currentUser, ...action.payload };
    },

    updateSelectedProfile: (state, action) => {
      state.currentUser.profile_id = action.payload;
    },

    enableTwoFactorAuth: state => {
      state.currentUser.otp_required_for_login = true;
    },

    addProfile: (state, action) => {
      state.currentUser.profiles.push(action.payload);
    },

    updateProfile: (state, action) => {
      const index = state.currentUser.profiles.findIndex(p => p.id === action.payload.id);
      const profile = state.currentUser.profiles[index];

      state.currentUser.profiles[index] = { ...profile, ...action.payload };
    },

    setReadyToCheck: (state, action) => {
      state.readyToCheck = action.payload;
    },
  },
});

export const {
  signIn,
  signOut,
  setCurrentUser,
  setCurrentUserChecked,
  setReadyToCheck,
  updateProfile,
  updateSelectedProfile,
  addProfile,
  updateCurrentUser,
  enableTwoFactorAuth,
} = authSlice.actions;

export default authSlice.reducer;
