import { createSlice } from '@reduxjs/toolkit';

export const advertiserProfileSlice = createSlice({
  name: 'advertiserProfile',
  initialState: {
    profile: {},
  },

  reducers: {
    setAdvertiserProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
});

export const { setAdvertiserProfile } = advertiserProfileSlice.actions;

export default advertiserProfileSlice.reducer;
