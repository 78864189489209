import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel } from '@material-ui/core';

import IOSSwitch from 'components/IOSSwitch';
import LocationAutocomplete from 'components/LocationAutocomplete';
import styles from 'styles/components/gigSetup/GigItemDialog.module.scss';

const EventAttendance = ({
  location,
  travelIncluded,
  mealsIncluded,
  lodgingIncluded,
  setFieldValue,
}) => (
  <>
    <LocationAutocomplete
      defaultValue={location}
      handleChange={({ location, lat, lng }) => {
        setFieldValue('location', location);
        setFieldValue('location_latitude', lat);
        setFieldValue('location_longitude', lng);
      }}
    />

    <div className={styles.switchContainer}>
      <InputLabel>Travel included</InputLabel>

      <IOSSwitch
        checked={travelIncluded}
        onChange={e => setFieldValue('travel_included', e.target.checked)}
        name="travel_included"
      />
    </div>

    <div className={styles.switchContainer}>
      <InputLabel>Lodging included</InputLabel>

      <IOSSwitch
        checked={lodgingIncluded}
        onChange={e => setFieldValue('lodging_included', e.target.checked)}
        name="lodging_included"
      />
    </div>

    <div className={styles.switchContainer}>
      <InputLabel>Meals included</InputLabel>

      <IOSSwitch
        checked={mealsIncluded}
        onChange={e => setFieldValue('meals_included', e.target.checked)}
        name="meals_included"
      />
    </div>
  </>
);

EventAttendance.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  location: PropTypes.string,
  travelIncluded: PropTypes.bool,
  mealsIncluded: PropTypes.bool,
  lodgingIncluded: PropTypes.bool,
};

EventAttendance.defaultProps = {
  location: '',
  travelIncluded: false,
  mealsIncluded: false,
  lodgingIncluded: false,
};

export default EventAttendance;
