import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { openModal, setStep } from 'features/utilsSlice';
import { getProfileOnboardingStep, setProfileOnboardingStep } from 'utils/localStorage';
import Toast from 'utils/toast';
import ShareIcon from 'public/icons/share-main.svg';
import QRCodeIcon from 'public/icons/qr-code.svg';
import IconMainContainer from 'components/IconMainContainer';
import TransitionDialog from 'components/TransitionDialog';
import QRCodeDialog from 'components/QRCodeDialog';
import styles from 'styles/components/ShareDialog.module.scss';

const useStyles = makeStyles(theme => ({
  paper: {
    height: 'fit-content',
    marginTop: 'auto',

    [theme.breakpoints.up('sm')]: {
      width: '40%',
      margin: '0 auto',
      borderRadius: 10,
    },

    [theme.breakpoints.up('md')]: {
      width: '30%',
    },

    [theme.breakpoints.up('lg')]: {
      width: '20%',
    },
  },

  paperFullScreenDialog: {
    width: '100%',
  },
}));

const ShareDialog = ({
  url,
  setOpen,
  open = false,
  heading = 'Share Profile',
  copyLinkText = 'Copy Rate Card',
  shareQrCodeText = 'Share profile QR code',
  linksUrl = '',
  wishlistUrl = '',
  fanInventoryUrl = '',
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const profileOnboardingStep = getProfileOnboardingStep();

  const handleClose = () => setOpen(false);

  return (
    <>
      <TransitionDialog
        open={open}
        handleClose={handleClose}
        paperProps={{ className: classes.paper }}
        dialogStyles={classes.paperFullScreenDialog}
        direction="up"
        desktopDirection="up"
      >
        <div className={styles.container}>
          <div className={styles.header}>{heading}</div>

          <ul>
            <CopyToClipboard text={url}>
              <li
                onClick={() => {
                  Toast('success', 'Copied profile link to clipboard!', {
                    icon: (
                      <IconMainContainer src="/icons/notifications/thumb-up.svg" alt="Thumb Up" />
                    ),
                  });

                  if (profileOnboardingStep === 2) {
                    setProfileOnboardingStep(3);
                    dispatch(setStep(3));
                  }

                  handleClose();
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ShareIcon />

                  <div className={styles.row}>
                    <span>{copyLinkText}</span>
                    <small>{url}</small>
                  </div>
                </div>
              </li>
            </CopyToClipboard>

            {linksUrl && (
              <CopyToClipboard text={linksUrl}>
                <li
                  onClick={() => {
                    Toast('success', 'Copied link page url to clipboard!', {
                      icon: (
                        <IconMainContainer src="/icons/notifications/thumb-up.svg" alt="Thumb Up" />
                      ),
                    });

                    handleClose();
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ShareIcon />

                    <div className={styles.row}>
                      <span>Copy My Link Page</span>
                      <small>{linksUrl}</small>
                    </div>
                  </div>
                </li>
              </CopyToClipboard>
            )}

            {wishlistUrl && (
              <CopyToClipboard text={wishlistUrl}>
                <li
                  onClick={() => {
                    Toast('success', 'Copied wishlist page url to clipboard!', {
                      icon: (
                        <IconMainContainer src="/icons/notifications/thumb-up.svg" alt="Thumb Up" />
                      ),
                    });

                    handleClose();
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ShareIcon />

                    <div className={styles.row}>
                      <span>Copy My Wishlist</span>
                      <small>{wishlistUrl}</small>
                    </div>
                  </div>
                </li>
              </CopyToClipboard>
            )}

            {fanInventoryUrl && (
              <CopyToClipboard text={fanInventoryUrl}>
                <li
                  onClick={() => {
                    Toast('success', 'Copied fan inventory url to clipboard!', {
                      icon: (
                        <IconMainContainer src="/icons/notifications/thumb-up.svg" alt="Thumb Up" />
                      ),
                    });

                    handleClose();
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ShareIcon />

                    <div className={styles.row}>
                      <span>Copy Fan Inventory</span>
                      <small>{fanInventoryUrl}</small>
                    </div>
                  </div>
                </li>
              </CopyToClipboard>
            )}

            {/* <li
              onClick={() => {
                dispatch(openModal({ name: 'QR Code' }));

                if (profileOnboardingStep === 2) {
                  setProfileOnboardingStep(3);
                  dispatch(setStep(3));
                }

                handleClose();
              }}
            >
              <QRCodeIcon />
              {shareQrCodeText}
            </li> */}

            {/* <li>
              <PaperAirplaneIcon />
              Share a custom quote
            </li> */}
          </ul>
        </div>
      </TransitionDialog>

      <QRCodeDialog url={url} />
    </>
  );
};

export default ShareDialog;
