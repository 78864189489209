import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, AppBar, Tabs, Tab } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';

import { closeModal } from 'features/utilsSlice';
import { getRequest } from 'utils/api';
import { formatPrice } from 'utils/helpers';
import useModalOpen from 'hooks/useModalOpen';
import TransitionDialog from './TransitionDialog';
import AffiliateTransactions from './AffiliateTransactions';
import DocumentHead from 'components/DocumentHead';
import WalletIcon from 'public/icons/wallet.svg';
import Share from 'components/Share';
import styles from 'styles/components/Wallet.module.scss';

const useStyles = makeStyles({
  root: {
    minWidth: 'fit-content',
    minHeight: 30,
    maxHeight: 30,
    borderRadius: 15,
  },

  textColorInherit: {
    textTransform: 'capitalize',
    fontFamily: 'Avenir Heavy',
    padding: '0px 10px !important',
    opacity: 1,
  },

  selected: {
    color: '#fff !important',
    backgroundColor: '#5b00db',
  },

  centered: {
    justifyContent: 'center',
  },

  colorDefault: {
    backgroundColor: 'transparent',
    borderBottom: 'none',
    padding: '0 15px',
  },

  indicator: {
    display: 'none',
  },
});

const TabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} {...other}>
    {value === index && <div>{children}</div>}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AffiliateDialog = () => {
  const [value, setValue] = useState(0);
  const open = useModalOpen('Affiliate Program');
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.currentUser);
  const [url, setUrl] = useState('');
  const [earnings, setEarnings] = useState({ total_revenue: 0, affiliates: [] });

  const handleClose = () => dispatch(closeModal('Affiliate Program'));

  const handleChange = (_event, newValue) => setValue(newValue);

  const handleChangeIndex = index => setValue(index);

  useEffect(() => {
    if (!open) return;
    getRequest({ endpoint: 'users/affiliate_earnings' })
      .then(earnings => setEarnings(earnings))
      .catch(() => {});
  }, [open]);

  useEffect(() => {
    setUrl(`${window.location.origin}/join/${currentUser.referral_code}`);
  }, [currentUser.referral_code]);

  return (
    <>
      <DocumentHead>
        <title>Referrals | GigSocial</title>
      </DocumentHead>

      <TransitionDialog open={open} handleClose={handleClose}>
        <Grid container className={styles.wallet}>
          <Grid item xs={12} sm={8} md={12}>
            <div className={styles.shareButton}>
              <Share
                url={url}
                title="Sign up on GigSocial to start making money on brand deals."
                overrideLabel="My Referral Links"
                heading="My Referral Links"
                copyLinkText="Copy referral link"
                shareQrCodeText="Show referral QR code"
              />
            </div>

            <p className={styles.affiliateBox}>
              <h3>
                Join our referral program and earn commissions on anyone that signs up through your
                link:
              </h3>

              <span>
                <strong className="color-main">25%</strong> profits for{' '}
                <strong className="color-main">the first 2yrs</strong>
              </span>

              <span style={{ display: 'flex', marginTop: 10, textAlign: 'center' }}>
                Anyone you bring on pays no GigSocial commission fees for their first 5 gigs
                &#129395;
              </span>
            </p>

            <div className={styles.header} style={{ paddingTop: 20 }}>
              <h3>Revenue</h3>

              <div className={styles.balanceBox}>
                <WalletIcon width={30} height={30} />
                {formatPrice(earnings.total_revenue)}
              </div>
            </div>

            <AppBar position="static" color="default" className={classes.colorDefault}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                classes={{
                  indicator: classes.indicator,
                  centered: classes.centered,
                }}
              >
                <Tab
                  label="Active"
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                    textColorInherit: classes.textColorInherit,
                  }}
                  disableRipple
                />

                <Tab
                  label="Previous"
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                    textColorInherit: classes.textColorInherit,
                  }}
                  disableRipple
                />
              </Tabs>
            </AppBar>

            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
              containerStyle={{
                transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
              }}
            >
              <TabPanel value={value} index={0}>
                <AffiliateTransactions transactions={earnings.affiliates.filter(e => e.active)} />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <AffiliateTransactions transactions={earnings.affiliates.filter(e => !e.active)} />
              </TabPanel>
            </SwipeableViews>
          </Grid>
        </Grid>
      </TransitionDialog>
    </>
  );
};

export default AffiliateDialog;
