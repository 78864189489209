import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, TextareaAutosize } from '@material-ui/core';
import get from 'lodash/get';
import clsx from 'clsx';

import { openModal } from 'features/utilsSlice';
import { useDispatch } from 'react-redux';
import DeleteIcon from 'public/icons/bin.svg';
import DocumentIcon from 'public/icons/document.svg';
import SearchIcon from 'public/icons/search.svg';
import AssetPreviewDialog from './AssetPreviewDialog';
import DropzoneFieldMultiple from 'components/gigSetup/DropzoneFieldMultiple';
import IOSSwitch from 'components/IOSSwitch';
import styles from 'styles/components/gigSetup/GigItemDialog.module.scss';

const marginTop = { marginTop: 10, color: '#323643' };
const marginVertical = { margin: '10px 0 10px' };
const errorPdf = { position: 'absolute', bottom: '-18px' };

const SpecialInstructions = ({
  specialInstructions,
  newSpecialInstructions,
  deletedAssetIds,
  setFieldValue,
  handleChange,
  item,
  isVideo,
  space,
  checked,
  errors,
  removeAsset,
}) => {
  const [openPreview, setOpenPreview] = useState(false);
  const dispatch = useDispatch();

  const getAssetType = asset => {
    let type = asset.file.type;

    if (isVideo(type)) {
      return (
        <a href={asset.preview} target="_blank" rel="noreferrer">
          <video width={60} height={60} src={asset.preview}></video>
        </a>
      );
    } else if (type === 'application/pdf') {
      return (
        <div className={styles.uploadPdf}>
          <DocumentIcon width={35} className="hover-icon" />
          <span>{asset.file.name}</span>
        </div>
      );
    }

    return (
      <div
        onClick={() => {
          setOpenPreview(true);
          dispatch(
            openModal({
              name: 'Asset Preview',
              data: { src: asset.preview },
            })
          );
        }}
        className={clsx('hover', 'asset-container')}
      >
        <img src={asset.preview} width={60} height={60} alt="Asset" />
        <SearchIcon className="search-icon" />
      </div>
    );
  };

  return (
    <>
      {!space.license && (
        <div className={styles.switchContainer}>
          <InputLabel>Special instructions{space.event_attendance && ' / Notes'}</InputLabel>

          <IOSSwitch
            checked={checked.special_instructions}
            onChange={e => handleChange(e, setFieldValue)}
            name="special_instructions"
          />
        </div>
      )}

      {checked.special_instructions && (
        <>
          <TextareaAutosize
            value={specialInstructions}
            onChange={e => setFieldValue('special_instructions', e.target.value)}
            minRows={4}
            maxRows={4}
            placeholder="Special instructions"
            className={clsx({
              ['error-border']: !specialInstructions && checked.special_instructions,
            })}
          />

          {!specialInstructions && checked.special_instructions && (
            <span className="error-msg" style={{ marginTop: -5 }}>
              is required.
            </span>
          )}

          <InputLabel style={marginTop}>
            Upload special instructions assets <span className="fw-n">(optional)</span>
          </InputLabel>

          <DropzoneFieldMultiple
            performDirectUpload
            uploads={newSpecialInstructions}
            accept="image/jpg, image/jpeg, image/png, image/heic, image/heif, video/mov, video/mpeg, video/mp4, video/quicktime, application/pdf"
            setUploads={uploads =>
              setFieldValue('new_special_instructions_assets', [
                ...newSpecialInstructions,
                ...uploads,
              ])
            }
            compress
          />

          <div style={marginVertical}>File types allowed: jpg, jpeg, mpeg, mp4, mov, pdf</div>

          <div className={styles.assets}>
            {item?.special_instructions_assets
              ?.filter(asset => !deletedAssetIds.includes(asset.id))
              ?.map(asset => (
                <div key={asset.id} className={styles.asset}>
                  <div
                    className={styles.iconContainer}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeAsset(asset);
                    }}
                  >
                    <DeleteIcon className="hover-icon" />
                  </div>

                  {asset.video && (
                    <a href={asset.url} target="_blank" rel="noreferrer">
                      <video width={60} height={60} src={asset.url}></video>
                    </a>
                  )}

                  {asset.content_type === 'application/pdf' ? (
                    <div className={styles.uploadPdf}>
                      <DocumentIcon width={35} className="hover-icon" />
                      <span>{asset.name}</span>
                    </div>
                  ) : (
                    !asset.video && <img src={asset.url} width={60} height={60} alt="Asset" />
                  )}
                </div>
              ))}

            {newSpecialInstructions.map((asset, index) => (
              <div key={index} className={styles.asset}>
                <div
                  className={styles.iconContainer}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    const specialInstructionsAssets = newSpecialInstructions;
                    specialInstructionsAssets.splice(index, 1);

                    setFieldValue('new_special_instructions_assets', specialInstructionsAssets);
                  }}
                >
                  <DeleteIcon className="hover-icon" />
                </div>

                {getAssetType(asset)}

                <span
                  className={isVideo ? styles.videoError : styles.error}
                  style={asset.file.type === 'application/pdf' ? errorPdf : {}}
                >
                  {get(errors, 'new_special_instructions_assets', [])[index]?.file}
                </span>
              </div>
            ))}
          </div>
        </>
      )}

      {openPreview && <AssetPreviewDialog />}
    </>
  );
};

SpecialInstructions.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isVideo: PropTypes.func.isRequired,
  space: PropTypes.object.isRequired,
  checked: PropTypes.object.isRequired,
  item: PropTypes.object,
  errors: PropTypes.object,
  specialInstructions: PropTypes.string,
  newSpecialInstructions: PropTypes.array,
};

SpecialInstructions.defaultProps = {
  item: {},
  errors: {},
  specialInstructions: '',
  newSpecialInstructions: [],
};

export default SpecialInstructions;
