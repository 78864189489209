import React from 'react';
import { closeModal } from 'features/utilsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import useModalOpen from 'hooks/useModalOpen';
import SelectCategory from './influencerSetup/SelectCategory';
import TransitionDialog from './TransitionDialog';
import SelectAudience from './influencerSetup/SelectAudience';
import SelectExperience from './influencerSetup/SelectExperience';
import ProfileVerification from './profileVerification/ProfileVerification';
import Networks from './influencerSetup/Networks';
import SelectInventory from './influencerSetup/SelectInventory';
import SelectTraits from './influencerSetup/SelectTraits';
import styles from 'styles/components/influencerSetup/InfluencerSetup.module.scss';

const paddingBottom = { paddingBottom: 20 };

const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },

    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
  },

  paperFullScreenDialog: {
    width: '100%',
  },
}));

const InventoryEditDialog = ({ fetchInventories }) => {
  const dispatch = useDispatch();
  const open = useModalOpen('Inventory Edit');
  const step = useSelector(state => state.utils.modalData['Inventory Edit']?.step);
  const fanOnly = useSelector(state => state.utils.modalData['Inventory Edit']?.fanOnly);
  const classes = useStyles();

  const handleClose = () => {
    if (fetchInventories && step !== 'Profile Verification') {
      fetchInventories();
    }

    dispatch(closeModal('Inventory Edit'));
  };

  return (
    <TransitionDialog
      open={open}
      handleClose={handleClose}
      paperProps={{ className: classes.paper }}
      dialogStyles={classes.paperFullScreenDialog}
      noCloseIcon={step === 'Profile Verification' || step === 'Traits'}
      styles={styles}
    >
      {step === 'Category' && (
        <div className={styles.container} style={{ paddingTop: 0 }}>
          <SelectCategory handleSuccess={handleClose} showSteps={false} stepButtonText="Done" />
        </div>
      )}

      {step === 'Audience' && (
        <div className={styles.container} style={{ paddingTop: 0 }}>
          <SelectAudience
            handleSuccess={handleClose}
            showSteps={false}
            stepButtonText="Done"
            shouldAnimate={false}
          />
        </div>
      )}

      {step === 'Experience' && (
        <div className={styles.container} style={{ paddingTop: 0 }}>
          <SelectExperience
            handleSuccess={handleClose}
            showSteps={false}
            stepButtonText="Done"
            shouldAnimate={false}
          />
        </div>
      )}

      {step === 'Networks' && (
        <div className={styles.container} style={{ paddingTop: 0, paddingBottom: 130 }}>
          <Networks
            stepButtonText="Done"
            handleSuccess={handleClose}
            networksStyle={paddingBottom}
            showSteps={false}
            isDialog
          />
        </div>
      )}

      {step === 'Inventory' && (
        <div className={styles.container} style={{ paddingTop: 0, paddingBottom: 130 }}>
          <SelectInventory
            fanOnly={!!fanOnly}
            showSteps={false}
            stepButtonText="Done"
            handleSuccess={handleClose}
          />
        </div>
      )}

      {step === 'Traits' && (
        <div className={styles.container} style={{ paddingTop: 0 }}>
          <SelectTraits handleSuccess={handleClose} noSteps showButtons isDialog />
        </div>
      )}

      {step === 'Profile Verification' && (
        <div className={styles.container} style={{ paddingTop: 0 }}>
          <ProfileVerification
            handleSuccess={handleClose}
            showSteps={false}
            handleClose={handleClose}
            isDialog
          />
        </div>
      )}
    </TransitionDialog>
  );
};

export default InventoryEditDialog;
