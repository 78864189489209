import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import ShareIcon from 'public/icons/profile/share.svg';
import MainShareIcon from 'public/icons/share-main.svg';
import Toast from 'utils/toast';
import IconMainContainer from 'components/IconMainContainer';
import ShareDialog from 'components/ShareDialog';

const height = { height: 40 };

const Share = ({
  url,
  linksUrl,
  wishlistUrl,
  fanInventoryUrl,
  isGig,
  overrideLabel = null,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const regularLabel = isGig ? 'Share gig' : 'Share';
  const label = overrideLabel || regularLabel;

  return (
    <>
      {isGig ? (
        <CopyToClipboard text={url}>
          <MainShareIcon
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              Toast('success', 'Copied gig link to clipboard!', {
                icon: <IconMainContainer src="/icons/notifications/thumb-up.svg" alt="Thumb Up" />,
              });
            }}
            width={14}
            height={17}
            alt="Share"
          />
        </CopyToClipboard>
      ) : (
        <Button
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(true);
          }}
          style={height}
          color="primary"
          variant="contained"
          endIcon={<ShareIcon />}
        >
          {label}
        </Button>
      )}

      <ShareDialog
        linksUrl={linksUrl}
        fanInventoryUrl={fanInventoryUrl}
        wishlistUrl={wishlistUrl}
        open={open}
        setOpen={setOpen}
        url={url}
        {...props}
      />
    </>
  );
};

Share.propTypes = {
  url: PropTypes.string.isRequired,
  isGig: PropTypes.bool,
};

Share.defaultProps = {
  isGig: false,
};

export default Share;
