import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import ActionCable from 'components/ActionCable/Cable';
import IconMainContainer from 'components/IconMainContainer';
import { postRequest } from 'utils/api';
import { getUuid } from 'utils/localStorage';
import toast from 'utils/toast';
import useCheckoutSuccess from 'hooks/useCheckoutSuccess';

const ApplePayForm = () => {
  const cart = useSelector(state => state.cart);
  const isLoggedIn = useSelector(state => state.auth.loggedIn);
  const handleCheckoutSuccess = useCheckoutSuccess('apple_pay');

  const handleSubmit = useCallback(
    response => {
      if (response.token) {
        const uuid = getUuid();
        const endpoint = isLoggedIn
          ? `carts/${cart.id}/checkout?async=true`
          : `carts/${uuid}/guest_checkout`;

        const { cardDetails, billingInfo } = response.wallet;

        postRequest({
          endpoint,
          data: {
            payment_method: 'apple_pay',
            token: response.token,
            card: {
              last4: cardDetails,
              billing_details: {
                name: `${billingInfo?.firstName} ${billingInfo?.lastName}`,
                zip: billingInfo?.postalCode,
                country: billingInfo?.country,
              },
            },
          },
          successMessage: '',
          errorMessage:
            'There was an error during the checkout. Please try again or contact support.',
        })
          .then(() => {
            if (isLoggedIn) {
              // Do nothing, we're waiting for the WebSocket update
            } else {
              handleCheckoutSuccess({ cart_id: cart.id });
            }
          })
          .catch(error => console.log('error', error));
      } else {
        toast('error', 'There was an error validating your payment method.', {
          icon: <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />,
        });
      }
    },
    [cart.id, handleCheckoutSuccess, isLoggedIn]
  );

  useEffect(() => {
    if (!window.CollectJS) return;

    window.CollectJS.configure({
      callback: response => {
        handleSubmit(response);
      },
      country: 'US',
      currency: 'USD',
      price: cart.total_checkout_price,
      fields: {
        applePay: {
          selector: '#apple-pay-button',
          requiredBillingContactFields: ['name'],
          requiredBillingContactFields: ['postalAddress'],
          requiredShippingContactFields: [],
          contactFields: [],
          // contactFields: ['phone', 'email'],
          // contactFieldsMappedTo: 'billing',
          lineItems: cart.cart_items.map(item => ({
            label: `${item.network?.name} - ${item.inventory_space?.name}`,
            amount: item.price,
          })),
          totalLabel: 'Total Price',
          type: 'buy',
          style: {
            'button-style': 'black',
            height: '50px',
            'border-radius': '5px',
          },
        },
      },
    });
  }, [cart.total_checkout_price, cart.cart_items, handleSubmit]);

  return (
    <>
      <ActionCable
        channel="CheckoutChannel"
        onReceived={({ type, payload }) => {
          if (type === 'success') {
            handleCheckoutSuccess(payload);
          } else {
            console.log('Checkout error', payload);
            toast(
              'error',
              payload?.errors?.[0] ||
                'There was an error during the checkout. Please try again or contact support.',
              {
                icon: (
                  <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />
                ),
              }
            );
          }
        }}
      />

      <div id="apple-pay-button" style={{ margin: '20px 0', padding: '0 15px' }}></div>
    </>
  );
};

export default ApplePayForm;
