import React, { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { putRequest } from 'utils/api';
import EnableIcon from 'public/icons/eye-enable.svg';
import DisableIcon from 'public/icons/eye-disable.svg';

const buttonStyle = {
  textTransform: 'capitalize',
  fontSize: 14,
  fontFamily: 'Avenir',
  fontWeight: 900,
  padding: 0,
  justifyContent: 'flex-end',
};

const enableStyle = {
  color: '#12dfc7',
  ...buttonStyle,
};

const disableStyle = {
  color: '#ff4b4b',
  ...buttonStyle,
};

const iconStyle = { marginRight: 3 };

const ActivityToggleButton = ({
  active = false,
  key = 'active',
  endpoint,
  resourceKey,
  callback,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);

    putRequest({ endpoint, data: { [resourceKey]: { [key]: !active } } })
      .then(response => {
        if (callback) callback(response.data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
      <Button style={active ? disableStyle : enableStyle} onClick={handleSubmit}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {active ? <DisableIcon style={iconStyle} /> : <EnableIcon style={iconStyle} />}
            {active ? 'Disable' : 'Enable'}
          </>
        )}
      </Button>
    </div>
  );
};

export default ActivityToggleButton;
