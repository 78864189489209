import { API_URL } from 'utils/api';

const directUpload = ({ file, handleProgress }) => {
  const DirectUpload = require('@rails/activestorage').DirectUpload;

  const upload = new DirectUpload(file, `${API_URL}/rails/active_storage/direct_uploads`, {
    directUploadWillStoreFileWithXHR: request => {
      if (handleProgress) {
        request.upload.addEventListener('progress', event => handleProgress(event));
      }
    },
  });

  return new Promise((resolve, reject) => {
    upload.create((error, blob) => {
      if (error) {
        reject(error);
      } else {
        resolve(blob);
      }
    });
  });
};

export default directUpload;
