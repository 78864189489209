import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { MenuItem, MenuList, Avatar, Divider } from '@material-ui/core';
import clsx from 'clsx';

import { openModal } from 'features/utilsSlice';
import { openCrispChat } from 'utils/helpers';
import NoAvatarImage from 'public/images/no-avatar.svg';
import TransitionDialog from 'components/TransitionDialog';
import ExternalLink from 'components/ExternalLink';
import Settings from './Settings';
import styles from 'styles/components/sidebar/DropdownList.module.scss';

const avatarFullSize = { width: '100%', height: '100%' };

const AffiliateSidebar = ({ handleClose, handleLogout, open }) => {
  const currentUser = useSelector(state => state.auth.currentUser);
  const dispatch = useDispatch();

  return (
    <TransitionDialog open={open} handleClose={handleClose} styles={styles}>
      <div className={styles.menuContainer}>
        <div className={styles.profileInfo}>
          <Avatar className={styles.img} src={currentUser.avatar_url}>
            <NoAvatarImage style={avatarFullSize} />
          </Avatar>

          <h3>{currentUser.display_name}</h3>
        </div>

        <Divider />

        <MenuList className={clsx(styles.menuList, styles.bold)} autoFocusItem={open}>
          <MenuItem onClick={handleClose}>Wallet</MenuItem>
        </MenuList>

        <Divider />

        <MenuList className={styles.menuList}>
          <MenuItem
            onClick={() => {
              dispatch(openModal({ name: 'Settings' }));
              handleClose;
            }}
          >
            Account information
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <ExternalLink
              preview="url"
              mobileUrl="https://gigsocial.crisp.help/en-us/"
              desktopUrl="https://gigsocial.crisp.help/en-us/"
              target="_blank"
              rel="noreferrer noopener"
              style={{ color: 'unset' }}
              page="FAQ"
            >
              FAQ
            </ExternalLink>
          </MenuItem>

          <MenuItem
            onClick={() => {
              openCrispChat();
              handleClose();
            }}
          >
            Support
          </MenuItem>
          <MenuItem onClick={handleClose}>Terms</MenuItem>
          <MenuItem onClick={handleClose}>Privacy policy</MenuItem>
        </MenuList>

        <Divider />

        <span onClick={handleLogout} className={styles.logout}>
          Logout
        </span>
      </div>

      <Settings />
    </TransitionDialog>
  );
};

AffiliateSidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default AffiliateSidebar;
