import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Link from 'next/link';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';

import { isServer } from 'utils/helpers';
import useIsAgency from 'hooks/useIsAgency';
import useIsInfluencer from 'hooks/useIsInfluencer';
import WhiteLogo from 'public/images/logo-white.svg';
import AlertIcon from 'public/icons/alert.svg';
import CartIcon from 'components/cart/CartIcon';
import Badge from 'components/Badge';
import Sidebar from 'components/sidebar/Sidebar';
import NavLink from 'components/NavLink';
import SwitchPrimaryInterest from 'components/SwitchPrimaryInterest';
import styles from 'styles/components/home/Header.module.scss';

const alertStyle = { position: 'absolute', height: 24 };

const MobileLoggedInHeader = ({ newStyles = {} }) => {
  const { asPath } = useRouter();
  const router = useRouter();
  const currentUser = useSelector(state => state.auth.currentUser);
  const isBuyer = currentUser.primary_interest === 'buy';
  const isAgency = useIsAgency();
  const isInfluencer = useIsInfluencer();
  const unseenNotificationsCount = useSelector(state => state.utils.unseenNotificationsCount);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const showPrimaryInterestSwitch = isInfluencer && !currentUser?.full_management_agency;

  const isHome =
    router.pathname === '/' || router.pathname === '/shoutout' || router.pathname === '/shoutoutb';
  const home = '/dashboard';
  const isDiscover = asPath.includes('/discover');

  useEffect(() => {
    const handleScroll = () => {
      if (isServer()) return;

      // eslint-disable-next-line no-undef
      const header = document.getElementById('header');

      if (!header) return;

      // eslint-disable-next-line no-undef
      if (window.scrollY > 50 && desktop && isHome) {
        header.classList.add('smaller-header');
      } else {
        header.classList.remove('smaller-header');
      }
    };

    // eslint-disable-next-line no-undef
    window.addEventListener('scroll', handleScroll);

    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('scroll', handleScroll);
  }, [desktop, isHome]);

  // useEffect(() => {
  //   if (currentInterest !== primaryInterest) setPrimaryInterest(currentInterest);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentInterest]);

  return (
    <header
      className={clsx(
        styles.header,
        { [newStyles.header]: !isEmpty(newStyles) },
        { [styles.noBorder]: isDiscover }
      )}
      id="header"
    >
      <div className={styles.headerContent} style={isAgency ? { maxWidth: 'initial' } : {}}>
        {showPrimaryInterestSwitch ? (
          <div className={styles.switchButton}>
            <SwitchPrimaryInterest />
          </div>
        ) : (
          <Link href={home} passHref>
            <div className={clsx(styles.logoImg, styles.loggedInPosition)}>
              <WhiteLogo />
            </div>
          </Link>
        )}

        <NavLink href="/alerts" style={alertStyle}>
          <Badge
            content={unseenNotificationsCount}
            badgeStyles={{
              top: '-5px',
              right: '12px',
              border: '1px solid #ebedf2',
            }}
          >
            <AlertIcon />
          </Badge>
        </NavLink>

        <Sidebar />

        {(!isInfluencer || isBuyer) && <CartIcon />}
      </div>
    </header>
  );
};

export default MobileLoggedInHeader;
