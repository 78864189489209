import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import toast from 'utils/toast.jsx';
import { incrementCount } from 'features/gigsSlice';
import { getRequest } from 'utils/api';
import { closeModal, openModal, setRoleFilter } from 'features/utilsSlice';
import { setCart } from 'features/cartSlice';
import { trackAhoyEvent } from 'utils/ahoy';
import IconMainContainer from 'components/IconMainContainer';
import useCurrentUser from './useCurrentUser';
import useIsInfluencer from './useIsInfluencer';

const useCheckoutSuccess = (method = 'card') => {
  const router = useRouter();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.auth.loggedIn);
  const currentUser = useCurrentUser();
  const isInfluencer = useIsInfluencer();
  const redirectUrl = currentUser?.full_management_agency ? '/agency-offers' : '/dashboard';

  const handleSuccess = useCallback(
    (payload = {}) => {
      dispatch(closeModal('Payment'));
      dispatch(closeModal('Cart'));

      isLoggedIn
        ? dispatch(openModal({ name: 'Influencer Response' }))
        : dispatch(openModal({ name: 'Which Are You' }));

      if (!isLoggedIn) return;

      getRequest({
        endpoint: 'carts/current',
      })
        .then(response => {
          dispatch(incrementCount('orders'));
          dispatch(setCart(response));
        })
        .catch(() => {});

      if (isLoggedIn && isInfluencer) dispatch(setRoleFilter('advertiser'));
      router.push(redirectUrl);

      trackAhoyEvent('Cart Checkout', {
        payment_method: method,
        ...payload,
      });

      toast('success', 'Your order has been placed successfully!', {
        icon: <IconMainContainer src="/icons/notifications/thumb-up.svg" alt="Thumb Up" />,
      });
    },
    [dispatch, isLoggedIn, isInfluencer, router, method, redirectUrl]
  );

  return handleSuccess;
};

export default useCheckoutSuccess;
