import { createSlice } from '@reduxjs/toolkit';

const defaultInventory = {
  weekly_availability: 10,
  inventory_items: [],
};

const defaultInventoryItem = {
  live_time_unit: '',
  live_time_duration: '',
  min_guaranteed_views: 0,
  average_views: 0,
  price: '',
};

export const influencerSetupSlice = createSlice({
  name: 'influencerSetup',
  initialState: {
    networks: [],
    influencerNetworks: [],
    inventories: [],
    inventorySpaces: [],
    step: 1,
    previousStep: 0,
    inventoryModalOpen: false,
    inventoryItemModalOpen: false,
    currentInfluencerNetwork: null,
    currentInventory: defaultInventory,
    currentInventorySpace: null,
    currentInventoryItem: defaultInventoryItem,
  },

  reducers: {
    addNetworks: (state, action) => {
      state.networks = action.payload;
    },

    addInfluencerNetworks: (state, action) => {
      state.influencerNetworks = action.payload;
    },

    addInventories: (state, action) => {
      state.inventories = action.payload;
    },

    addInventorySpaces: (state, action) => {
      state.inventorySpaces = action.payload;
    },

    nextStep: state => {
      state.step += 1;
      state.previousStep = state.step - 1;
    },

    goToStep: (state, action) => {
      state.previousStep = state.step;
      state.step = action.payload;

      if (action.payload === 1) {
        state.currentInfluencerNetwork = null;
        state.inventories = [];
        state.inventorySpaces = [];
      }
    },

    setStep: (state, action) => {
      state.step = action.payload;
    },

    openInventoryModal: (state, { payload: { inventory, inventorySpace } }) => {
      state.inventoryModalOpen = true;
      state.currentInventorySpace = inventorySpace;
      state.currentInventory = inventory || defaultInventory;
    },

    closeInventoryModal: state => {
      state.inventoryModalOpen = false;
      state.currentInventory = defaultInventory;
    },

    openInventoryItemModal: (state, action) => {
      state.inventoryItemModalOpen = true;
      state.currentInventoryItem = action.payload || {
        ...defaultInventoryItem,
        index: state.currentInventory.inventory_items.length,
      };
    },

    closeInventoryItemModal: state => {
      state.inventoryItemModalOpen = false;
      state.currentInventoryItem = defaultInventoryItem;
    },

    selectInfluencerNetwork: (state, action) => {
      state.currentInfluencerNetwork = action.payload;
    },

    addInventoryItem: (state, { payload }) => {
      state.currentInventory.inventory_items[payload.index] = payload;
      state.inventoryItemModalOpen = false;
      state.currentInventoryItem = defaultInventoryItem;
    },

    updateInventory: (state, action) => {
      state.currentInventory = { ...state.currentInventory, ...action.payload };
    },

    updateInventoryItem: (state, action) => {
      state.currentInventoryItem = { ...state.currentInventoryItem, ...action.payload };
    },

    destroyInventoryItem: (state, action) => {
      if (state.currentInventory.inventory_items[action.payload]?.id) {
        state.currentInventory.inventory_items[action.payload]['_destroy'] = true;
      } else {
        state.currentInventory.inventory_items.splice(action.payload, 1);
      }
    },

    updateInventoryInfluencerNetwork: (state, action) => {
      state.inventories
        .filter(i => i.influencer_network.id === action.payload.id)
        .forEach(i => {
          i.influencer_network = { ...i.influencer_network, ...action.payload };
        });
    },
  },
});

export const {
  addNetworks,
  addInfluencerNetworks,
  addInventories,
  updateInventoryInfluencerNetwork,
  addInventorySpaces,
  nextStep,
  goToStep,
  setStep,
  openInventoryModal,
  closeInventoryModal,
  openInventoryItemModal,
  closeInventoryItemModal,
  selectInfluencerNetwork,
  addInventoryItem,
  updateInventory,
  updateInventoryItem,
  destroyInventoryItem,
} = influencerSetupSlice.actions;

export default influencerSetupSlice.reducer;
