import get from 'lodash/get';
import startCase from 'lodash/startCase';
import qs from 'qs';
import axios from 'utils/axios';
import toast from 'utils/toast.jsx';

import IconMainContainer from 'components/IconMainContainer';

export const API_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:5000';
const HEADERS = { 'Content-Type': 'application/json' };

export const handleError = ({ error, errorMessage, groupErrors = false, setErrors = null }) => {
  const { response } = error;
  const { error: serverError } = response.data;

  if (serverError) {
    toast('error', serverError, {
      icon: <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />,
    });
    return;
  }

  if (errorMessage) {
    toast('error', errorMessage, {
      icon: <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />,
    });
    return;
  }

  if (response.status === 401) {
    toast('error', 'You are not authorized to perform that action.', {
      icon: <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />,
    });
    return;
  }

  if (response.status > 401 && response.status < 500) {
    const { errors } = response.data;

    if (Array.isArray(errors)) {
      if (groupErrors) {
        const fullMessages = errors.map(message => message).join('<br />');
        toast('error', fullMessages, {
          icon: <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />,
        });
      } else {
        errors.map(message =>
          toast('error', message, {
            icon: <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />,
          })
        );
      }
    } else {
      const keys = Object.keys(errors);

      if (keys) {
        if (setErrors) {
          toast('error', 'Please fix the form errors', {
            icon: <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />,
          });
          setErrors(errors);
        } else if (groupErrors) {
          const fullMessages = keys
            .map(key =>
              key === 'base' || key.includes('.base')
                ? errors[key][0]
                : `<strong>${startCase(key)}:</strong> ${errors[key][0]}`
            )
            .join('<br />');

          toast('error', fullMessages, {
            icon: <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />,
          });
        } else {
          keys.forEach(key => {
            const message =
              key === 'base' || key.includes('.base') ? errors[key][0] : `${key} ${errors[key][0]}`;
            toast('error', message, {
              icon: (
                <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />
              ),
            });
          });
        }
      } else {
        toast('error', 'There was an error processing your request.', {
          icon: <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />,
        });
      }
    }
  }
};

export const postRequest = ({
  endpoint,
  data,
  config = { headers: HEADERS },
  successMessage = '',
  errorMessage = '',
  setSubmitting = null,
  setErrors = null,
  groupErrors = false,
  successCallback = null,
  showErrors = true,
  version = 'v1',
}) => {
  const path = version ? `${version}/${endpoint}` : endpoint;
  const url = `${API_URL}/${path}`;

  if (setSubmitting) setSubmitting(true);

  return axios
    .post(url, data, config)
    .then(response => {
      if (setSubmitting) setSubmitting(false);
      if (successMessage)
        toast('success', successMessage, {
          icon: <IconMainContainer src="/icons/notifications/thumb-up.svg" alt="Thumb Up" />,
        });
      if (successCallback) successCallback(response);

      return Promise.resolve(response);
    })
    .catch(error => {
      if (setSubmitting) setSubmitting(false);

      if (showErrors) {
        handleError({
          error,
          errorMessage,
          setErrors,
          groupErrors,
        });
      }

      return Promise.reject(error);
    });
};

export const getRequest = ({
  endpoint,
  query = {},
  version = 'v1',
  headers = {},
  resolveResponse = false,
}) => {
  const path = version ? `${version}/${endpoint}` : endpoint;
  const queryString = qs.stringify(query, { arrayFormat: 'brackets' });
  const url = `${API_URL}/${path}?${queryString}`;

  return axios
    .get(url, { headers })
    .then(response => {
      const data = response.status === 204 ? [] : response.data;
      return Promise.resolve(resolveResponse ? response : data);
    })
    .catch(error => Promise.reject(error));
};

export const putRequest = ({
  endpoint,
  data,
  config = { headers: HEADERS },
  successMessage = '',
  errorMessage = '',
  setSubmitting = null,
  setErrors = null,
  method = 'PUT',
  groupErrors = false,
  showErrors = true,
  version = 'v1',
}) => {
  const path = version ? `${version}/${endpoint}` : endpoint;
  const url = `${API_URL}/${path}`;

  if (setSubmitting) setSubmitting(true);

  return axios({
    url,
    data,
    method,
    ...config,
  })
    .then(response => {
      if (setSubmitting) setSubmitting(false);
      if (successMessage)
        toast('success', successMessage, {
          icon: <IconMainContainer src="/icons/notifications/thumb-up.svg" alt="Thumb Up" />,
        });

      return Promise.resolve(response);
    })
    .catch(error => {
      if (setSubmitting) setSubmitting(false);

      if (showErrors) {
        handleError({
          error,
          errorMessage,
          setErrors,
          groupErrors,
        });
      }

      return Promise.reject(get(error, 'response.data.errors', error));
    });
};

export const deleteRequest = ({
  endpoint,
  version = 'v1',
  successMessage = '',
  errorMessage = '',
}) => {
  const path = version ? `${version}/${endpoint}` : endpoint;
  const url = `${API_URL}/${path}`;

  return new Promise((resolve, reject) => {
    axios
      .delete(url)
      .then(response => {
        if (successMessage)
          toast('success', successMessage, {
            icon: <IconMainContainer src="/icons/notifications/thumb-up.svg" alt="Thumb Up" />,
          });
        resolve(response);
      })
      .catch(error => {
        handleError({ error, errorMessage });
        reject(get(error, 'response.data.errors', error));
      });
  });
};
