import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { InputLabel, Button, InputAdornment } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';
import pick from 'lodash/pick';
import has from 'lodash/has';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';
import 'react-phone-number-input/style.css';

import { normalizeInitialValues } from 'utils/helpers';
import { putRequest, postRequest } from 'utils/api';
import { updateCurrentUser, updateProfile } from 'features/authSlice';
import { closeModal } from 'features/utilsSlice';
import { setProfile } from 'features/profilesSlice';
import useCurrentProfile from 'hooks/useCurrentProfile';
import useModalOpen from 'hooks/useModalOpen';
import User from 'public/icons/form/user.svg';
import Password from 'public/icons/form/password.svg';
import Email from 'public/icons/form/email.svg';
import Switch from 'components/Switch';
import TransitionDialog from 'components/TransitionDialog';
import styles from 'styles/components/ProfileForm.module.scss';

const defaultRequired = 'is required.';
const fullScreen = { width: '100%', height: '100%' };
const marginBottom = { marginBottom: 20 };
const padding = { padding: '0 20px 120px' };
const marginTop = { marginTop: 23 };

const schema = yup.object({
  first_name: yup.string().required(defaultRequired),
  last_name: yup.string().required(defaultRequired),
  display_name: yup.string().required(defaultRequired),
  contact_email: yup.string().when('influencer', influencer => {
    if (influencer) {
      return yup
        .string()
        .email('is not a valid email')
        .required('is required');
    }

    return yup.string();
  }),
  phone: yup.number().when('influencer', influencer => {
    if (influencer) {
      return yup.number().required(defaultRequired);
    }

    return yup.number();
  }),
  contact_phone: yup.number().when('influencer', influencer => {
    if (influencer) {
      return yup.number();
    }

    return yup.number().required(defaultRequired);
  }),
  company_title: yup.string().when('influencer', influencer => {
    if (influencer) {
      return yup.string();
    }

    return yup.string().required(defaultRequired);
  }),
  notification_type: yup.string(),
  password: yup.string().min(6, 'Must be at least 6 characters'),
  influencer: yup.bool().required(defaultRequired),
  password_confirmation: yup
    .string()
    .min(6, 'must contain at least 6 characters')
    .oneOf([yup.ref('password')], "Passwords don't match"),
  email: yup.string().when('influencer', influencer => {
    if (influencer) {
      return yup.string();
    }

    return yup
      .string()
      .email('is not a valid email')
      .required('is required');
  }),
  marketing_emails_consent: yup.bool(),
});

const initialValues = {
  first_name: '',
  last_name: '',
  display_name: '',
  password: '',
  password_confirmation: '',
  email: '',
  contact_email: '',
  phone: undefined,
  notification_type: 'sms',
  contact_phone: '',
  contact_phone_ext: '',
  company_title: '',
  influencer: false,
  marketing_emails_consent: false,
};

const Settings = () => {
  const [confirmationSent, setConfirmationSent] = useState(false);
  const open = useModalOpen('Settings');
  const user = useSelector(state => state.auth.currentUser);
  const influencer = user.role === 'influencer';
  const currentProfile = useCurrentProfile();
  const dispatch = useDispatch();

  const handleClose = () => dispatch(closeModal('Settings'));

  const handleSubmit = (values, actions) => {
    const data = new FormData(); // eslint-disable-line no-undef

    const keys = Object.keys(
      pick(values, [
        'first_name',
        'last_name',
        'password',
        'password_confirmation',
        'notification_type',
        'display_name',
        ...(influencer
          ? ['phone', 'contact_email']
          : ['contact_phone', 'contact_phone_ext', 'company_title', 'email']),
      ])
    );

    keys.forEach(key => {
      if (values[key]) data.set(`user[${key}]`, values[key]);
    });

    data.set('user[marketing_emails_consent]', values.marketing_emails_consent);

    putRequest({
      endpoint: `users/${user.id}`,
      data,
      successMessage: `Successfully updated your Account.`,
      ...actions,
    })
      .then(response => {
        dispatch(updateCurrentUser(response.data));

        if (influencer) {
          dispatch(
            updateProfile({
              id: currentProfile.id,
              display_name: response.data.display_name,
            })
          );

          dispatch(
            setProfile({
              ...currentProfile,
              display_name: response.data.display_name,
            })
          );
        }

        handleClose();
      })
      .catch(() => {});
  };

  const handleResend = () => {
    const login = user.phone ? user.phone : user.email;

    setConfirmationSent(true);

    postRequest({
      endpoint: 'confirmations',
      data: { user: { login } },
      version: null,
      successMessage: `We just sent you a confirmation link. Please check your ${
        influencer ? 'phone' : 'email'
      }.`,
    }).catch(() => {});
  };

  const opacity = { opacity: 0.5 };

  return (
    <TransitionDialog open={open} styles={styles} handleClose={handleClose} noCloseIcon>
      <div className={styles.container}>
        <Formik
          validationSchema={schema}
          initialValues={normalizeInitialValues({
            ...initialValues,
            ...user,
            influencer,
          })}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ isSubmiting, values, actions, setFieldValue, handleBlur, touched, errors }) => (
            <Form style={fullScreen}>
              <div className={styles.form} style={padding}>
                <h3>Account Information</h3>

                <InputLabel>
                  First Name <span className="fw-n">(not shared publicly)</span>
                </InputLabel>

                <Field
                  component={TextField}
                  name="first_name"
                  type="text"
                  placeholder="First Name"
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User width={14} />
                      </InputAdornment>
                    ),
                  }}
                />

                <InputLabel>
                  Last Name <span className="fw-n">(not shared publicly)</span>
                </InputLabel>

                <Field
                  component={TextField}
                  name="last_name"
                  type="text"
                  placeholder="Last Name"
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User width={14} />
                      </InputAdornment>
                    ),
                  }}
                />

                {influencer ? (
                  <>
                    <PhoneInput
                      name="phone"
                      international
                      placeholder="Phone Number"
                      value={values.phone}
                      onChange={phone => setFieldValue('phone', phone)}
                      onBlur={handleBlur}
                      className={clsx(styles.phoneInput, {
                        [styles.phoneInputError]: has(errors, 'phone', false),
                      })}
                      style={opacity}
                      disabled
                    />

                    <ErrorMessage name="phone" component="span" className={styles.error} />

                    <div style={{ marginTop: -10 }}>
                      <span style={{ marginLeft: 14, opacity: 0.6, fontSize: 12 }}>
                        Email to receive some notifications and receipts
                      </span>

                      <Field
                        component={TextField}
                        name="contact_email"
                        type="email"
                        placeholder="Email Address"
                        variant="outlined"
                        color="primary"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email width={14} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <InputLabel>
                      Email <span className="fw-n">(not shared publicly)</span>
                    </InputLabel>
                    <Field
                      component={TextField}
                      name="email"
                      type="email"
                      placeholder="Email Address"
                      variant="outlined"
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email width={14} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}

                {!user.confirmed && (
                  <div className={styles.notVerified}>
                    <span>{influencer ? 'Phone' : 'Email'} not verified</span>{' '}
                    {!confirmationSent && (
                      <Button variant="contained" color="primary" onClick={() => handleResend()}>
                        Resend {influencer ? 'message' : 'email'}
                      </Button>
                    )}
                  </div>
                )}

                {influencer && user.country === 'United States' && (
                  <div style={marginBottom}>
                    <InputLabel>Which way of communication do you prefer?</InputLabel>

                    <Switch
                      firstOption={{ label: 'SMS', value: 'sms' }}
                      secondOption={{ label: 'WhatsApp', value: 'whatsapp' }}
                      setValue={value => setFieldValue('notification_type', value)}
                      value={values.notification_type}
                    />
                  </div>
                )}

                <InputLabel>
                  Display Name ({user.role === 'influencer' ? 'Name of your page' : 'Company Name'})
                </InputLabel>

                <Field
                  component={TextField}
                  name="display_name"
                  type="text"
                  placeholder={`Display Name ${
                    user.role === 'influencer' ? 'Name of your page' : 'Company Name'
                  }`}
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User width={14} />
                      </InputAdornment>
                    ),
                  }}
                />

                {!influencer && (
                  <>
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ width: '70%' }}>
                        <InputLabel>
                          Contact Phone <span className="fw-n">(not shared publicly)</span>
                        </InputLabel>

                        <PhoneInput
                          name="contact_phone"
                          placeholder="Contact Phone"
                          value={values.contact_phone}
                          onBlur={handleBlur}
                          onChange={phone => setFieldValue('contact_phone', phone)}
                          defaultCountry="US"
                          defaultChecked
                          required
                          className={clsx(styles.phoneInput, {
                            [styles.phoneInputError]:
                              has(errors, 'contact_phone', false) && touched.contact_phone,
                          })}
                        />

                        <ErrorMessage
                          name="contact_phone"
                          component="div"
                          className={styles.error}
                          style={marginTop}
                        />
                      </div>

                      <div style={{ width: '28%' }}>
                        <InputLabel>Extension</InputLabel>

                        <Field
                          component={TextField}
                          id="search_contact_phone_ext"
                          name="contact_phone_ext"
                          type="text"
                          placeholder="Ext"
                          variant="outlined"
                          color="primary"
                          autoComplete="off"
                        />
                      </div>
                    </div>

                    <InputLabel>
                      Company Title <span className="fw-n">(not shared publicly)</span>
                    </InputLabel>

                    <Field
                      component={TextField}
                      name="company_title"
                      type="string"
                      placeholder="Company Title"
                      variant="outlined"
                      color="primary"
                    />
                  </>
                )}

                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="marketing_emails_consent"
                  Label={{ label: 'I agree to receive updates and discounts to this email' }}
                  color="primary"
                />

                <h3>Change Password?</h3>

                <Field
                  component={TextField}
                  name="password"
                  type="password"
                  placeholder="New Password"
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Password width={14} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  component={TextField}
                  name="password_confirmation"
                  type="password"
                  placeholder="Repeat Password"
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Password width={14} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className={styles.buttons}>
                <Button onClick={handleClose} color="primary" fullWidth>
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit(values, actions)}
                  disabled={isSubmiting || !isEmpty(errors)}
                  fullWidth
                >
                  Done
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </TransitionDialog>
  );
};

export default Settings;
