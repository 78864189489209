import React from 'react';
import { useMediaQuery } from '@material-ui/core';

import DesktopLoggedInHeader from './DesktopLoggedInHeader';
import MobileLoggedInHeader from './MobileLoggedInHeader';

const LoggedInHeader = () => {
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  return isDesktop ? <DesktopLoggedInHeader /> : <MobileLoggedInHeader />;
};

export default LoggedInHeader;
