import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { Grid, Button, InputAdornment, CircularProgress } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';
import clsx from 'clsx';
import * as yup from 'yup';
import 'react-phone-number-input/style.css';
import isEmpty from 'lodash/isEmpty';

import { signIn } from 'features/authSlice';
import { postRequest } from 'utils/api';
import { closeModal, setRoleFilter } from 'features/utilsSlice';
import { getTokenFromResponse, identifyFullStoryUser, setCrispUser } from 'utils/helpers';
import {
  saveAccessToken,
  setLastSignInRole,
  getLastSignInRole,
  getUuid,
  deleteUuid,
  setCurrentUserLS,
} from 'utils/localStorage';
import useScrollToTop from 'hooks/useScrollToTop';
import Password from 'public/icons/form/password.svg';
import PasswordEyeIcon from 'public/icons/eye-purple.svg';
import SlashEyeIcon from 'public/icons/eye-hide-purple.svg';
import Email from 'public/icons/form/email.svg';
import Switch from 'components/Switch';
import styles from 'styles/components/auth/AuthForm.module.scss';

const defaultRequired = 'is required.';
const marginBottom = { marginBottom: 30 };
const width = { width: '100%' };

const schema = yup.object().shape({
  email: yup.string().when('role', role => {
    if (role === 'influencer') {
      return yup.string();
    }

    return yup
      .string()
      .email('is not a valid email.')
      .required(defaultRequired);
  }),

  phone: yup.number().when('role', role => {
    if (role === 'advertiser') {
      return yup.number().nullable();
    }

    return yup.number().required(defaultRequired);
  }),

  password: yup
    .string()
    .required(defaultRequired)
    .min(6, 'Must contain at least 6 characters'),
  checked: yup.boolean(),
  role: yup.string().required(),
});

const initialValues = {
  phone: undefined,
  email: '',
  password: '',
  checked: false,
  role: 'influencer',
};

const GuestLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState('influencer');
  const dispatch = useDispatch();
  const uuid = getUuid();

  useScrollToTop();

  const handleClickShowPassword = () => setShowPassword(prev => !prev);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleSubmit = ({ phone, email, password, checked }, actions) => {
    setIsLoading(true);

    postRequest({
      endpoint: 'sign_in',
      data: {
        user: {
          login: role === 'influencer' ? phone : email,
          password,
          remember_me: checked,
        },
        uuid,
      },
      errorMessage: `Invalid ${role === 'influencer' ? 'phone' : 'email'} or password.`,
      version: null,
      ...actions,
    })
      .then(response => {
        const token = getTokenFromResponse(response);

        if (token) saveAccessToken(token);

        setLastSignInRole(response.data.role);

        dispatch(signIn(response.data));
        setCurrentUserLS(response.data);
        setCrispUser(response.data);
        identifyFullStoryUser(response.data);

        if (uuid) {
          deleteUuid();
          dispatch(closeModal('Login'));

          dispatch(setRoleFilter('advertiser'));
        } else {
          dispatch(setRoleFilter(response.data.role));
        }

        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    const lastSignInRole = getLastSignInRole();

    if (lastSignInRole) {
      setRole(lastSignInRole);
    } else {
      setRole('influencer');
    }
  }, []);

  return (
    <Grid container className={styles.guestContainer} direction="column" alignItems="center">
      <h1 style={{ fontSize: 24 }}>Returning Customers</h1>

      <span style={{ margin: '20px 0 30px' }}>
        Sign in to save time and link the order to your account.
      </span>

      <Grid item xs={11} sm={6} md={11} xl={7} style={width}>
        <div style={marginBottom}>
          <Switch
            firstOption={{ label: 'Influencer', value: 'influencer' }}
            secondOption={{ label: 'Advertiser', value: 'advertiser' }}
            setValue={value => setRole(value)}
            value={role}
          />
        </div>

        <Formik
          validationSchema={schema}
          initialValues={{ ...initialValues, role }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ handleBlur, setFieldValue, values, touched, errors, isSubmiting }) => (
            <Form className={styles.form}>
              {role === 'influencer' ? (
                <>
                  <PhoneInput
                    name="phone"
                    placeholder="Phone Number"
                    onChange={phone => setFieldValue('phone', phone)}
                    value={values.phone}
                    onBlur={handleBlur}
                    defaultCountry="US"
                    defaultChecked
                    className={clsx(styles.phoneInput, {
                      [styles.phoneInputError]: touched.phone && !values.phone,
                    })}
                  />

                  <ErrorMessage component="span" className={styles.error} name="phone" />
                </>
              ) : (
                <>
                  <Field
                    component={TextField}
                    name="email"
                    type="email"
                    placeholder="Email"
                    variant="outlined"
                    color="primary"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email width={14} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              )}

              <Field
                component={TextField}
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                placeholder="Password"
                variant="outlined"
                color="primary"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Password width={14} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        style={{ height: 20, cursor: 'pointer' }}
                      >
                        {showPassword ? (
                          <PasswordEyeIcon width={20} height={20} />
                        ) : (
                          <SlashEyeIcon width={20} height={20} />
                        )}
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="checked"
                Label={{ label: 'Remember me' }}
                color="primary"
              />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading || isSubmiting || !isEmpty(errors)}
                fullWidth
              >
                {isLoading ? <CircularProgress color="primary" /> : 'Login'}
              </Button>
            </Form>
          )}
        </Formik>

        <div className={styles.bottomLinks} style={{ marginTop: 0 }}>
          <Link href="/forgot-password">Forgot password?</Link>
        </div>
      </Grid>
    </Grid>
  );
};

export default GuestLogin;
