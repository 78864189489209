import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';

import { inventoryRedirectTo } from 'utils/helpers';
import { goToStep } from 'features/influencerSetupSlice';
import { goToStep as goToStepGig } from 'features/gigSetupSlice';
import { openModal } from 'features/utilsSlice';
import useIsSeller from 'hooks/useIsSeller';
import styles from 'styles/components/Stepper.module.scss';

const Stepper = ({
  handleStepChange,
  disabled,
  steps,
  isLoading,
  newStyles,
  showNextButton = true,
  buttonText = 'Next',
  showSteps = true,
  skipStep = false,
  setOpenConfirmation,
}) => {
  const isSeller = useIsSeller();
  const currentStep = useSelector(state =>
    isSeller ? state.influencerSetup?.step : state.gigSetup?.step
  );
  const dispatch = useDispatch();
  const router = useRouter();

  const handleStepClick = step => {
    if (currentStep >= step && isSeller) {
      dispatch(goToStep(step));
      inventoryRedirectTo(step, router);
    } else if (currentStep >= step && !isSeller) {
      dispatch(goToStepGig(step));
    }
  };

  return (
    <div className={clsx(styles.wrapper, { [newStyles]: !isEmpty(newStyles) })}>
      <div className={styles.container}>
        <div style={{ display: 'flex' }}>
          {skipStep && (
            <Button
              onClick={() => {
                dispatch(openModal({ name: 'Confirmation', data: { action: 'approve' } }));
                setOpenConfirmation(true);
              }}
              color="primary"
              disabled={isLoading}
              fullWidth
            >
              {isLoading ? <CircularProgress color="primary" size={30} /> : 'Skip this step'}
            </Button>
          )}

          {showNextButton && (
            <Button
              onClick={handleStepChange}
              variant="contained"
              color="primary"
              disabled={disabled || isLoading}
              fullWidth
            >
              {isLoading ? <CircularProgress color="primary" size={30} /> : buttonText}
            </Button>
          )}
        </div>

        {showSteps && (
          <div
            className={clsx(styles.stepper, { [newStyles?.stepper]: newStyles || {} })}
            style={steps.length < 4 ? { justifyContent: 'center' } : {}}
          >
            {steps.map(step => (
              <div
                className={clsx(
                  styles.step,
                  { [styles.active]: currentStep >= step },
                  { [styles.hover]: currentStep >= step },
                  { [styles.width]: steps }
                )}
                style={steps.length < 4 ? { marginRight: 10 } : {}}
                onClick={() => handleStepClick(step)}
                key={step}
              ></div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

Stepper.propTypes = {
  handleStepChange: PropTypes.func,
  active: PropTypes.number,
  disabled: PropTypes.bool,
  steps: PropTypes.array,
  isLoading: PropTypes.bool,
  newStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  skipStep: PropTypes.bool,
};

Stepper.defaultProps = {
  handleStepChange: null,
  active: 1,
  disabled: false,
  steps: [1, 2, 3],
  isLoading: false,
  newStyles: '',
  skipStep: false,
};

export default Stepper;
