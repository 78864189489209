import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button } from '@material-ui/core';

import ActionCable from 'components/ActionCable/Cable';
import toast from 'utils/toast.jsx';
import IconMainContainer from 'components/IconMainContainer';
import useCheckoutSuccess from 'hooks/useCheckoutSuccess';
import { getRequest, postRequest } from 'utils/api';
import styles from 'styles/components/cart/SavedCards.module.scss';

const paddingBottom = { paddingBottom: 140 };
const noPadding = { paddingBottom: 0 };

const SavedCards = ({ cardId, setCardId }) => {
  const cart = useSelector(state => state.cart);
  const [cards, setCards] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isLoggedIn = useSelector(state => state.auth.loggedIn);
  const handleCheckoutSuccess = useCheckoutSuccess('saved_card');

  useEffect(() => {
    getRequest({ endpoint: 'credit_cards' })
      .then(cards => {
        setCards(cards);
        setCardId(cards[0]?.id);
      })
      .catch(() => {});

    return () => {
      setCardId(null);
    };
  }, [setCardId]);

  const handleSubmit = () => {
    setIsSubmitting(true);

    postRequest({
      endpoint: `carts/${cart.id}/checkout?async=true`,
      data: {
        payment_method: 'card',
        card_id: cardId,
      },
      successMessage: '',
      errorMessage: 'There was an error during the checkout. Please try again or contact support.',
    })
      .then(() => {
        if (isLoggedIn) {
          // Do nothing, we're waiting for the WebSocket update
        } else {
          handleCheckoutSuccess({ cart_id: cart.id });
        }
      })
      .catch(error => {
        console.log('error', error);
        setIsSubmitting(false);
      });
  };

  if (!cards.length) return null;

  return (
    <div className={styles.container} style={!cardId ? noPadding : paddingBottom}>
      <ActionCable
        channel="CheckoutChannel"
        onReceived={({ type, payload }) => {
          if (type === 'success') {
            handleCheckoutSuccess(payload);
          } else {
            console.log('Checkout error', payload);
            setIsSubmitting(false);
            toast(
              'error',
              payload?.errors?.[0] ||
                'There was an error during the checkout. Please try again or contact support.',
              {
                icon: (
                  <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />
                ),
              }
            );
          }
        }}
      />
      <RadioGroup
        aria-label="Saved Card ID"
        name="card_id"
        value={cardId}
        onChange={e => {
          setCardId(+e.target.value);
        }}
      >
        {cards.map(card => (
          <div key={card.id} className={styles.card} onClick={() => setCardId(card.id)}>
            <FormControlLabel value={card.id} control={<Radio />} label="" />

            <div className={styles.cardInfo}>
              <div className={styles.brand}>
                {card.network} ****{card.last4}
              </div>
              <div className={styles.name}>{card.name}</div>
              <div className={styles.exp}>Expires {card.exp}</div>
            </div>
          </div>
        ))}
      </RadioGroup>

      {cardId && (
        <Button
          classes={{ root: styles.addButton }}
          variant="outlined"
          color="secondary"
          onClick={() => setCardId(null)}
        >
          + ADD CARD
        </Button>
      )}

      {cardId && (
        <div className={styles.bottom}>
          <Button
            type="submit"
            id="payButton"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Complete Payment
          </Button>
        </div>
      )}
    </div>
  );
};

export default SavedCards;
