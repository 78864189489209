import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import { closeModal } from 'features/utilsSlice';
import useModalOpen from 'hooks/useModalOpen';
import TransitionDialog from 'components/TransitionDialog';
import BottomButtons from 'components/BottomButtons';

const useStyles = makeStyles(theme => ({
  paper: {
    height: '50%',
    marginTop: 'auto',

    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },

  root: {
    margin: '30px auto',
    width: '90%',
  },
}));

const SignatureDialog = ({ variableQuantity, setFieldValue, min, max }) => {
  const [count, setCount] = useState(variableQuantity);
  const open = useModalOpen('Signature Count');
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleClose = () => dispatch(closeModal('Signature Count'));

  useEffect(() => {
    setCount(variableQuantity);
  }, [variableQuantity]);

  const invalid = count < min || count > max;

  return (
    <TransitionDialog
      open={open}
      handleClose={handleClose}
      paperProps={{ className: classes.paper }}
      direction="up"
      desktopDirection="left"
      noCloseIcon
    >
      <div style={{ padding: 15, border: 'solid 1px rgba(0, 0, 0, 0.15)' }}>
        <h4 style={{ fontSize: 16, textAlign: 'center' }}>Count</h4>
      </div>

      <TextField
        type="number"
        value={count}
        onChange={e => setCount(e.target.value ? +e.target.value : '')}
        variant="outlined"
        classes={{ root: classes.root }}
        className="larger-input"
        error={invalid}
        helperText={invalid && `Print count needs to be between ${min} and ${max}`}
      />

      <BottomButtons
        text="Cancel"
        textContained="Apply"
        disabled={invalid}
        handleClose={handleClose}
        handleClick={() => {
          setFieldValue('variable_quantity', count);
          handleClose();
        }}
      />
    </TransitionDialog>
  );
};

export default SignatureDialog;
