import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { InputLabel, Slider } from '@material-ui/core';
import * as yup from 'yup';
import pick from 'lodash/pick';

import { closeModal } from 'features/utilsSlice';
import { normalizeInitialValues } from 'utils/helpers';
import { putRequest } from 'utils/api';
import { updateProfile } from 'features/authSlice';
import useModalOpen from 'hooks/useModalOpen';
import useCurrentProfile from 'hooks/useCurrentProfile';
import Switch from 'components/Switch';
import LocationAutocomplete from 'components/LocationAutocomplete';
import TransitionDialog from 'components/TransitionDialog';
import BottomButtons from 'components/BottomButtons';
import styles from 'styles/components/sidebar/DropdownList.module.scss';

const radiusStyle = { display: 'flex', justifyContent: 'space-between' };
const titleStyle = { fontSize: 24 };
const labelStyle = { marginTop: 20, marginBottom: 10 };
const marginTop = { marginTop: 20 };
const defaultRequired = 'is required.';

const schema = yup.object({
  location: yup.string().required(defaultRequired),
  location_latitude: yup.number().required(defaultRequired),
  location_longitude: yup.number().required(defaultRequired),
  shipping_address: yup.string().required(defaultRequired),
  location_radius: yup.number(),
  location_based_gigs: yup.bool(),
  physical_product_gigs: yup.bool(),
});

const initialValues = {
  location: '',
  location_latitude: 0,
  location_longitude: 0,
  shipping_address: '',
  location_radius: 50,
  location_based_gigs: true,
  physical_product_gigs: false,
  adult: false,
};

const GigSettingsDialog = () => {
  const [isLoading, setIsLoading] = useState(false);
  const open = useModalOpen('Gig Settings');
  const profile = useCurrentProfile();
  const dispatch = useDispatch();

  const handleClose = () => dispatch(closeModal('Gig Settings'));

  const handleSubmit = (values, actions) => {
    setIsLoading(true);

    putRequest({
      endpoint: `profiles/${profile.id}`,
      data: {
        profile: pick(values, [
          'location_based_gigs',
          'physical_product_gigs',
          'location',
          'location_latitude',
          'location_longitude',
          'location_radius',
          'shipping_address',
          'adult',
        ]),
      },
      successMessage: `Successfully updated your gig settings.`,
      ...actions,
    })
      .then(response => {
        dispatch(updateProfile(response.data));
        setIsLoading(false);
        handleClose();
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <TransitionDialog open={open} handleClose={handleClose} noCloseIcon>
      <Formik
        validationSchema={schema}
        initialValues={normalizeInitialValues({
          ...initialValues,
          ...profile,
          location_radius: profile?.location_radius === 0 ? 50 : profile?.location_radius,
        })}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ values, actions, setFieldValue }) => (
          <>
            <Form className={styles.form} style={{ paddingTop: 20 }}>
              <h2 style={titleStyle}>Gig Settings</h2>

              <InputLabel style={labelStyle}>Are you willing to do on-location gigs?</InputLabel>

              <Switch
                firstOption={{ label: 'No', value: false }}
                secondOption={{ label: 'Yes', value: true }}
                setValue={value => setFieldValue('location_based_gigs', value)}
                value={values.location_based_gigs}
              />

              {values.location_based_gigs && (
                <>
                  <LocationAutocomplete
                    defaultValue={values.location}
                    handleChange={({ location, lat, lng }) => {
                      setFieldValue('location', location);
                      setFieldValue('location_latitude', lat);
                      setFieldValue('location_longitude', lng);
                    }}
                    style={marginTop}
                  />

                  <InputLabel style={radiusStyle}>
                    Radius
                    <span className="color-main">{values.location_radius} miles</span>
                  </InputLabel>

                  <Slider
                    value={values.location_radius}
                    onChange={(_, newValue) => setFieldValue('location_radius', newValue)}
                    min={50}
                    max={500}
                    step={10}
                  />
                </>
              )}

              <InputLabel style={labelStyle}>
                Are you willing to accept gigs that require a product to be shipped to you?
              </InputLabel>

              <Switch
                firstOption={{ label: 'No', value: false }}
                secondOption={{ label: 'Yes', value: true }}
                setValue={value => {
                  setFieldValue('physical_product_gigs', value);
                  if (value === false) setFieldValue('shipping_address', '');
                }}
                value={values.physical_product_gigs}
              />

              {values.physical_product_gigs && (
                <LocationAutocomplete
                  defaultValue={values.shipping_address}
                  handleChange={({ location }) => setFieldValue('shipping_address', location)}
                  style={marginTop}
                  address
                />
              )}

              <div>
                <InputLabel style={labelStyle}>
                  Are you willing to sell to OnlyFans models?
                </InputLabel>

                <Switch
                  firstOption={{ label: 'Yes', value: true }}
                  secondOption={{ label: 'No', value: false }}
                  setValue={value => setFieldValue('adult', value)}
                  value={values.adult}
                />
              </div>
            </Form>

            <BottomButtons
              text="Cancel"
              textContained="Save"
              handleClose={handleClose}
              handleClick={() => handleSubmit(values, actions)}
              disabled={
                (!values.location && values.location_based_gigs) ||
                (values.location_based_gigs && values.location_radius === 0) ||
                (values.physical_product_gigs && !values.shipping_address)
              }
              isLoading={isLoading}
            />
          </>
        )}
      </Formik>
    </TransitionDialog>
  );
};

export default GigSettingsDialog;
