import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { setLatestAnnouncementId } from 'utils/localStorage';
import { trackAhoyEvent } from 'utils/ahoy';
import styles from 'styles/components/Notification.module.scss';

const AnnouncementNotification = ({ announcement }) => {
  const handleClose = () => {
    setLatestAnnouncementId(announcement.id);
  };

  const handleClick = () => {
    if (announcement.url) {
      window.location.href = announcement.url;
    }

    trackAhoyEvent('Announcement Clicked', { id: announcement.id });

    handleClose();
  };

  return (
    <div className={clsx(styles.notification)}>
      <span className={clsx(styles.icon)}>
        <Image
          src="/icons/notifications/default.svg"
          alt="image"
          width={30}
          height={30}
          unoptimized
        />
      </span>

      <Link href={announcement.url || ''} passHref>
        <div className={styles.text} onClick={handleClick}>
          <h3>{announcement.title}</h3>

          <p>
            <span dangerouslySetInnerHTML={{ __html: announcement.description }}></span>

            {announcement.url && <span className={styles.viewMore}> Click to view more</span>}
          </p>
        </div>
      </Link>

      <IconButton className={styles.close} onClick={handleClose}>
        <Image src="/icons/close-main.svg" alt="close" width={12} height={12} unoptimized />
      </IconButton>
    </div>
  );
};

export default AnnouncementNotification;
