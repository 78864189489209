import { createSlice } from '@reduxjs/toolkit';

export const profilesSlice = createSlice({
  name: 'profiles',
  initialState: {
    mapping: {},
    followingIds: [],
  },

  reducers: {
    setProfile: (state, action) => {
      state.mapping[action.payload.username_lower] = {
        ...state.mapping[action.payload.username],
        ...action.payload,
      };
    },

    toggleInventoryActive: (state, action) => {
      const profile = state.mapping[action.payload.username];
      const inventory = profile?.inventories.find(i => i.id === action.payload.id);

      if (inventory) {
        inventory.active = action.payload.active;
      }
    },

    toggleInfluencerNetworkActive: (state, action) => {
      const profile = state.mapping[action.payload.username];

      profile?.inventories
        .filter(i => i.influencer_network.id === action.payload.id)
        .forEach(i => {
          i.influencer_network.active = action.payload.active;
        });
    },

    updateInfluencerNetwork: (state, action) => {
      const profile = state.mapping[action.payload.profileUsername];

      profile?.inventories
        .filter(i => i.influencer_network.id === action.payload.id)
        .forEach(i => {
          i.influencer_network = { ...i.influencer_network, ...action.payload };
        });
    },

    setFollowingIds: (state, action) => {
      state.followingIds = action.payload;
    },

    follow: (state, action) => {
      state.followingIds.push(action.payload);
    },

    unfollow: (state, action) => {
      state.followingIds = state.followingIds.filter(id => id !== action.payload);
    },
  },
});

export const {
  setProfile,
  toggleInfluencerNetworkActive,
  toggleInventoryActive,
  updateInfluencerNetwork,
  setFollowingIds,
  follow,
  unfollow,
} = profilesSlice.actions;

export default profilesSlice.reducer;
