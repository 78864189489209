import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Button } from '@material-ui/core';

import { signOut } from 'features/authSlice';
import { resetCrispSession } from 'utils/helpers';
import { deleteRequest } from 'utils/api';
import { deleteAccessToken, setCurrentUserLS } from 'utils/localStorage';
import useIsAgency from 'hooks/useIsAgency';
import HamburgerIcon from 'public/icons/hamburger.svg';
import AdvertiserSidebar from './AdvertiserSidebar';
import AffiliateSidebar from './AffiliateSidebar';
import AgencySidebar from './AgencySidebar';
import InfluencerMobileSidebar from './InfluencerMobileSidebar';
import styles from 'styles/components/sidebar/DropdownMenu.module.scss';

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const currentUser = useSelector(state => state.auth.currentUser);
  const isAgency = useIsAgency();

  const handleToggle = () => setOpen(prevOpen => !prevOpen);

  const handleLogout = () => {
    deleteRequest({
      endpoint: 'sign_out',
      version: null,
    }).then(() => {
      setCurrentUserLS({});
      dispatch(signOut());
      router.push('/');
      deleteAccessToken();
      resetCrispSession();
    });
  };

  const renderMenu = () => {
    const props = {
      open,
      handleClose: () => setOpen(false),
      handleLogout: handleLogout,
    };

    if (currentUser.role === 'influencer') {
      return isAgency ? <AgencySidebar {...props} /> : <InfluencerMobileSidebar {...props} />;
    } else if (currentUser.role === 'advertiser') {
      return <AdvertiserSidebar {...props} />;
    }

    return <AffiliateSidebar {...props} />;
  };

  return (
    <>
      <div className={styles.dropdownMenuContainer}>
        <Button onClick={handleToggle} disableRipple>
          <HamburgerIcon width={20} height={20} alt="Menu" />
        </Button>

        {renderMenu()}
      </div>
    </>
  );
};

export default Sidebar;
