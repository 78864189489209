import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCart } from 'features/cartSlice';
import { openModal } from 'features/utilsSlice';
import { postRequest } from 'utils/api';

const PayPalForm = () => {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const cart = useSelector(state => state.cart);

  const handleSubmit = useCallback(
    data => {
      postRequest({
        endpoint: `carts/${cart.id}/checkout`,
        data: {
          payment_method: 'pay_pal',
          ...data,
        },
        successMessage: 'Your order has been placed successfully!',
        errorMessage:
          'There was an error during the checkout. Please try again or contact support.',
      })
        .then(() => {
          dispatch(setCart({}));
          dispatch(openModal({ name: 'Influencer Response' }));
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    [cart.id, dispatch]
  );

  useEffect(() => {
    if (!window.paypal || initialized) return;

    paypal
      .Buttons({
        createOrder: (_, actions) =>
          actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: cart.total_checkout_price,
                },
              },
            ],
            application_context: {
              shipping_preference: 'NO_SHIPPING',
            },
          }),

        onApprove: (data, actions) =>
          actions.order.authorize().then(authorization => {
            const authorizationID = authorization.purchase_units[0].payments.authorizations[0].id;

            handleSubmit({ authorization_id: authorizationID, order_id: data.orderID });
          }),
      })
      .render('#paypal-button-container');

    setInitialized(true);
  }, [cart.total_checkout_price, handleSubmit, initialized]);

  return <div id="paypal-button-container" style={{ margin: '20px 0', padding: '0 15px' }}></div>;
};

export default PayPalForm;
