import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getWebSocketUrl } from 'utils/helpers';
import CableContext from './Context';

const Provider = ({ children }) => {
  const [consumer, setConsumer] = useState(null);
  const loggedIn = useSelector(state => state.auth.loggedIn);

  useEffect(() => {
    if (!loggedIn) return;

    const consumer = require('@anycable/web').createConsumer(getWebSocketUrl());

    setConsumer(consumer);

    return () => {
      if (consumer.cable) {
        consumer.cable.disconnect();
      }
    };
  }, [loggedIn]);

  return <CableContext.Provider value={consumer}>{children}</CableContext.Provider>;
};

export default Provider;
