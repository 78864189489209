import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';

import 'video.js/dist/video-js.css';

const Video = ({
  onReady,
  src,
  type = 'video/mp4',
  isFluid,
  controls = true,
  autoplay = false,
}) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    const options = {
      autoplay,
      controls,
      responsive: true,
      fluid: isFluid,
      fill: !isFluid,
      sources: [
        {
          src,
          type,
        },
      ],
    };

    const player = playerRef.current;

    if (!player) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      playerRef.current = videojs(videoElement, options, () => {
        if (onReady) onReady(player);
      });
    }
  }, [videoRef, onReady, src, type, isFluid, controls, autoplay]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className={'video-js vjs-big-play-centered'} />
    </div>
  );
};

export default Video;
