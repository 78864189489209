/* eslint-disable no-use-before-define */
import React, { useCallback, useLayoutEffect, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import debounce from 'lodash/debounce';

import { getRequest } from 'utils/api';

const padding = { padding: '0 25px' };
const noPadding = { padding: 0 };

const useStyles = makeStyles({
  inputRoot: {
    padding: '0px 15px 0 !important',
  },
  adornedStart: {
    paddingLeft: '14px !important',
  },
  input: {
    padding: '0px !important',
  },
});

const TagsAutocomplete = ({
  endpoint,
  handleSelect,
  selectedOptions,
  hasPadding,
  addingTag,
  allowCustomAdd,
  readonly,
  placeholder,
  openOnFocus,
  autoFocus,
}) => {
  const styles = useStyles();
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(
    debounce(
      text =>
        getRequest({
          endpoint,
          query: { filter: { q: text } },
        })
          .then(data => {
            if (inputRef.current) setData(data);
          })
          .catch(() => {}),
      200
    ),
    [endpoint]
  );

  useEffect(() => {
    search(inputValue);
  }, [search, inputValue]);

  useLayoutEffect(() => {
    if (!autoFocus) return;

    const timer = setTimeout(() => {
      inputRef.current.focus();
    }, 300);

    return () => clearTimeout(timer);
  }, [autoFocus]);

  return (
    <Autocomplete
      value={null}
      inputValue={inputValue}
      includeInputInList
      freeSolo
      openOnFocus={openOnFocus}
      filterOptions={(options, params) => {
        const filtered = options.filter(option =>
          openOnFocus
            ? !selectedOptions.includes(option.name)
            : !selectedOptions.includes(option.name) && params.inputValue.length > 0
        );

        if (allowCustomAdd && filtered.length === 0 && params.inputValue.length > 0) {
          filtered.push({ label: `Add "${params.inputValue}"`, name: params.inputValue });
        }

        return filtered;
      }}
      autoComplete
      filterSelectedOptions
      onChange={(e, newValue) => {
        e.preventDefault();
        e.stopPropagation();

        setInputValue('');

        if (!newValue) return;

        if (e.keyCode === 13 && !addingTag) return;

        if (e.keyCode === 13) {
          handleSelect(typeof newValue === 'string' ? newValue : newValue.name);
        } else {
          handleSelect(newValue.name);
        }
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'clear') {
          setInputValue('');
        } else {
          setInputValue(newInputValue);
        }
      }}
      options={data}
      getOptionLabel={option => option?.label || option.name || ''}
      style={hasPadding ? padding : noPadding}
      renderInput={params => (
        <TextField
          {...params}
          inputRef={inputRef}
          variant="outlined"
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: styles.inputRoot,
              adornedStart: styles.adornedStart,
              input: styles.input,
            },
            inputProps: {
              ...params.inputProps,
              enterKeyHint: 'Go',
              onKeyUp: e => e.stopPropagation(),
              readOnly: readonly ? 'readonly' : null,
            },
          }}
        />
      )}
    />
  );
};

TagsAutocomplete.propTypes = {
  endpoint: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array,
  hasPadding: PropTypes.bool,
  addingTag: PropTypes.bool,
  placeholder: PropTypes.string,
  openOnFocus: PropTypes.bool,
  autoFocus: PropTypes.bool,
  readonly: PropTypes.bool,
  allowCustomAdd: PropTypes.bool,
};

TagsAutocomplete.defaultProps = {
  selectedOptions: [],
  hasPadding: true,
  addingTag: true,
  placeholder: '',
  openOnFocus: false,
  autoFocus: false,
  readonly: false,
  allowCustomAdd: true,
};

export default TagsAutocomplete;
