import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';

const positionRelative = { position: 'relative', height: '100%' };

const variantsLeft = {
  pageInitial: {
    left: '100%',
  },

  pageAnimate: { left: 0 },

  pageExit: { left: '-100%' },
};

const variantsRight = {
  pageInitial: {
    left: '-100%',
  },

  pageAnimate: { left: 0 },
};

const AnimateStepChange = ({ children, onAnimationComplete }) => {
  const currentStep = useSelector(state => state.influencerSetup.step);
  const previousStep = useSelector(state => state.influencerSetup.previousStep);
  const variants = currentStep > previousStep ? variantsLeft : variantsRight;

  return (
    <motion.div
      style={positionRelative}
      key={currentStep}
      initial="pageInitial"
      animate="pageAnimate"
      variants={variants}
      onAnimationComplete={onAnimationComplete}
    >
      {children}
    </motion.div>
  );
};

AnimateStepChange.propTypes = {
  children: PropTypes.node.isRequired,
  onAnimationComplete: PropTypes.func,
};

AnimateStepChange.defaultProps = {
  onAnimationComplete: () => {},
};

export default AnimateStepChange;
