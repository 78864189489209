import React, { useEffect, useState } from 'react';
import { closeModal } from 'features/utilsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { InputLabel, TextField, TextareaAutosize } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { postRequest } from 'utils/api';
import { emailRegex } from 'utils/helpers';
import { updateCurrentUser } from 'features/authSlice';
import useModalOpen from 'hooks/useModalOpen';
import TransitionDialog from './TransitionDialog';
import BottomButtons from './BottomButtons';

const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginLeft: 'auto',
    },

    [theme.breakpoints.up('lg')]: {
      width: '35%',
    },
  },

  paperFullScreenDialog: {
    width: '100%',
  },
}));

const InvoicingDetailsDialog = () => {
  const currentUser = useSelector(state => state.auth.currentUser);
  const [billingEmail, setBillingEmail] = useState('');
  const [billingDetails, setBillingDetails] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const open = useModalOpen('Invoicing Details');

  const hasError = billingEmail && !billingEmail.match(emailRegex);

  const handleClose = () => dispatch(closeModal('Invoicing Details'));

  const handleSubmit = () => {
    setIsLoading(true);

    postRequest({
      endpoint: `users/${currentUser.id}/update_billing_info`,
      data: { user: { billing_details: billingDetails, billing_email: billingEmail } },
      successMessage: 'Successfully updated your billing info',
    })
      .then(() => {
        dispatch(
          updateCurrentUser({ billing_email: billingEmail, billing_details: billingDetails })
        );
        setIsLoading(false);
        handleClose();
      })
      .catch(() => setIsLoading(false)),
      [];
  };

  useEffect(() => {
    if (!open) {
      setBillingEmail('');
      setBillingDetails('');
      return;
    }

    if (currentUser.billing_email) setBillingEmail(currentUser.billing_email);
    if (currentUser.billing_details) setBillingDetails(currentUser.billing_details);
  }, [open, currentUser.billing_email, currentUser.billing_details]);

  return (
    <TransitionDialog
      open={open}
      handleClose={handleClose}
      paperProps={{ className: classes.paper }}
      dialogStyles={classes.paperFullScreenDialog}
      noCloseIcon
    >
      <div style={{ padding: '20px 15px' }}>
        <h3 style={{ fontSize: 24, paddingBottom: 20 }}>Extra billing information</h3>

        <InputLabel required>Billing e-mail</InputLabel>

        <p style={{ marginBottom: 10 }}>
          Alternate email for billing purposes like email receipts.
        </p>

        <TextField
          type="email"
          name="email"
          value={billingEmail}
          placeholder="Email"
          onChange={e => setBillingEmail(e.target.value)}
          variant="outlined"
          error={hasError}
          helperText={hasError ? 'is not valid.' : ''}
        />

        <InputLabel>Billing details</InputLabel>

        <p style={{ marginBottom: 10 }}>
          For your security, do not include any confidential or financial information (like credit
          card numbers).
        </p>

        <TextareaAutosize
          minRows={5}
          maxRows={5}
          value={billingDetails}
          placeholder="VAT ID, Business address, etc..."
          onChange={e => setBillingDetails(e.target.value)}
          maxLength={255}
        />

        <BottomButtons
          text="Cancel"
          textContained="Save"
          handleClick={handleSubmit}
          handleClose={handleClose}
          disabled={isLoading || !billingEmail}
          isLoading={isLoading}
          fixed
        />
      </div>
    </TransitionDialog>
  );
};

export default InvoicingDetailsDialog;
