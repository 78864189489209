const getStatus = (network, name = '') => {
  const status = name ? network[`${name}_status`] : network.status;

  if (status === 'pending_verification') {
    return (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'Avenir Heavy',
          fontSize: 12,
          padding: '0 12px',
          height: 18,
          borderRadius: 9,
          color: '#fff',
          backgroundColor: '#323643',
        }}
      >
        Pending
      </span>
    );
  } else if (status === 'verified') {
    return (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'Avenir Heavy',
          fontSize: 12,
          padding: '0 12px',
          height: 18,
          borderRadius: 9,
          color: '#fff',
          backgroundColor: '#12dfc7',
        }}
      >
        Verified
      </span>
    );
  } else if (status === 'rejected') {
    return (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'Avenir Heavy',
          fontSize: 12,
          padding: '0 12px',
          height: 18,
          borderRadius: 9,
          color: '#fff',
          backgroundColor: '#ff4b4b',
        }}
      >
        Rejected
      </span>
    );
  } else {
    return '';
  }
};

export default getStatus;
