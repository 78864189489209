import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { IconButton, ImageList, ImageListItem } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import EditIcon from 'public/icons/edit.svg';
import AddIcon from 'public/icons/add-white.svg';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',

    [theme.breakpoints.up('md')]: {
      backgroundColor: '#323643',
    },
  },

  imageList: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    margin: '0 !important',
    transform: 'translateZ(0)',
  },

  imageListItem: {
    position: 'relative',
    minHeight: '200px !important',
    height: '100% !important',
    padding: '0 !important',
    paddingRight: '3px !important',

    '&:last-child': {
      paddingRight: '0 !important',
    },

    '& div': {
      width: '100%',
    },

    '& img': {
      objectFit: 'cover',
    },
  },
}));

const CoverImage = ({ photos, edit }) => {
  const [numSlice, setNumSlice] = useState(1);
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (desktop && photos?.length > 2) return setNumSlice(3);

    return setNumSlice(2);
  }, [desktop, photos]);

  return (
    <div className={classes.root}>
      {edit && (
        <IconButton
          style={{
            position: 'absolute',
            zIndex: 1,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          className="icon-btn-hover-dark"
        >
          {photos?.length > 0 ? (
            <EditIcon width={24} height={24} alt="Edit" />
          ) : (
            <AddIcon width={24} height={24} alt="Plus Sign" />
          )}
        </IconButton>
      )}

      <ImageList className={classes.imageList} cols={2}>
        {photos?.slice(0, numSlice).map(img => (
          <ImageListItem
            key={img.id}
            className={clsx(
              classes.imageListItem,
              { ['full-width']: photos?.length === 1 },
              { ['split']: desktop && photos?.length > 2 }
            )}
          >
            <div
              style={{
                backgroundImage: `url("${img.url}")`,
                height: '100%',
                backgroundSize: 'cover',
                backgroundPosition: 'center top',
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};

CoverImage.propTypes = {
  photos: PropTypes.array,
  edit: PropTypes.bool,
};

CoverImage.defaultProps = {
  photos: [],
  edit: false,
};

export default CoverImage;
