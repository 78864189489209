import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, NativeSelect, Slider, TextField } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useDispatch } from 'react-redux';
import { add, format } from 'date-fns';
import { useRouter } from 'next/router';
import DateFnsUtils from '@date-io/date-fns';
import pluralize from 'pluralize';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  formatPrice,
  getMilestoneLiveTimeOption,
  getFrequencyLabel,
  getQuantityLabel,
  initDate,
  normalizeNetworkName,
  normalizeSpaceName,
  shouldQuantityDisplay,
} from 'utils/helpers';
import { openModal } from 'features/utilsSlice';
import ChevronDownDarkIcon from 'public/icons/chevron-down-dark.svg';
import SignatureDialog from './SignatureDialog';
import styles from 'styles/components/gigSetup/GigItemDialog.module.scss';

const frequencyToLabel = {
  daily: 'day',
  weekly: 'week',
  monthly: 'month',
};

const QuantityAndPricing = ({
  space,
  setFieldValue,
  inventory,
  showSummary,
  summaryQuantity,
  inventoryItem,
  getInventoryItem,
  digitalSignature,
  quantity,
  frequency,
  variableQuantity,
  offeredPrice,
  inventoryItemId,
  startTimes,
  profileOwner,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();

  return (
    <>
      {space.has_quantity && !profileOwner && (
        <>
          <div className={styles.title}>
            <h3>{getQuantityLabel(space)}</h3>

            <span className="color-main">{quantity}</span>
          </div>

          <Slider
            value={quantity}
            onChange={(_, newValue) => {
              if (space.event_attendance) {
                const startTimesArray = [...startTimes];
                const key = { weekly: 'weeks', monthly: 'months', daily: 'days' }[frequency];

                if (newValue >= quantity) {
                  const diff = newValue - quantity;

                  [...new Array(diff)].forEach(() => {
                    const lastDate = startTimesArray[startTimesArray.length - 1];
                    if (lastDate) startTimesArray.push(add(lastDate, { [key]: 1 }));
                  });

                  setFieldValue('start_times', startTimesArray);
                } else {
                  const diff = quantity - newValue;

                  setFieldValue('start_times', startTimes.slice(0, -diff));
                }
              }

              setFieldValue('quantity', newValue);
            }}
            min={space.custom_quantity ? space.min_quantity : 1}
            max={space.custom_quantity ? space.max_quantity : 20}
            step={space.custom_quantity ? space.default_quantity : 1}
          />
        </>
      )}

      {space.has_variable_quantity && !digitalSignature && !profileOwner && (
        <>
          <div className={styles.title}>
            <h3>
              How many{' '}
              {inventoryItem && inventoryItem.live_time_unit === 'none'
                ? `${pluralize(normalizeSpaceName(space.name))} would you like?`
                : `${pluralize(inventoryItem.live_time_unit || '')} per ${normalizeSpaceName(
                    space.name
                  )}?`}
            </h3>

            <TextField
              type="number"
              onClick={() => dispatch(openModal({ name: 'Signature Count' }))}
              onChange={e => setFieldValue('variable_quantity', e.target.value)}
              value={variableQuantity}
              variant="outlined"
              className={styles.input}
            />
          </div>

          <Slider
            value={variableQuantity}
            onChange={(_, newValue) => setFieldValue('variable_quantity', newValue)}
            min={space.custom_variable_quantity ? space.min_variable_quantity : 1}
            max={space.custom_variable_quantity ? space.max_variable_quantity : 20}
            step={space.custom_variable_quantity ? space.default_variable_quantity : 1}
          />
        </>
      )}

      {digitalSignature && !profileOwner && (
        <>
          <div className={styles.title}>
            <h3>Count: (prints)</h3>

            <TextField
              type="number"
              onClick={() => dispatch(openModal({ name: 'Signature Count' }))}
              onChange={e => setFieldValue('variable_quantity', e.target.value)}
              value={variableQuantity}
              variant="outlined"
              className={styles.input}
            />
          </div>

          <Slider
            value={variableQuantity}
            onChange={(_, newValue) => setFieldValue('variable_quantity', newValue)}
            min={space.custom_variable_quantity ? space.min_variable_quantity : 1}
            max={space.custom_variable_quantity ? space.max_variable_quantity : 20}
            step={space.custom_variable_quantity ? space.default_variable_quantity : 1}
          />
        </>
      )}

      {space.offer && !profileOwner && (
        <>
          <div className={styles.title}>
            <h3>
              Price of your offer{' '}
              {inventoryItem &&
                inventoryItem.live_time_unit &&
                `per ${
                  inventoryItem.live_time_duration > 1 ? inventoryItem.live_time_duration : ''
                } ${inventoryItem.live_time_unit}`}
            </h3>

            <span className="color-main">{formatPrice(offeredPrice)}</span>
          </div>

          <Slider
            value={offeredPrice}
            onChange={(_, newValue) => {
              setFieldValue('offered_price', newValue);
            }}
            min={+inventoryItem?.min_offer_price}
            max={+inventoryItem?.max_offer_price}
            step={+inventoryItem?.max_offer_price < 50000 ? 100 : 1000}
          />
        </>
      )}

      {!space.license && quantity > 1 && shouldQuantityDisplay(space) && !profileOwner && (
        <>
          <InputLabel>{getFrequencyLabel(space)}</InputLabel>

          <NativeSelect
            value={frequency}
            onChange={e => setFieldValue('frequency', e.target.value)}
            name="frequency"
            fullWidth
            IconComponent={ChevronDownDarkIcon}
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </NativeSelect>
        </>
      )}

      {space.event_attendance && !profileOwner && (
        <>
          <div className={styles.title}>
            <h3>{pluralize('Start Time', quantity)}</h3>
          </div>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {[...new Array(quantity)].map((_, i) => (
              <div key={i}>
                <strong style={{ position: 'relative', top: 15 }}>Event {i + 1}</strong>

                <DateTimePicker
                  variant="dialog"
                  format="PPPPPp zzz"
                  placeholder="Start date & time"
                  disablePast
                  clearable
                  margin="normal"
                  value={initDate(startTimes[i])}
                  minDate={i === 0 || !startTimes[i - 1] ? new Date() : initDate(startTimes[i - 1])}
                  onChange={date => {
                    const startTimesArray = [...startTimes];
                    startTimesArray[i] = date;
                    setFieldValue('start_times', startTimesArray);
                  }}
                />
              </div>
            ))}
          </MuiPickersUtilsProvider>
        </>
      )}

      {inventory?.inventory_items?.length > 0 &&
        inventory?.inventory_items[0]?.live_time_unit !== 'none' &&
        !space.event_attendance && (
          <>
            <InputLabel>{space.guaranteed_gains ? 'Guaranteed subs' : 'Live time'}</InputLabel>

            <NativeSelect
              name="inventory_item_id"
              value={inventoryItemId}
              onChange={e => {
                setFieldValue('inventory_item_id', +e.target.value);
                setFieldValue('offered_price', getInventoryItem(+e.target.value)?.min_offer_price);

                if (profileOwner) {
                  router.push(
                    {
                      url: router.pathname,
                      query: { ...router.query, ii: +e.target.value },
                    },
                    undefined,
                    {
                      shallow: true,
                    }
                  );
                }
              }}
              fullWidth
              IconComponent={ChevronDownDarkIcon}
            >
              {inventory?.inventory_items
                .filter(item => item.active)
                .map(option => (
                  <option key={option.id} value={option.id}>
                    {getMilestoneLiveTimeOption(option, space)}
                  </option>
                ))}
            </NativeSelect>
          </>
        )}

      {showSummary && !profileOwner && (
        <div className={styles.summaryBox}>
          <h3>
            {summaryQuantity} {normalizeNetworkName(get(space, 'network.name', ''))}{' '}
            {pluralize(normalizeSpaceName(space.name), summaryQuantity)}
          </h3>

          {!isEmpty(inventoryItem) && quantity > 1 && (
            <p>
              1 per {frequencyToLabel[frequency]}, over {quantity}{' '}
              {pluralize(frequencyToLabel[frequency], quantity)}, each staying live for at least{' '}
              {inventoryItem?.live_time_duration}{' '}
              {pluralize(inventoryItem?.live_time_unit, inventoryItem?.live_time_duration)}.
            </p>
          )}

          {!isEmpty(inventoryItem) && quantity <= 1 && (
            <p>
              staying {space.guaranteed_gains ? 'live up to' : 'live for at least'}  {inventoryItem?.live_time_duration}{' '}
              {pluralize(inventoryItem?.live_time_unit, inventoryItem?.live_time_duration)}.
            </p>
          )}
        </div>
      )}

      {space.event_attendance && !profileOwner && (
        <div className={styles.summaryBox}>
          <h3>
            {summaryQuantity} {normalizeNetworkName(get(space, 'network.name', ''))}{' '}
            {pluralize(normalizeSpaceName(space.name), summaryQuantity)}
          </h3>

          {!isEmpty(inventoryItem) && (
            <p>
              {quantity > 1 &&
                `1 event per ${frequencyToLabel[frequency]}, over ${quantity} ${pluralize(
                  frequencyToLabel[frequency],
                  quantity
                )}, `}
              {variableQuantity} {pluralize(inventoryItem?.live_time_unit, variableQuantity)} per
              event
              {startTimes.length > 0 &&
                `, starting on ${format(new Date(startTimes[0]), 'PPPPPp zzz')}`}
            </p>
          )}
        </div>
      )}

      <SignatureDialog
        setFieldValue={setFieldValue}
        variableQuantity={variableQuantity}
        inputStyle={styles.countInput}
        min={space.custom_variable_quantity ? space.min_variable_quantity : 1}
        max={space.custom_variable_quantity ? space.max_variable_quantity : 20}
      />
    </>
  );
};

QuantityAndPricing.propTypes = {
  space: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  showSummary: PropTypes.bool.isRequired,
  summaryQuantity: PropTypes.number.isRequired,
  getInventoryItem: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  frequency: PropTypes.string.isRequired,
  variableQuantity: PropTypes.number.isRequired,
  offeredPrice: PropTypes.number.isRequired,
  inventoryItemId: PropTypes.number.isRequired,
  startTimes: PropTypes.array,
  digitalSignature: PropTypes.bool,
  inventoryItem: PropTypes.object,
  inventory: PropTypes.object,
};

QuantityAndPricing.defaultProps = {
  inventory: {},
  inventoryItem: {},
  startTimes: [],
  digitalSignature: false,
};

QuantityAndPricing.whyDidYouRender = true;

export default QuantityAndPricing;
