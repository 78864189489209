import React from 'react';
import styles from 'styles/components/gigSetup/GigItemDialog.module.scss';
import HandshakeIcon from 'public/icons/handshake.svg';

const LoyalFansPromo = () => (
  <div className={styles.summaryBox}>
    <HandshakeIcon width={30} height={30} />
    <h3>
      $25 LoyalFans promotional discount <br />
      <small style={{ color: 'black', fontSize: 12 }}>(applied at checkout)</small>
    </h3>
  </div>
);

export default LoyalFansPromo;
