import { useSelector } from 'react-redux';
import get from 'lodash/get';

const useModalOpen = name => {
  const modal = useSelector(state => state.utils.modal);

  return get(modal, name, false);
};

export default useModalOpen;
