import Head from 'next/head';
import theme from '../theme';

const DocumentHead = ({ children }) => (
  <Head>
    <meta name="theme-color" content={theme.palette.primary.main} />
    <link
      rel="icon"
      href={`${process.env.NEXT_PUBLIC_URL}/images/favicon.svg`}
      type="image/svg+xml"
    />
    <link
      rel="apple-touch-icon"
      href={`${process.env.NEXT_PUBLIC_URL}/images/favicon.svg`}
      type="image/svg+xml"
    />

    <title>GigSocial</title>

    <meta
      property="og:title"
      content="The easiest way for brands to buy from influencers directly"
      key="og:title"
    />
    <meta property="og:url" content={process.env.NEXT_PUBLIC_URL} key="og:url" />
    <meta
      property="og:image"
      content={`${process.env.NEXT_PUBLIC_URL}/images/logo-gradient@2x.jpg`}
      key="og:image"
    />
    <meta property="og:type" content="website" key="og:type" />
    <meta
      property="og:description"
      content="The Influencer &amp; Athlete marketplace. Buy posts from influencers with any sized budget or list your brand gigs to get matched."
      key="og:description"
    />

    <meta
      name="twitter:title"
      content="The easiest way for brands to buy from influencers directly"
      key="twitter:title"
    />
    <meta name="twitter:card" content="summary_large_image" key="twitter:card" />
    <meta
      name="twitter:image"
      content={`${process.env.NEXT_PUBLIC_URL}/images/logo-gradient@2x.jpg`}
      key="twitter:image"
    />
    <meta property="twitter:domain" content="gigsocial.com" key="twitter:domain" />
    <meta property="twitter:url" content={process.env.NEXT_PUBLIC_URL} key="twitter:url" />
    <meta
      name="twitter:description"
      content="The Influencer &amp; Athlete marketplace. Buy posts from influencers with any sized budget or list your brand gigs to get matched."
      key="twitter:description"
    />

    {children}
  </Head>
);

export default DocumentHead;
