import React, { useRef, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Formik, Form, Field } from 'formik';
import { useDispatch } from 'react-redux';
import { batch } from 'react-redux';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel,
  Button,
  Avatar,
  NativeSelect,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import pick from 'lodash/pick';
import * as yup from 'yup';

import { addProfile, updateSelectedProfile } from 'features/authSlice';
import { postRequest, getRequest } from 'utils/api';
import { identifications } from 'utils/helpers';
import { closeModal } from 'features/utilsSlice';
import useModalOpen from 'hooks/useModalOpen';
import ChevronDownDarkIcon from 'public/icons/chevron-down-dark.svg';
import User from 'public/icons/form/user.svg';
import NoAvatarImage from 'public/images/no-avatar.svg';
import TransitionDialog from 'components/TransitionDialog';
import AvatarInput from 'components/AvatarInput';
import styles from 'styles/components/ProfileForm.module.scss';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'image/heic', 'image/heif'];
const defaultRequired = 'is required.';
const avatarFullSize = { width: '100%', height: '100%' };

const AddProfileDialog = ({ children, handleClose }) => {
  const open = useModalOpen('Add Profile');

  return (
    <TransitionDialog open={open} styles={styles} handleClose={handleClose} noCloseIcon>
      {children}
    </TransitionDialog>
  );
};

const schema = yup.object({
  username: yup
    .string()
    .matches(/^[a-zA-Z0-9_.-]*$/, 'is invalid (no spaces or special characters allowed).')
    .required(defaultRequired),
  display_name: yup.string().required(defaultRequired),
  avatar: yup
    .mixed()
    .test('fileSize', 'File size is too large.', value =>
      value ? value.size <= 10 * 1024 * 1024 : true
    )
    .test('fileType', 'Unsupported file format.', value =>
      value ? SUPPORTED_FORMATS.includes(value.type) : true
    )
    .required(defaultRequired),
  experiences: yup
    .array()
    .min(1, 'Min is 1')
    .max(5, 'max is 5'),
  audiences: yup
    .array()
    .min(1, 'Min is 1')
    .max(5, 'max is 5'),
});

const initialValues = {
  username: '',
  display_name: '',
  avatar: '',
};

const useStyles = makeStyles({
  large: {
    width: 108,
    height: 108,
  },

  root: {
    marginTop: 10,
    marginBottom: 7,
  },
});

const marginTop = { marginTop: 30 };
const negativeMarginTop = { marginTop: -24, marginLeft: 0 };

const AddProfile = () => {
  const [preview, setPreview] = useState(null);
  const [selected, setSelected] = useState({
    gender: '',
    audience_gender: '',
    hasGenderError: false,
    hasAudienceGenderError: false,
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState('');
  const [isModelRole, setIsModelRole] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const fileRef = useRef();
  const classes = useStyles();
  const open = useModalOpen('Add Profile');

  const experiencesInputRef = useRef();
  const audiencesInputRef = useRef();

  const handleClose = () => dispatch(closeModal('Add Profile'));

  const handleSubmit = async (values, actions) => {
    setIsLoading(true);
    const data = new FormData(); // eslint-disable-line no-undef

    Object.keys(pick(values, ['username', 'display_name', 'avatar'])).forEach(key => {
      if (values[key]) data.set(`profile[${key}]`, values[key]);
    });

    // data.set('profile[sub_category_id]', subCategoryId);
    // data.set('profile[names]', selectedCategories);
    // data.set('model', isModelRole);

    // values.audiences.forEach(audience => {
    //   data.append('profile[audiences][]', audience);
    // });

    // values.experiences.forEach(experience => {
    //   data.append('profile[experiences][]', experience);
    // });

    // data.set('profile[audience_gender]', selected.audience_gender);

    data.set('profile[gender]', selected.gender);

    const endpoint = 'profiles';

    try {
      const response = await postRequest({
        endpoint,
        data,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        successMessage: `Successfully created new profile.`,
        ...actions,
      });

      const profile = response.data;

      postRequest({
        endpoint: `profiles/${profile.id}/select`,
      }).then(() => {
        batch(() => {
          dispatch(addProfile(profile));
          dispatch(updateSelectedProfile(profile.id));
        });

        setPreview('');
        setSelected({});
        setIsLoading(false);

        handleClose();
        router.push('/inventory');
      });
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // getRequest({
    //   endpoint: 'categories',
    // })
    //   .then(categories => setCategories(categories))
    //   .catch(() => {});

    router.prefetch('/inventory');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSubCategories = categoryId => {
    getRequest({
      endpoint: 'sub_categories',
      query: { category_id: categoryId },
    })
      .then(subCategories => setSubCategories(subCategories))
      .catch(() => {});
  };

  const handleFileClick = setTouched => {
    fileRef.current.click();
    setTouched({ avatar: true });
  };

  return (
    <AddProfileDialog handleClose={handleClose}>
      {open && (
        <div className={styles.container}>
          <div className={styles.gallery}></div>

          <Formik
            validationSchema={schema}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, setTouched, touched }) => (
              <Form className={styles.form}>
                <div className={styles.avatarField}>
                  <>
                    <div onClick={() => handleFileClick(setTouched)} className={styles.avatar}>
                      <Avatar src={preview} className={classes.large} alt="Avatar">
                        <NoAvatarImage style={avatarFullSize} />
                      </Avatar>

                      {(!preview || !values.avatar) && (
                        <span className={styles.addPhoto}>Add Photo</span>
                      )}
                    </div>

                    <AvatarInput
                      fileRef={fileRef}
                      preview={preview}
                      setPreview={setPreview}
                      handleDrop={avatar => setFieldValue('avatar', avatar)}
                    />
                  </>

                  {!values.avatar && touched.avatar && (
                    <span className={styles.avatarErrors}>is required.</span>
                  )}
                </div>

                <span style={{ display: 'flex', fontSize: 12, opacity: 0.8, marginTop: 5 }}>
                  (Please do not upload explicit images for your public profile)
                </span>

                <InputLabel className={classes.root} style={marginTop}>
                  Username
                </InputLabel>

                <Field
                  component={TextField}
                  name="username"
                  type="text"
                  placeholder="Username"
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User width={14} />
                      </InputAdornment>
                    ),
                  }}
                />

                <InputLabel className={classes.root}>Display Name (Name of your page)</InputLabel>
                <Field
                  component={TextField}
                  name="display_name"
                  type="text"
                  placeholder="Display Name (Name of your page)"
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User width={14} />
                      </InputAdornment>
                    ),
                  }}
                />

                <InputLabel>What do you identify as?</InputLabel>

                <NativeSelect
                  fullWidth
                  error={selected.hasGenderError}
                  name="gender"
                  className={styles.select}
                  value={selected.gender}
                  onChange={e =>
                    setSelected(prevState => ({
                      ...prevState,
                      gender: e.target.value,
                      hasGenderError: !e.target.value,
                    }))
                  }
                  onBlur={() => {
                    if (!selected.gender) {
                      setSelected(prevState => ({ ...prevState, hasGenderError: true }));
                    }
                  }}
                  IconComponent={ChevronDownDarkIcon}
                >
                  <option value="">Select</option>
                  {identifications.map((gender, index) => (
                    <option key={index} value={gender}>
                      {gender}
                    </option>
                  ))}
                </NativeSelect>

                {selected.hasGenderError && (
                  <span style={negativeMarginTop} className="error-msg">
                    is required.
                  </span>
                )}

                {/* See with Will about categories/audience/experience below */}

                {/* <h5 className="color-main">YOUR CATEGORIES</h5>

              <p>Choose up to three categories that best represent you.</p>

              <h6>Categories</h6>

              <SelectCategory
                callback={tags => setSelectedCategories(tags)}
                hasTitle={false}
                noSteps={true}
                hasPadding={false}
                defaultSelected={false}
                setSubCategoryId={setSubCategoryId}
                setIsModelRole={setIsModelRole}
              />

              <h5 className="color-main">YOUR AUDIENCE INTERESTS</h5>

              <p>
                This will help us get you gig matches by matching an advertiser’s ideal audience
                requirement.
              </p>

              <h6>What gender represents your main audience?</h6>

              <NativeSelect
                fullWidth
                error={selected.hasAudienceGenderError}
                name="audience_gender"
                className={styles.select}
                value={selected.audience_gender}
                onChange={e =>
                  setSelected(prevState => ({
                    ...prevState,
                    audience_gender: e.target.value,
                    hasAudienceGenderError: !e.target.value,
                  }))
                }
                IconComponent={ChevronDownDarkIcon}
              >
                <option value="">Select</option>

                {genders.map((gender, index) => (
                  <option key={index} value={gender}>
                    {gender}
                  </option>
                ))}
              </NativeSelect>

              {selected.hasAudienceGenderError && (
                <span style={negativeMarginTop} className="error-msg">
                  is required.
                </span>
              )}

              <TagSelection
                title="Audience Interests"
                subtitle="Audience keywords"
                endpoint="audiences"
                inputRef={audiencesInputRef}
                placeholder="Add keyword"
                noSteps
                newStyles={styles}
                callback={tags => setFieldValue('audiences', tags)}
                hasPadding={false}
              />

              <TagSelection
                title="Experience"
                subtitle="What have you had good results selling?"
                endpoint="experiences"
                inputRef={experiencesInputRef}
                placeholder="Add product type"
                noSteps
                newStyles={styles}
                callback={tags => setFieldValue('experiences', tags)}
                hasPadding={false}
              /> */}

                <div className={styles.buttons}>
                  <Button onClick={handleClose} color="primary" fullWidth>
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    // disabled={
                    //   isLoading ||
                    //   !isEmpty(errors) ||
                    //   selectedCategories.length === 0 ||
                    //   !selected.gender ||
                    //   !selected.audience_gender ||
                    //   (isModelRole && !subCategoryId)
                    // }
                    disabled={
                      isLoading ||
                      !values.avatar ||
                      !values.username ||
                      !values.display_name ||
                      !selected.gender
                    }
                    fullWidth
                  >
                    {isLoading ? <CircularProgress color="primary" /> : 'Create'}
                  </Button>
                </div>

                {/* <CropDialog
                  image={preview || values.avatar}
                  handleAvatarChange={avatar => {
                    if (preview) URL.revokeObjectURL(preview);
                    setFieldValue('avatar', avatar);
                    setPreview(URL.createObjectURL(avatar));
                  }}
                /> */}
              </Form>
            )}
          </Formik>
        </div>
      )}
    </AddProfileDialog>
  );
};

export default AddProfile;
