import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Image from 'next/image';
import clsx from 'clsx';

import { openInventoryModal } from 'features/influencerSetupSlice';
import CheckIcon from 'public/icons/check.svg';
import AnimateList from 'components/animations/AnimateList';
import AnimateListItem from 'components/animations/AnimateListItem';
import styles from 'styles/components/influencerSetup/InventorySpaces.module.scss';

const InventorySpaces = ({ inventorySpaces, inventories, fanOnly }) => {
  const dispatch = useDispatch();

  const inventoryModalData = inventorySpace => {
    const inventory = inventories.find(i => i.inventory_space.id === inventorySpace.id);

    if (inventory) {
      const formattedInventory = {
        ...inventory,
        inventory_items: [...inventory.inventory_items]
          .sort((a, b) => b.default - a.default)
          .map((item, index) => ({ ...item, index })),
      };

      return { inventory: formattedInventory, inventorySpace };
    } else {
      return { inventorySpace };
    }
  };

  const handleClick = space => dispatch(openInventoryModal(inventoryModalData(space)));

  const isSelected = space => inventories.some(i => i.inventory_space.id === space.id);

  const regularSpaces = [...inventorySpaces]
    .filter(space => !space.fan)
    .sort((a, b) => a.order - b.order);

  const fanSpaces = [...inventorySpaces]
    .filter(space => space.fan)
    .sort((a, b) => a.order - b.order);

  const renderItem = space => (
    <AnimateListItem
      key={space.id}
      onClick={() => handleClick(space)}
      className={clsx(styles.space, {
        [styles.selectedSpace]: isSelected(space),
      })}
    >
      <Image src={space.icon_url} width={33} height={33} alt={space.name} unoptimized />
      <span>{space.name}</span>

      {isSelected(space) && (
        <div
          className={styles.checkIcon}
          style={
            inventorySpaces.length === 2 || inventorySpaces.length === 3
              ? {
                  right: '-2px',
                }
              : {}
          }
        >
          <CheckIcon width={40} height={40} />
        </div>
      )}
    </AnimateListItem>
  );

  return (
    <>
      {fanSpaces.length > 0 && fanOnly && (
        <AnimateList
          key={inventorySpaces[0]?.id}
          className={clsx(styles.inventorySpaces, {
            [styles.twoColumns]: inventorySpaces.length === 2,
            [styles.threeColumns]: inventorySpaces.length === 3,
          })}
        >
          {fanSpaces.map(renderItem)}
        </AnimateList>
      )}

      {!fanOnly && (
        <AnimateList
          key={inventorySpaces[0]?.id}
          className={clsx(styles.inventorySpaces, {
            [styles.twoColumns]: inventorySpaces.length === 2,
            [styles.threeColumns]: inventorySpaces.length === 3,
          })}
        >
          {regularSpaces.map(renderItem)}
        </AnimateList>
      )}
    </>
  );
};

InventorySpaces.propTypes = {
  inventorySpaces: PropTypes.array.isRequired,
  inventories: PropTypes.array.isRequired,
};

export default InventorySpaces;
