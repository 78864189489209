import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { closeModal } from 'features/utilsSlice';
import useModalOpen from 'hooks/useModalOpen';
import TransitionDialog from 'components/TransitionDialog';

const useStyles = makeStyles(theme => ({
  paper: {
    height: 'fit-content',
    width: '90%',
    marginBottom: 'auto',
    top: '15%',
    borderRadius: 10,

    [theme.breakpoints.up('md')]: {
      width: '50%',
    },

    [theme.breakpoints.up('lg')]: {
      width: '30%',
    },
  },

  paperFullScreenDialog: {
    width: '100%',
  },
}));

const AssetPreviewDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useModalOpen('Asset Preview');
  const src = useSelector(state => state.utils.modalData['Asset Preview']?.src);

  const btnStyle = { display: 'flex', width: '50%', margin: '20px auto 0' };
  const imgStyle = { borderRadius: 10, objectFit: 'contain' };

  const handleClose = () => dispatch(closeModal('Asset Preview'));

  return (
    <TransitionDialog
      open={open}
      handleClose={handleClose}
      paperProps={{ className: classes.paper }}
      dialogStyles={classes.paperFullScreenDialog}
      direction="up"
      desktopDirection="up"
      noCloseIcon
    >
      <div
        style={{
          padding: 20,
        }}
      >
        <img src={src} alt="Preview" width="100%" height={400} style={imgStyle} />

        <Button
          color="primary"
          variant="contained"
          onClick={handleClose}
          style={btnStyle}
          fullWidth
        >
          Close
        </Button>
      </div>
    </TransitionDialog>
  );
};

export default AssetPreviewDialog;
