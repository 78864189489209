import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';

import { putRequest } from 'utils/api';
import { updateCurrentUser } from 'features/authSlice';
import { useRouter } from 'next/router';
import HandIcon from 'public/icons/hand-with-circles-black.svg';
import DollarBillIcon from 'public/icons/dollar-bill.svg';
import styles from 'styles/components/home/Header.module.scss';

const noPadding = { padding: 0 };
const itemStyle = { padding: '5px', fontSize: 14, fontFamily: 'Avenir-Heavy' };
const marginRight = { marginRight: 10 };
const zIndex = { zIndex: 10 };

const map = {
  'As Seller': 'sell',
  'As Buyer': 'buy',
};

const SwitchPrimaryInterestPopper = ({ open, setOpen, anchorRef }) => {
  const currentUserId = useSelector(state => state.auth.currentUser.id);
  const dispatch = useDispatch();
  const router = useRouter();

  const handleClose = () => setOpen(false);

  const handleClick = interest => {
    putRequest({
      endpoint: `users/${currentUserId}`,
      data: { user: { primary_interest: map[interest] } },
    }).then(() => {
      dispatch(updateCurrentUser({ primary_interest: map[interest] }));
      router.push('/dashboard');
    });

    handleClose();
  };

  return (
    <Popper open={open} anchorEl={anchorRef.current} style={zIndex} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper className={styles.paperStyle}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList style={noPadding}>
                <MenuItem
                  onClick={() => handleClick('As Seller')}
                  style={itemStyle}
                  className={styles.switchOption}
                >
                  <HandIcon style={marginRight} />
                  As Seller
                </MenuItem>

                <MenuItem
                  onClick={() => handleClick('As Buyer')}
                  style={itemStyle}
                  className={styles.switchOption}
                >
                  <DollarBillIcon style={marginRight} />
                  As Buyer
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

SwitchPrimaryInterestPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  anchorRef: PropTypes.object.isRequired,
};

export default SwitchPrimaryInterestPopper;
