import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';

import compressImage from 'utils/compressImage';
import styles from 'styles/components/DropzoneField.module.scss';

const padding = { padding: '0 15px 0 0' };

const DropzoneField = ({
  setFile,
  file,
  showDropFileLabel,
  noPreview,
  addPadding,
  accept = 'image/*',
  handleClick,
  defaultPreview = null,
  compress = true,
  compressOptions = {
    quality: 0.8,
  },
  disabled = false,
  updatePreview = false,
}) => {
  const [preview, setPreview] = useState(defaultPreview);

  useEffect(() => {
    if (!updatePreview) return;

    setPreview(defaultPreview);
  }, [defaultPreview, updatePreview]);

  const { getRootProps, getInputProps } = useDropzone({
    disabled,
    accept,
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0];

      if (['image/heic', 'image/heif'].includes(file?.type)) {
        require('heic2any')({
          blob: file,
          toType: 'image/jpeg',
          quality: 1,
          multiple: false,
        })
          .then(blob => {
            const result = new File([blob], file.name, { type: blob.type });

            if (compress) {
              compressImage(result, compressOptions)
                .then(compressedFile => {
                  const preview = URL.createObjectURL(compressedFile);
                  setPreview(preview);
                  setFile(compressedFile);
                })
                .catch(() => {
                  const preview = URL.createObjectURL(result);
                  setPreview(preview);
                  setFile(result);
                });
            } else {
              const preview = URL.createObjectURL(result);
              setPreview(preview);
              setFile(result);
            }
          })
          .catch(e => {
            console.log('Error converting heic to jpeg', e);
          });
      } else if (['image/jpeg', 'image/jpg', 'image/png'].includes(file?.type)) {
        if (compress) {
          compressImage(file, compressOptions)
            .then(compressedFile => {
              const preview = URL.createObjectURL(compressedFile);
              setPreview(preview);
              setFile(compressedFile);
            })
            .catch(() => {
              const preview = URL.createObjectURL(file);
              setPreview(preview);
              setFile(file);
            });
        } else {
          const preview = URL.createObjectURL(file);
          setPreview(preview);
          setFile(file);
        }
      } else {
        setPreview(URL.createObjectURL(file));
        setFile(file);
      }
    },
  });

  useEffect(
    () => () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div
        className={styles.dropzoneField}
        style={preview || addPadding ? padding : {}}
        onClick={handleClick}
      >
        {!preview && noPreview}

        {preview && <img src={preview} width={74} height={74} alt="Thumbnail" />}
        {file && !preview && <span className={styles.file}>{file.name}</span>}

        {!preview && !file && !showDropFileLabel && <span>Drop file or</span>}

        <Button color="primary" variant="contained">
          {preview || file ? 'Replace' : 'Browse'}
        </Button>
      </div>
    </div>
  );
};

export default DropzoneField;
