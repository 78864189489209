import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, ImageList, ImageListItem } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';

import { updateProfile } from 'features/authSlice';
import { closeModal, openModal } from 'features/utilsSlice';
import { deleteRequest, putRequest } from 'utils/api';
import compressImage from 'utils/compressImage';
import useModalOpen from 'hooks/useModalOpen';
import TransitionDialog from 'components/TransitionDialog';
import FullScreenLoader from 'components/milestones/FullScreenLoader';
import ConfirmationDialog from 'components/ConfirmationDialog';
import BinIcon from 'public/icons/bin.svg';
import styles from 'styles/components/profile/Gallery.module.scss';

const useStyles = makeStyles(theme => ({
  imageList: {
    width: '100%',
    height: '100%',
    flexWrap: 'nowrap',
    alignItems: 'center',
    margin: '0 !important',
    scrollSnapType: 'x mandatory',
    overflowY: 'hidden',

    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      justifyContent: 'center',
      padding: '70px 70px 0 70px',
    },
  },

  imageListItem: {
    width: '340px !important',
    height: '480px !important',
    position: 'relative',
    padding: '0 !important',
    marginRight: '15px !important',

    [theme.breakpoints.down('sm')]: {
      scrollSnapAlign: 'center',

      '&:first-child': {
        marginLeft: '15px !important',
      },

      '&:last-child': {
        marginRight: '15px !important',
      },
    },

    [theme.breakpoints.up('sm')]: {
      marginBottom: '15px !important',
    },

    '& div': {
      width: '100%',
      borderRadius: '10px !important',
    },

    '& img': {
      objectFit: 'cover',
    },
  },
}));

const Gallery = ({ edit, profile, setProfile }) => {
  const [progress, setProgress] = useState(100);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const photos = profile?.photos;
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.up('sm'));
  const open = useModalOpen('Gallery');
  const dispatch = useDispatch();

  const fileRef = useRef();
  const classes = useStyles();

  const handleFileClick = () => fileRef.current.click();

  const handleClose = () => dispatch(closeModal('Gallery'));

  const handleSubmit = async files => {
    setProgress(0);
    const data = new FormData(); // eslint-disable-line no-undef

    const promises = Object.entries(files).map(async ([_, photo]) => {
      if (['image/heic', 'image/heif'].includes(photo.type)) {
        const blob = await require('heic2any')({
          blob: photo,
          toType: 'image/jpeg',
          quality: 1,
          multiple: false,
        }).catch(e => {
          console.log('Error converting heic to jpeg', e);
        });

        const convertedFile = new File([blob], photo.name, { type: blob.type });

        return await compressImage(convertedFile, { maxWidth: 1200, maxHeight: 1200 });
      } else {
        return await compressImage(photo, { quality: 0.8, maxWidth: 1200, maxHeight: 1200 });
      }
    });

    await Promise.allSettled(promises)
      .then(promises => {
        promises.forEach(promise => {
          data.append(`profile[photos][]`, promise.value);
        });
      })
      .catch(error => console.log('ooops :(', error));

    putRequest({
      endpoint: `profiles/${profile.id}`,
      data,
      config: {
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
        headers: { 'Content-Type': 'multipart/form-data' },
      },

      successMessage: `Successfully uploaded ${
        Object.values(files).length === 1 ? 'a new photo' : 'new photos'
      }.`,
      errorMessage: `Failed to upload ${Object.values(files).length === 1 ? 'a photo' : 'photos'}.`,
    })
      .then(response => {
        if (setProfile) setProfile(response.data);
        dispatch(updateProfile(response.data));
        setProgress(100);
      })
      .catch(() => setProgress(100));
  };

  const removePhoto = photo => {
    setIsLoading(true);

    deleteRequest({
      endpoint: `attachments/${photo.id}`,
    })
      .then(() => {
        const newPhotos = profile.photos.filter(p => p.id !== photo.id);

        setProfile({ ...profile, photos: newPhotos });
        dispatch(updateProfile({ ...profile, photos: newPhotos }));
        dispatch(closeModal('Confirmation'));
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <>
      <TransitionDialog
        open={open}
        handleClose={handleClose}
        newStyle={{ width: '100%' }}
        styles={styles}
        PaperProps={{ style: { backgroundColor: 'transparent' } }}
        noCloseIcon
      >
        <div className={styles.galleryContainer}>
          {progress < 100 && <FullScreenLoader progress={progress} />}

          {photos?.length === 0 && progress === 100 ? (
            <Alert className={styles.alert} severity="info">
              You haven't added any photos <br />
              We recommend at least 3 for a nice layout
            </Alert>
          ) : (
            <ImageList
              className={clsx(classes.imageList, {
                [styles.center]: photos?.length === 1 || (photos?.length === 2 && tablet),
              })}
              cols={1}
            >
              {photos?.map((img, index) => (
                <ImageListItem className={classes.imageListItem} key={index}>
                  {edit && (
                    <BinIcon
                      className={clsx(styles.bin, 'hover-icon')}
                      alt="Bin"
                      onClick={() => {
                        dispatch(openModal({ name: 'Confirmation', data: { action: 'approve' } }));
                        setSelectedImg(img);
                      }}
                    />
                  )}

                  <div
                    style={{
                      backgroundImage: `url("${img.url}")`,
                      height: '100%',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center top',
                    }}
                  />
                  <div className={styles.placeholder} />
                </ImageListItem>
              ))}
            </ImageList>
          )}

          <input
            ref={fileRef}
            type="file"
            name="photo"
            multiple
            onChange={e => handleSubmit(e.target.files)}
            accept="image/jpg, image/jpeg, image/png, image/heic, image/heif"
          />

          <div className={styles.buttons}>
            <Button onClick={handleClose} color="secondary" variant="outlined">
              Close
            </Button>

            {edit && (
              <Button color="secondary" variant="outlined" onClick={handleFileClick}>
                Add Photos
              </Button>
            )}
          </div>
        </div>
      </TransitionDialog>

      {selectedImg && (
        <ConfirmationDialog
          title="Are you sure?"
          handleApprove={() => removePhoto(selectedImg)}
          disabled={isLoading}
        />
      )}
    </>
  );
};

Gallery.propTypes = {
  profile: PropTypes.object.isRequired,
  setProfile: PropTypes.func,
  edit: PropTypes.bool,
};

Gallery.defaultProps = {
  setProfile: () => {},
  edit: false,
};

export default Gallery;
