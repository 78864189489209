import { useRouter } from 'next/router';
import Link from 'next/link';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function NavLink({ href, exact, children, activeClassName, className, isActive, ...props }) {
  const { asPath } = useRouter();
  let active;

  if (isActive !== null) {
    active = isActive;
  } else {
    active = exact ? asPath === href : asPath.startsWith(href);
  }

  return (
    <Link href={href}>
      <a {...props} className={clsx(className, { [activeClassName]: active })}>
        {children}
      </a>
    </Link>
  );
}

NavLink.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  exact: PropTypes.bool,
  isActive: PropTypes.bool,
  activeClassName: PropTypes.string,
};

NavLink.defaultProps = {
  exact: false,
  isActive: null,
  activeClassName: 'active',
  className: '',
};

export default NavLink;
