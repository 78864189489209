import React from 'react';
import Countdown from 'react-countdown';

const TwoFactorCountdown = () => {
  const start = React.useRef(Date.now());

  return (
    <Countdown
      date={start.current + 120000}
      intervalDelay={1000}
      precision={1}
      renderer={props => <span>Time remaining: {props.minutes * 60 + props.seconds} seconds</span>}
      onComplete={() => window.location.reload()}
    />
  );
};

export default TwoFactorCountdown;
