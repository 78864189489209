import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import QRCode from 'react-qr-code';

import { closeModal } from 'features/utilsSlice';
import useModalOpen from 'hooks/useModalOpen';
import TransitionDialog from 'components/TransitionDialog';
import styles from 'styles/components/QRCode.module.scss';

const useStyles = makeStyles(theme => ({
  paper: {
    height: 'fit-content',
    width: '90%',
    marginBottom: 'auto',
    top: '5%',
    borderRadius: 10,

    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },

    [theme.breakpoints.up('md')]: {
      width: '40%',
    },

    [theme.breakpoints.up('lg')]: {
      width: '30%',
    },
  },

  paperFullScreenDialog: {
    width: '100%',
  },
}));

const QRCodeDialog = ({ url }) => {
  const classes = useStyles();
  const open = useModalOpen('QR Code');
  const dispatch = useDispatch();

  const handleClose = () => dispatch(closeModal('QR Code'));

  return (
    <TransitionDialog
      open={open}
      handleClose={handleClose}
      paperProps={{ className: classes.paper }}
      dialogStyles={classes.paperFullScreenDialog}
      direction="up"
      desktopDirection="up"
      noCloseIcon
    >
      <div className={styles.container}>
        <h4>YOUR QR CODE</h4>

        <p>
          Show this code to a friend so they can scan it with their camera and jump directly to your
          profile
        </p>

        <div className={styles.qrCode}>
          <QRCode value={url} />
        </div>

        <Button onClick={handleClose} variant="contained" color="primary">
          Done
        </Button>
      </div>
    </TransitionDialog>
  );
};

export default QRCodeDialog;
