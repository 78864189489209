import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Avatar } from '@material-ui/core';
import pluralize from 'pluralize';

import { formatPrice, safeDateFormat } from 'utils/helpers';
import { deleteRequest } from 'utils/api';
import { setCart } from 'features/cartSlice';
import { closeModal, openModal } from 'features/utilsSlice';
import { useSelector } from 'react-redux';
import { getUuid } from 'utils/localStorage';
import DiscountCodeDialog from './DiscountCodeDialog';
import EditIcon from 'public/icons/edit-grey.svg';
import ChevronRightIcon from 'public/icons/chevron-right.svg';
import PinIcon from 'public/icons/pin.svg';
import DeleteIcon from 'public/icons/bin-grey.svg';
import NoAvatarImage from 'public/images/no-avatar.svg';
import ConfirmationDialog from 'components/ConfirmationDialog';
import OrderDialog from 'components/orderDialog/OrderDialog';

const alertStyle = { width: 30, height: 30 };
const marginLeft = { marginLeft: 'auto' };
const avatarFullSize = { width: '100%', height: '100%' };

const CartItem = ({ styles, cartItems, cart, handleCloseOrder }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const profile = cartItems[0]?.profile;
  const isLoggedIn = useSelector(state => state.auth.loggedIn);
  const dispatch = useDispatch();
  const router = useRouter();

  const isProfilePage = router.pathname === '/u/[username]';

  const redirectToProfile = () => {
    router.push(`/u/${profile.username}`);

    handleCloseOrder();
  };

  const removeCartItem = cartItemId => {
    const uuid = getUuid();

    const endpoint = isLoggedIn
      ? `carts/${cart.id}/cart_items/${cartItemId}`
      : `carts/${uuid}/cart_items/${cartItemId}`;

    deleteRequest({
      endpoint,
    }).then(response => {
      dispatch(setCart(response.data));
      dispatch(closeModal('Confirmation'));

      if (response.data.cart_items.length === 0) {
        dispatch(closeModal('Cart'));
      }
    });
  };

  const handleOpenConfirmation = (action, cartItemId) => {
    dispatch(openModal({ name: 'Confirmation', data: { action } }));
    setSelectedItemId(cartItemId);
    setOpenConfirmation(true);
  };

  const handleClose = () => setIsOpen(false);

  return (
    <div className={styles.profile}>
      <div
        className={styles.profileHeader}
        onClick={() => (isProfilePage ? handleCloseOrder() : redirectToProfile())}
      >
        <Avatar style={alertStyle} src={profile?.avatar_url} alt="Network">
          <NoAvatarImage style={avatarFullSize} />
        </Avatar>
        <span>{profile?.display_name}</span>

        <ChevronRightIcon style={marginLeft} />
      </div>

      <div className={styles.items}>
        {[...cartItems]
          .sort((a, b) => a.network.id - b.network.id)
          .map(item => (
            <div className={styles.cartItem} key={item.id}>
              <div className={styles.network}>
                <Image
                  src={item.network.active_icon_url}
                  width={30}
                  height={30}
                  alt="Network"
                  unoptimized
                />

                <span className={styles.space}>{item.inventory_space.name}</span>

                <span className={styles.price}>{formatPrice(item.price)}</span>
              </div>

              {item?.earliest_start_time && (
                <span style={{ fontSize: 13, color: '#ff4b4b', marginBottom: 5 }}>
                  Earliest Start:{' '}
                  {safeDateFormat(new Date(item.earliest_start_time), 'MMMM d, yyyy', '-')}
                </span>
              )}

              <div className={styles.bottom}>
                <span>Qty: {item.quantity * item.variable_quantity}</span>
                {item.quantity * item.variable_quantity > 1 &&
                  !item.inventory_space.digital_signature && <span>Freq: {item.frequency}</span>}
                {item.inventory_item?.live_time_unit !== 'none' &&
                  !item.inventory_space?.event_attendance && (
                    <span>
                      Live: {item.inventory_item.live_time_duration}{' '}
                      {pluralize(
                        item.inventory_item.live_time_unit,
                        item.inventory_item.live_time_duration
                      )}
                    </span>
                  )}

                <div className={styles.actions}>
                  <EditIcon
                    onClick={() => setSelectedItem(item)}
                    className={styles.hover}
                    width={16}
                    height={16}
                    alt="Pen"
                  />

                  <DeleteIcon
                    onClick={() => handleOpenConfirmation('approve', item.id)}
                    width={16}
                    height={16}
                    alt="Bin"
                    className={styles.hover}
                  />
                </div>
              </div>
            </div>
          ))}

        <div className={styles.discountCode} onClick={() => setIsOpen(true)}>
          <PinIcon width={20} height={20} alt="Pin" />
          {cart.influencer_discounts?.find(disc => disc.profile_id === cartItems[0]?.profile?.id)
            ?.code || 'Add a discount code'}
        </div>

        <DiscountCodeDialog open={isOpen} handleClose={handleClose} />

        <OrderDialog
          inventoryId={selectedItem?.inventory_item?.inventory_id}
          open={!!selectedItem}
          handleClose={() => setSelectedItem(null)}
          cartItem={selectedItem}
        />

        {openConfirmation && (
          <ConfirmationDialog
            title="Are you sure?"
            handleApprove={() => removeCartItem(selectedItemId)}
          />
        )}
      </div>
    </div>
  );
};

CartItem.propTypes = {
  styles: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  cartItems: PropTypes.array,
};

CartItem.defaultProps = {
  cartItems: [],
};

export default CartItem;
