import React from 'react';
import { useDispatch } from 'react-redux';

import { closeModal } from 'features/utilsSlice';
import useModalOpen from 'hooks/useModalOpen';
import TransitionDialog from './TransitionDialog';
import Login from 'pages/login';

const LoginDialog = () => {
  const open = useModalOpen('Login');
  const dispatch = useDispatch();

  const handleClose = () => dispatch(closeModal('Login'));

  return (
    <TransitionDialog open={open} handleClose={handleClose}>
      <div>
        <Login isDialog />
      </div>
    </TransitionDialog>
  );
};

export default LoginDialog;
