import { useSelector } from 'react-redux';

const useIsAgency = () => {
  const currentUser = useSelector(state => state.auth.currentUser);

  return (
    currentUser?.role === 'influencer' &&
    currentUser?.full_management_agency &&
    !currentUser?.currently_managing_profile
  );
};

export default useIsAgency;
