import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cart_items: [],
  },

  reducers: {
    setCart: (_, action) => action.payload,
  },
});

export const { setCart } = cartSlice.actions;

export default cartSlice.reducer;
