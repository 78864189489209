import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';

import { closeModal } from 'features/utilsSlice';
import useModalOpen from 'hooks/useModalOpen';
import TransitionDialog from './TransitionDialog';

const marginTop = { marginTop: 20 };
const margin = { margin: '0 auto' };

const useStyles = makeStyles(theme => ({
  paper: {
    height: 'fit-content',
    width: '90%',
    marginBottom: 'auto',
    top: '15%',
    borderRadius: 10,
    padding: '0 20px 30px 20px',

    [theme.breakpoints.up('md')]: {
      width: '50%',
    },

    [theme.breakpoints.up('lg')]: {
      width: '30%',
    },
  },

  paperFullScreenDialog: {
    width: '100%',
  },
}));

const CropDialog = ({ image, handleAvatarChange, onClose }) => {
  const open = useModalOpen('Crop');
  const dispatch = useDispatch();
  const classes = useStyles();
  const editorRef = useRef();

  const handleClose = () => {
    onClose && onClose();
    dispatch(closeModal('Crop'));
  };

  return (
    <TransitionDialog
      open={open}
      handleClose={handleClose}
      paperProps={{ className: classes.paper }}
      dialogStyles={classes.paperFullScreenDialog}
      direction="up"
      desktopDirection="up"
      noCloseIcon
    >
      <AvatarEditor
        ref={editorRef}
        image={image}
        width={200}
        height={200}
        border={50}
        color={[255, 255, 255, 0.6]}
        scale={1.2}
        borderRadius={100}
        rotate={0}
        style={margin}
      />

      <Button
        onClick={() => {
          editorRef.current.getImageScaledToCanvas().toBlob(avatar => handleAvatarChange(avatar));
          dispatch(closeModal('Crop'));
        }}
        variant="contained"
        color="primary"
        style={marginTop}
      >
        Crop
      </Button>
    </TransitionDialog>
  );
};

CropDialog.propTypes = {
  handleAvatarChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

CropDialog.defaultProps = {
  onClose: () => {},
};

export default CropDialog;
