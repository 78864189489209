import React from 'react';
import { formatPrice } from 'utils/helpers';

const Subtotal = ({ styles, cart }) => (
  <div className={styles.subtotal}>
    <h3>SUBTOTAL</h3>

    <div className={styles.row}>
      Inventory: <span>{formatPrice(cart.inventory_price)}</span>
    </div>

    <div className={styles.row}>
      GigSocial Fee: <span>{formatPrice(cart.fee)}</span>
    </div>

    {(cart.bundle_discount || cart.influencer_discounts) && (
      <div className={styles.row}>
        {cart.influencer_discounts && (
          <div>
            Discounts:
            {cart.influencer_discounts.map(discount => (
              <div className={styles.bundle} key={discount.code}>
                <span className="avenir-heavy">Code: {discount.code}</span>
                <span className="color-red">-{formatPrice(discount.discount)}</span>
              </div>
            ))}
            {cart.promotional_discount > 0 && (
              <div className={styles.bundle}>
                <span className="avenir-heavy">LoyalFans Promo</span>
                <span className="color-red">-{formatPrice(cart.promotional_discount)}</span>
              </div>
            )}
          </div>
        )}

        {cart.bundle_discount > 0 && (
          <div className={styles.bundle}>
            <span className="avenir-heavy">Bundle</span>{' '}
            <span className="color-red">-{formatPrice(cart.bundle_discount)}</span>
          </div>
        )}

        <div>
          Discount total:
          <span className="color-red">
            <strong>-{formatPrice(cart.total_discount)}</strong>
          </span>
        </div>
      </div>
    )}

    <div className={styles.row}>
      <span className="avenir-heavy">Total to pay:</span>{' '}
      <span className={styles.price}>{formatPrice(cart.total_checkout_price)}</span>
    </div>
  </div>
);

export default Subtotal;
