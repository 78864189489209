import React, { useState, useRef, useEffect } from 'react';
import { Avatar } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { normalizePrimaryInterest } from 'utils/helpers';
import useCurrentProfile from 'hooks/useCurrentProfile';
import NoAvatarImage from 'public/images/no-avatar.svg';
import ChevronIcon from 'public/icons/chevron-down-green.svg';
import SwitchPrimaryInterestPopper from './SwitchPrimaryInterestPopper';
import styles from 'styles/components/home/Header.module.scss';

const avatarStyle = { width: 30, height: 30 };
const avatarFullSize = { width: '100%', height: '100%' };

const SwitchPrimaryInterest = () => {
  const [primaryInterest, setPrimaryInterest] = useState('As Seller');
  const [open, setOpen] = useState(false);

  const currentPrimaryInterest = useSelector(state => state.auth.currentUser?.primary_interest);
  const normalizedInterest = normalizePrimaryInterest(currentPrimaryInterest);
  const currentProfile = useCurrentProfile();
  const anchorRef = useRef(null);

  const handleToggle = () => setOpen(prevOpen => !prevOpen);

  useEffect(() => {
    if (normalizedInterest !== primaryInterest) setPrimaryInterest(normalizedInterest);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [normalizedInterest]);

  return (
    <>
      <div className={styles.button} ref={anchorRef} onClick={handleToggle}>
        <Avatar style={avatarStyle} src={currentProfile?.avatar_url}>
          <NoAvatarImage style={avatarFullSize} />
        </Avatar>

        <h3>{primaryInterest}</h3>

        <ChevronIcon width={8} height={8} />
      </div>

      <SwitchPrimaryInterestPopper open={open} setOpen={setOpen} anchorRef={anchorRef} />
    </>
  );
};

export default SwitchPrimaryInterest;
