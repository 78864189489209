import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from 'styles/components/Switch.module.scss';

const goRight = { transform: 'translate(0, -50%)', left: 10 };
const goLeft = { transform: 'translate(102%, -50%)' };
const white = { color: '#fff' };

const Switch = ({ firstOption, secondOption, value, setValue, disabled }) => (
  <div className={styles.switchContainer}>
    <div
      className={clsx(styles.container, {
        [styles.selectedFirst]: value === firstOption.value,
        [styles.selectedSecond]: value === secondOption.value,
      })}
    />
    <div
      className={clsx(styles.button, { [styles.nothingSelected]: value === 'nothing-selected' })}
      style={value === firstOption.value ? goRight : goLeft}
    />

    <div className={styles.labels}>
      <span
        onClick={() => setValue(firstOption.value)}
        style={value === firstOption.value ? white : {}}
      >
        {firstOption.label}
      </span>

      <span
        onClick={disabled ? null : () => setValue(secondOption.value)}
        style={value === secondOption.value ? white : {}}
      >
        {secondOption.label}
      </span>
    </div>
  </div>
);

Switch.propTypes = {
  firstOption: PropTypes.object.isRequired,
  secondOption: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Switch.defaultProps = {
  disabled: false,
};

export default Switch;
