import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Fragment } from 'react';

import { updateProfile } from 'features/authSlice';
import { postRequest } from 'utils/api';
import { nextStep } from 'features/influencerSetupSlice';
import { setInventoryStep } from 'utils/localStorage';
import TagSelection from 'components/TagSelection';
import AnimateStepChange from 'components/animations/AnimateStepChange';
import useCurrentProfile from 'hooks/useCurrentProfile';

const SelectExperience = ({ handleSuccess, showSteps, stepButtonText, shouldAnimate = true }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const profile = useCurrentProfile();
  const inputRef = useRef();

  const handleExperienceStepChange = names => {
    setIsLoading(true);

    postRequest({
      endpoint: `profiles/${profile.id}/experiences`,
      data: { names },
    })
      .then(response => {
        dispatch(updateProfile({ id: profile.id, experiences: response.data }));

        if (handleSuccess) {
          handleSuccess();
        } else {
          setInventoryStep(profile?.id, 5);
          dispatch(nextStep());
          router.push('/inventory/profile-info');
        }
      })
      .catch(() => setIsLoading(false));
  };

  const AnimateComponent = shouldAnimate ? AnimateStepChange : Fragment;

  return (
    <AnimateComponent>
      <TagSelection
        title="traits"
        subtitle="List some traits that best describe you."
        placeholder="Type to add"
        endpoint="experiences"
        handleStepChange={handleExperienceStepChange}
        defaultSelected={profile?.experiences}
        inputRef={inputRef}
        isLoading={isLoading}
        showSteps={showSteps}
        stepButtonText={stepButtonText}
      />
    </AnimateComponent>
  );
};

export default SelectExperience;
