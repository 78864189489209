import Compressor from 'compressorjs';

const compressImage = (file, options = {}) =>
  new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.8,
      strict: true,
      checkOrientation: true,
      ...options,
      success: result => {
        resolve(new File([result], file.name, { type: result.type }));
      },
      error: error => reject(error),
    });
  });

export default compressImage;
