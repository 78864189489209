import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { openModal } from 'features/utilsSlice';
import compressImage from 'utils/compressImage';
import CropDialog from './CropDialog';

const compressOptions = { quality: 0.8, maxWidth: 750, maxHeight: 750 };

const AvatarInput = ({ fileRef, handleDrop, preview, setPreview }) => {
  const [avatar, setAvatar] = useState(null);
  const dispatch = useDispatch();

  return (
    <div>
      <input
        ref={fileRef}
        type="file"
        name="avatar"
        onChange={e => {
          if (preview) URL.revokeObjectURL(preview);

          const file = e.target.files[0];

          if (['image/heic', 'image/heif'].includes(file?.type)) {
            require('heic2any')({
              blob: file,
              toType: 'image/jpeg',
              quality: 1,
              multiple: false,
            })
              .then(blob => {
                const result = new File([blob], file.name, { type: blob.type });

                compressImage(result, compressOptions)
                  .then(compressedFile => {
                    const preview = URL.createObjectURL(compressedFile);

                    setPreview(preview);
                    setAvatar(compressedFile);
                  })
                  .catch(() => {
                    const preview = URL.createObjectURL(result);

                    setPreview(preview);
                    setAvatar(result);
                  });
              })
              .catch(e => {
                console.log('Error converting heic to jpeg', e);
              });
          } else if (['image/jpeg', 'image/jpg', 'image/png'].includes(file?.type)) {
            compressImage(file, compressOptions)
              .then(compressedFile => {
                const preview = URL.createObjectURL(compressedFile);

                setPreview(preview);
                setAvatar(compressedFile);
              })
              .catch(() => {
                const preview = URL.createObjectURL(file);

                setPreview(preview);
                setAvatar(file);
              });
          }

          dispatch(openModal({ name: 'Crop' }));
        }}
        accept="image/jpg, image/jpeg, image/png, image/heic, image/heif"
      />

      <CropDialog
        image={preview}
        onClose={() => handleDrop(avatar)}
        handleAvatarChange={blob => {
          if (preview) URL.revokeObjectURL(preview);

          setPreview(URL.createObjectURL(blob));
          setAvatar(blob);
          handleDrop(blob);
        }}
      />
    </div>
  );
};

AvatarInput.propTypes = {
  fileRef: PropTypes.object.isRequired,
  handleDrop: PropTypes.func.isRequired,
  setPreview: PropTypes.func.isRequired,
  preview: PropTypes.string,
};

AvatarInput.defaultProps = {
  preview: '',
};

export default AvatarInput;
