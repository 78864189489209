import AnnouncementNotification from 'components/AnnouncementNotification';
import Notification from 'components/Notification';
import React from 'react';
import { toast } from 'react-toastify';

// eslint-disable-next-line react/prop-types
const Message = ({ message }) => <span>{message}</span>;

export default function Toast(type, message, options = {}, icon) {
  toast[type](<Message message={message} />, options, { icon });
}

export function NotificationToast(notification, switchProfile = false, options = {}, icon) {
  toast(
    <Notification notification={notification} switchProfile={switchProfile} />,
    { ...options, className: 'notification-toast' },
    { icon }
  );
}

export function AnnouncementToast(announcement) {
  toast(<AnnouncementNotification announcement={announcement} />, {
    className: 'notification-toast',
  });
}
