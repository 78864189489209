import React from 'react';
import PropTypes from 'prop-types';

import styles from 'styles/components/Badge.module.scss';

const maxNumber = 99;

const Badge = ({ content, children, badgeStyles }) => (
  <div className={styles.badgeContainer}>
    {content > 0 ? (
      <div className={styles.badge} style={badgeStyles}>
        {content > maxNumber ? '99+' : content}
      </div>
    ) : null}

    {children}
  </div>
);

Badge.propTypes = {
  content: PropTypes.number,
  children: PropTypes.node,
  badgeStyles: PropTypes.object,
};

Badge.defaultProps = {
  content: null,
  children: null,
  badgeStyles: null,
};

export default Badge;
