import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import OpenNewTab from 'public/icons/open-new-tab.svg';
import { closeModal } from 'features/utilsSlice';
import useModalOpen from 'hooks/useModalOpen';
import TransitionDialog from 'components/TransitionDialog';

const useStyles = makeStyles({
  paper: {
    height: '100%',
    width: '100%',
    marginBottom: 'auto',
  },

  paperFullScreenDialog: {
    width: '100%',
    margnTop: 20,
  },
});

const ExternalLinkDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const open = useModalOpen('External Link');
  const data = useSelector(state => state.utils.modalData['External Link']);

  const handleClose = () => dispatch(closeModal('External Link'));

  if (!data || !open) return null;

  return (
    <TransitionDialog
      open={open}
      handleClose={handleClose}
      paperProps={{ className: classes.paper }}
      dialogStyles={classes.paperFullScreenDialog}
      direction="up"
      desktopDirection="up"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 15,
          borderBottom: '1px solid #eee',
        }}
      >
        <h3>{data.page}</h3>

        <a
          style={{ position: 'absolute', right: 15 }}
          href={data.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <OpenNewTab fontSize={15} fill="#5b00db" />
        </a>
      </div>

      {data.isImage ? (
        <img src={data.url} alt="Preview" />
      ) : (
        <iframe style={{ border: 0, zIndex: 9999 }} src={data.url} width="100%" height="100%" />
      )}
    </TransitionDialog>
  );
};

export default ExternalLinkDialog;
