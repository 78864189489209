import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { updateCurrentUser } from 'features/authSlice';
import { getMasquerading } from 'utils/localStorage';
import { putRequest } from 'utils/api';
import useCurrentUser from 'hooks/useCurrentUser';
import useCurrentProfile from 'hooks/useCurrentProfile';

const ManagingProfileHeader = () => {
  const [masquerading, setMasquerading] = useState(false);

  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const router = useRouter();
  const profile = useCurrentProfile();
  const moveTop = masquerading ? 100 : 50;
  const managingProfile =
    currentUser?.full_management_agency && currentUser?.currently_managing_profile;

  const backToAgency = () => {
    putRequest({
      endpoint: `users/${currentUser.id}/update_currently_managing_profile`,
      data: { currently_managing_profile: false },
    })
      .then(() => {
        router.push('/dashboard').then(() => {
          dispatch(updateCurrentUser({ currently_managing_profile: false }));
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    const masquerading = getMasquerading();
    setMasquerading(masquerading);
  }, []);

  if (!managingProfile) return null;

  return (
    <div className="acting-container" style={{ backgroundColor: '#c3e1de', top: moveTop }}>
      <div style={{ opacity: 0.8 }}>
        Acting as <span>{profile?.display_name}</span> -{' '}
        <span onClick={backToAgency} className="color-main hover">
          back to agency
        </span>
      </div>
    </div>
  );
};

export default ManagingProfileHeader;
