import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CircularProgress, Hidden } from '@material-ui/core';
import clsx from 'clsx';

import { addInventories, addInventorySpaces, nextStep } from 'features/influencerSetupSlice';
import { getRequest } from 'utils/api';
import { closeModal } from 'features/utilsSlice';
import { setInventoryStep } from 'utils/localStorage';
import useCurrentProfile from 'hooks/useCurrentProfile';
import InventoryModal from './InventoryModal';
import NetworkIcon from '../NetworkIcon';
import InventorySpaces from './InventorySpaces';
import Stepper from 'components/Stepper';
import AnimateList from 'components/animations/AnimateList';
import AnimateListItem from 'components/animations/AnimateListItem';
import styles from 'styles/components/influencerSetup/SelectInventory.module.scss';

const justifyCenter = { justifyContent: 'center' };
const progressStyle = { marginTop: 50 };

const SelectInventory = ({ handleSuccess, stepButtonText, showSteps, fanOnly = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { currentInfluencerNetwork, inventories, inventorySpaces } = useSelector(
    state => state.influencerSetup
  );
  const profile = useCurrentProfile();

  const goNext = () => {
    if (handleSuccess) {
      handleSuccess();
    }

    setInventoryStep(profile.id, 3);
    dispatch(nextStep());
  };

  const fetchInventories = useCallback(() => {
    if (!currentInfluencerNetwork?.network_id) return;

    getRequest({
      endpoint: 'inventories',
      query: {
        profile_id: profile?.id,
        per_page: 200,
        network_id: currentInfluencerNetwork?.network_id,
      },
    })
      .then(inventories => dispatch(addInventories(inventories)))
      .catch(() => {});
  }, [currentInfluencerNetwork, dispatch, profile?.id]);

  useEffect(() => {
    if (!currentInfluencerNetwork?.network_id) return;

    setIsLoading(true);
    getRequest({
      endpoint: 'inventory_spaces',
      query: { network_id: currentInfluencerNetwork.network_id, per_page: 100 },
    })
      .then(spaces => {
        dispatch(addInventorySpaces(spaces));
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));

    fetchInventories();
  }, [currentInfluencerNetwork, dispatch, fetchInventories]);

  return (
    <div className={clsx(styles.maxHeight, { [styles.padding]: currentInfluencerNetwork })}>
      {!currentInfluencerNetwork && (
        <>
          <h3>INVENTORY</h3>

          <h1>Lets add some inventory to your networks.</h1>
        </>
      )}

      <AnimateList className={styles.selectedNetworks} style={justifyCenter}>
        <AnimateListItem
          key={currentInfluencerNetwork?.network.id}
          className={clsx(styles.network, {
            [styles.selectedNetwork]: true,
            [styles.allBorder]: false,
            [styles.noMargin]: currentInfluencerNetwork,
          })}
        >
          <Hidden smDown>
            <NetworkIcon
              active={true}
              hovered={true}
              network={{
                ...currentInfluencerNetwork?.network,
                name: fanOnly ? 'Fan Items' : currentInfluencerNetwork?.network?.name,
              }}
              styles={styles}
            />
          </Hidden>

          <Hidden mdUp>
            <NetworkIcon
              active={true}
              network={{
                ...currentInfluencerNetwork?.network,
                name: fanOnly ? 'Fan Items' : currentInfluencerNetwork?.network?.name,
              }}
              styles={styles}
            />
          </Hidden>
        </AnimateListItem>
      </AnimateList>

      {isLoading && <CircularProgress style={progressStyle} color="primary" />}

      {!currentInfluencerNetwork && !isLoading && (
        <div className={styles.description}>
          Tap a network icon above to start adding your inventory options.
        </div>
      )}

      {currentInfluencerNetwork && !isLoading && (
        <InventorySpaces
          fanOnly={fanOnly}
          inventorySpaces={inventorySpaces}
          inventories={inventories}
        />
      )}

      <InventoryModal fetchInventories={fetchInventories} profile={profile} />

      <Stepper
        buttonText={stepButtonText}
        handleStepChange={goNext}
        showSteps={showSteps}
        disabled={inventories.length === 0}
        newStyles={styles.wrapper}
      />
    </div>
  );
};

export default SelectInventory;
