import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { Dialog, Slide } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';

const iconStyle = {
  padding: '20px 15px',
  position: 'absolute',
  zIndex: 10,
};

const useStyles = makeStyles(theme => ({
  paperFullScreen: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginLeft: 'auto',
    },

    [theme.breakpoints.up('lg')]: {
      width: '35%',
      marginLeft: 'auto',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} {...props} />;
});

const TransitionDialog = ({
  open,
  handleClose,
  styles,
  children,
  direction,
  desktopDirection,
  whiteExit,
  newStyle,
  noCloseIcon,
  paperProps,
  dialogStyles,
  disablePortal,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Dialog
      className={!isEmpty(dialogStyles) ? dialogStyles : classes.paperFullScreen}
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionProps={{ direction: desktop ? desktopDirection : direction }}
      PaperProps={paperProps}
      TransitionComponent={Transition}
      style={newStyle}
      disablePortal={disablePortal}
    >
      {open && !noCloseIcon && (
        <div
          className={clsx(styles.closeIcon, 'hover-icon', { ['white-exit']: whiteExit })}
          onClick={handleClose}
          style={iconStyle}
        >
          <Image
            src={whiteExit ? '/icons/close-white.svg' : '/icons/close-main.svg'}
            width={15}
            height={15}
            alt="Close"
            unoptimized
          />
        </div>
      )}

      {open && children}
    </Dialog>
  );
};

TransitionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  direction: PropTypes.string,
  desktopDirection: PropTypes.string,
  whiteExit: PropTypes.bool,
  newStyle: PropTypes.object,
  noCloseIcon: PropTypes.bool,
  styles: PropTypes.object,
  handleClose: PropTypes.func,
  paperProps: PropTypes.object,
  children: PropTypes.node,
  disablePortal: PropTypes.bool,
};

TransitionDialog.defaultProps = {
  direction: 'left',
  desktopDirection: 'left',
  whiteExit: false,
  newStyle: {},
  noCloseIcon: false,
  styles: {},
  paperProps: {},
  handleClose: () => {},
  children: null,
  disablePortal: false,
};

export default TransitionDialog;
