import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import get from 'lodash/get';
import clsx from 'clsx';

import { openModal } from 'features/utilsSlice';
import { useDispatch } from 'react-redux';
import DropzoneFieldMultiple from 'components/gigSetup/DropzoneFieldMultiple';
import DeleteIcon from 'public/icons/bin.svg';
import VideoIcon from 'public/icons/video.svg';
import SearchIcon from 'public/icons/search.svg';
import AssetPreviewDialog from 'components/AssetPreviewDialog';
import styles from 'styles/components/gigSetup/GigItemDialog.module.scss';

const marginVertical = { margin: '10px 0 10px' };
const noMarginTop = { marginTop: 0 };
const assetsTypeLabels = {
  assets_provided: 'Post a photo/video I provide (shoutout, ad, repost)',
  creator_assets: 'Have influencer create the content for the post',
};
const AddAssets = ({
  space,
  setFieldValue,
  item,
  removeAsset,
  isVideo,
  errors,
  deletedAssetIds,
  assetsType,
  newAssets,
  setFieldTouched,
  setAssetsType,
  checked,
}) => {
  const [openPreview, setOpenPreview] = useState(false);

  const assetsRequired = assetsType === 'assets_provided' || checked;

  const assetsUploaded =
    (newAssets.length === 0 && item?.assets?.length === 0) ||
    (newAssets.length === 0 && item?.assets?.every(i => deletedAssetIds.includes(i.id))) ||
    (!item?.assets && newAssets.length === 0);

  const assetsValid = assetsRequired && assetsUploaded;

  const dispatch = useDispatch();

  return (
    <>
      <h4>{space?.has_content ? 'SAMPLE PHOTOS/VIDEOS' : 'PHOTOS/VIDEOS FOR POST'}</h4>

      {space?.show_asset_choices && (
        <>
          <InputLabel>What type of post is this?</InputLabel>

          <RadioGroup name="assets_type" className={styles.radioButtons}>
            <FormControlLabel
              value="assets_provided"
              control={<Radio color="primary" />}
              label={assetsTypeLabels['assets_provided']}
              onChange={e => setAssetsType(e.target.value)}
              className={assetsType === 'assets_provided' ? styles.active : ''}
              checked={assetsType === 'assets_provided'}
            />

            <FormControlLabel
              value="creator_assets"
              control={<Radio color="primary" />}
              label={assetsTypeLabels['creator_assets']}
              onChange={e => setAssetsType(e.target.value)}
              className={assetsType === 'creator_assets' ? styles.active : ''}
              checked={assetsType === 'creator_assets'}
            />
          </RadioGroup>
        </>
      )}

      {(['assets_provided', 'creator_assets'].includes(assetsType) ||
        item?.assets?.length > 0 ||
        checked) && (
        <>
          <InputLabel>
            Upload assets{' '}
            <span className="fw-n">
              {assetsType === 'assets_provided' ? '(required)' : '(optional)'}
            </span>
          </InputLabel>

          <DropzoneFieldMultiple
            performDirectUpload
            uploads={newAssets}
            accept="image/jpg, image/jpeg, image/png, image/heic, image/heif, video/mov, video/mpeg, video/mp4, video/quicktime"
            setUploads={uploads => setFieldValue('new_assets', [...newAssets, ...uploads])}
            handleClick={() => setFieldTouched('new_assets', true)}
            compress
          />

          {assetsValid && (
            <span className="error-msg" style={noMarginTop}>
              is required.
            </span>
          )}

          <div style={marginVertical}>File types allowed: jpg, jpeg, mpeg, mp4, mov</div>

          <div className={styles.assets}>
            {item?.assets
              ?.filter(asset => !deletedAssetIds.includes(asset.id))
              .map(asset => (
                <div key={asset.id} className={styles.asset}>
                  <div
                    className={styles.iconContainer}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeAsset(asset);
                    }}
                  >
                    <DeleteIcon className="hover-icon" />
                  </div>

                  <div>
                    {asset.video ? (
                      <a href={asset.url} target="_blank" rel="noreferrer">
                        <video width={60} height={60} src={asset.url}></video>
                      </a>
                    ) : (
                      <img src={asset.url} width={60} height={60} alt="Asset" />
                    )}
                  </div>
                </div>
              ))}

            {newAssets.map((asset, index) => (
              <div key={index} className={styles.asset}>
                <div
                  className={styles.iconContainer}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    const newAssetsArray = newAssets;
                    newAssetsArray.splice(index, 1);

                    setFieldValue('new_assets', newAssetsArray);
                  }}
                >
                  <DeleteIcon className="hover-icon" />
                </div>

                {isVideo(asset.file.type) ? (
                  <a href={asset.preview} target="_blank" rel="noreferrer">
                    {asset.file.type === 'video/mp4' ? (
                      <video src={asset.preview} width={60} height={60} alt="Asset" />
                    ) : (
                      <VideoIcon width={60} height={60} />
                    )}
                  </a>
                ) : (
                  <div
                    onClick={() => {
                      setOpenPreview(true);
                      dispatch(
                        openModal({
                          name: 'Asset Preview',
                          data: { src: asset.preview },
                        })
                      );
                    }}
                    className={clsx('hover', 'asset-container')}
                  >
                    <img src={asset.preview} width={60} height={60} alt="Asset" />
                    <SearchIcon className="search-icon" />
                  </div>
                )}

                <span className={isVideo ? styles.videoError : styles.error}>
                  {get(errors, 'new_assets', [])[index]?.file}
                </span>
              </div>
            ))}
          </div>
        </>
      )}

      {openPreview && <AssetPreviewDialog />}
    </>
  );
};

AddAssets.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  removeAsset: PropTypes.func.isRequired,
  isVideo: PropTypes.func.isRequired,
  assetsType: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  item: PropTypes.object,
  errors: PropTypes.object,
  newAssets: PropTypes.array,
  deletedAssetIds: PropTypes.array,
};

AddAssets.defaultProps = {
  item: {},
  errors: {},
  newAssets: [],
  checked: false,
  deletedAssetIds: [],
};

export default AddAssets;
