import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { formatDistanceToNow } from 'date-fns';
import clsx from 'clsx';

import { removeNotification } from 'features/utilsSlice';
import { postRequest, putRequest } from 'utils/api';
import styles from 'styles/components/Notification.module.scss';

const imageByKind = kind => {
  switch (kind) {
    case 'gig_application_accepted':
    case 'gig_invitation_accepted':
      return '/icons/notifications/accept.svg';
    case 'milestone_approved':
      return '/icons/notifications/delivery.svg';
    case 'gig_application_rejected':
    case 'gig_invitation_rejected':
    case 'gig_invitation_expired':
    case 'milestone_rejected':
    case 'gig_payment_incomplete':
      return '/icons/notifications/reject.svg';
    case 'gig_payment_reminder':
      return '/icons/notifications/sell.svg';
    case 'milestone_post':
    case 'milestone_posted':
      return '/icons/notifications/post.svg';
    case 'milestone_awaiting_approval':
      return '/icons/notifications/inventory.svg';
    case 'milestone_takedown':
    case 'milestone_takedown_soon':
      return '/icons/notifications/shoutout.svg';
    case 'new_gig_invitation':
      return '/icons/notifications/offers.svg';
    case 'new_order':
      return '/icons/notifications/order.svg';
    default:
      return '/icons/notifications/default.svg';
  }
};

const Notification = ({ notification, showTime = false, switchProfile = false, handleRemove }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    putRequest({ endpoint: `notifications/${notification.id}/mark_as_read` })
      .then(() => {
        dispatch(removeNotification(notification.id));
        if (handleRemove) handleRemove(notification.id);
      })
      .catch(() => {});
  };

  const handleClick = e => {
    if (switchProfile && notification.url && notification.notifiable_type === 'Profile') {
      e.preventDefault();
      e.stopPropagation();

      Promise.all([
        putRequest({ endpoint: `notifications/${notification.id}/mark_as_read` }),
        postRequest({ endpoint: `profiles/${notification.notifiable_id}/select` }),
      ])
        .then(() => {
          window.location.href = notification.url;
        })
        .catch(() => {});
    } else {
      handleClose();
    }
  };

  const red = [
    'gig_application_rejected',
    'gig_invitation_rejected',
    'milestone_rejected',
    'gig_payment_incomplete',
    'gig_invitation_expired',
  ].includes(notification.kind);

  return (
    <div className={clsx(styles.notification, { [styles.red]: red })}>
      <span className={clsx(styles.icon, { [styles.redIcon]: red })}>
        <Image
          src={imageByKind(notification.kind)}
          alt="image"
          width={30}
          height={30}
          unoptimized
        />
      </span>

      <Link href={notification.url || ''} passHref>
        <div className={styles.text} onClick={handleClick}>
          <h3>{notification.title}</h3>

          <p>
            <span dangerouslySetInnerHTML={{ __html: notification.text }}></span>

            {notification.url && <span className={styles.viewMore}> Click to view more</span>}
          </p>

          {showTime && (
            <span style={{ marginTop: 10, fontStyle: 'italic', opacity: 0.6 }}>
              {formatDistanceToNow(new Date(notification.created_at), {
                addSuffix: true,
              })}
            </span>
          )}
        </div>
      </Link>

      <IconButton className={styles.close} onClick={handleClose}>
        <Image src="/icons/close-main.svg" alt="close" width={12} height={12} unoptimized />
      </IconButton>
    </div>
  );
};

export default Notification;
