import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Fragment } from 'react';

import { updateProfile } from 'features/authSlice';
import { postRequest } from 'utils/api';
import { nextStep } from 'features/influencerSetupSlice';
import { setInventoryStep } from 'utils/localStorage';
import useCurrentProfile from 'hooks/useCurrentProfile';
import AnimateStepChange from 'components/animations/AnimateStepChange';
import TraitSelection from './TraitSelection';

const SelectTraits = ({
  shouldAnimate = true,
  showSteps,
  handleSuccess,
  isDialog,
  showButtons,
  noSteps,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [haveSteps, setHaveSteps] = useState(showSteps);
  const dispatch = useDispatch();
  const router = useRouter();
  const profile = useCurrentProfile();
  const inputRef = useRef();

  const AnimateComponent = shouldAnimate ? AnimateStepChange : Fragment;

  const handleTraitStepChange = ids => {
    setIsLoading(true);

    postRequest({
      endpoint: `profiles/${profile.id}/traits`,
      data: { trait_ids: ids },
    })
      .then(response => {
        dispatch(updateProfile({ id: profile.id, trait_ids: response.data.map(r => r.id) }));

        if (profile.completed && !isDialog) {
          router.push('/dasboard');
        } else if (handleSuccess) {
          handleSuccess();
        } else {
          setInventoryStep(profile?.id, 4);
          dispatch(nextStep());
          router.push('/inventory/congratulations');
        }
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    if (profile.completed) {
      setHaveSteps(false);
      setInventoryStep(profile?.id, 4);
    }
  }, [profile.completed, profile?.id]);

  return (
    <AnimateComponent>
      <TraitSelection
        title="Traits"
        endpoint="trait_categories"
        subtitle="To find better matches for you, tell us a bit more about yourself."
        handleStepChange={handleTraitStepChange}
        defaultSelectedIds={profile?.trait_ids}
        inputRef={inputRef}
        isLoading={isLoading}
        showSteps={haveSteps}
        isDialog={isDialog}
        noSteps={noSteps}
        showButtons={showButtons}
      />
    </AnimateComponent>
  );
};

export default SelectTraits;
