import React, { useEffect, useCallback, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce, TextField } from '@material-ui/core';

import { getRequest } from 'utils/api';

const SchoolsAutocomplete = ({
  name,
  school = null,
  state,
  schoolType,
  handleSelect,
  handleBlur,
  error = false,
}) => {
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(
    debounce(
      text =>
        getRequest({
          endpoint: '/schools',
          query: { state, kind: schoolType, q: text },
        })
          .then(data => setData(data))
          .catch(() => {}),
      500
    ),
    [state, schoolType]
  );

  useEffect(() => {
    search(inputValue);
  }, [search, inputValue]);

  return (
    <Autocomplete
      onBlur={handleBlur}
      value={school}
      inputValue={inputValue}
      autoComplete
      options={data}
      freeSolo
      blurOnSelect
      getOptionSelected={(option, value) => option?.id === value?.id}
      getOptionLabel={option => option.name || ''}
      onChange={(e, newValue) => {
        e.preventDefault();
        e.stopPropagation();

        setInputValue('');

        if (!newValue) return;

        if (e.keyCode === 13) {
          handleSelect(typeof newValue === 'string' ? newValue : newValue);
        } else {
          handleSelect(newValue);
        }
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'clear') {
          setInputValue('');
        } else {
          setInputValue(newInputValue);
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            name,
            placeholder: 'School Name (no abbreviations)',
          }}
          error={error}
        />
      )}
    />
  );
};

export default SchoolsAutocomplete;
