import { v4 as uuidv4 } from 'uuid';

export const isServer = () => typeof window === 'undefined';

export const setCurrentUserLS = currentUser => {
  if (isServer()) return;

  localStorage.setItem('current_user', JSON.stringify(currentUser));
};

export const getCurrentUserLS = () => {
  if (isServer()) return;

  return JSON.parse(localStorage.getItem('current_user'));
};

export const saveAccessToken = token => {
  if (isServer()) return;

  localStorage.setItem('gig_social_access_token', token);
};

export const deleteAccessToken = () => {
  if (isServer()) return;

  localStorage.removeItem('gig_social_access_token');
  localStorage.removeItem('gig_social_masquerading');
};

export const getAccessToken = () => {
  if (isServer()) return;

  return localStorage.getItem('gig_social_access_token');
};

export const getUuid = () => {
  if (isServer()) return;

  return localStorage.getItem('gig_social_uuid');
};

export const generateUuid = () => {
  if (isServer()) return;

  localStorage.setItem('gig_social_uuid', uuidv4());
};

export const deleteUuid = () => {
  if (isServer()) return;

  localStorage.removeItem('gig_social_uuid');
};

export const getMasquerading = () => {
  if (isServer()) return;

  return localStorage.getItem('gig_social_masquerading');
};

export const setReferralCode = ref => {
  if (isServer()) return;
  if (getReferralCode()) return;

  localStorage.setItem('gig_social_referral_code', ref);
};

export const getReferralCode = () => {
  if (isServer()) return;

  return localStorage.getItem('gig_social_referral_code');
};

export const setLastSignInRole = role => {
  if (isServer()) return;

  localStorage.setItem('last_sign_in_role', role);
};

export const getLastSignInRole = () => {
  if (isServer()) return;

  return localStorage.getItem('last_sign_in_role');
};

export const setDiscoverTab = tab => {
  if (isServer()) return;

  localStorage.setItem('discover_tab', tab);
};

export const getDiscoverTab = () => {
  if (isServer()) return;

  return localStorage.getItem('discover_tab');
};

export const setSelectedNetworkIdsFilter = networks => {
  if (isServer()) return;

  localStorage.setItem('selected_networks', networks);
};

export const getSelectedNetworkIdsFilter = () => {
  if (isServer()) return;

  try {
    return JSON.parse(localStorage.getItem('selected_networks'));
  } catch {
    return [];
  }
};

export const setHowToCollapsed = collapsed => {
  if (isServer()) return;

  localStorage.setItem('how_to_collapsed', collapsed);
};

export const getHowToCollapsed = () => {
  if (isServer()) return;

  return JSON.parse(localStorage.getItem('how_to_collapsed'));
};

export const setInventorySetup = isFirst => {
  if (isServer()) return;

  return localStorage.setItem('inventory_setup', isFirst);
};

export const getInventorySetup = () => {
  if (isServer()) return;

  return JSON.parse(localStorage.getItem('inventory_setup'));
};

export const setInventoryStep = (profileId, step) => {
  if (isServer()) return;

  return localStorage.setItem(`inventory_step_${profileId}`, step);
};

export const getInventoryStep = profileId => {
  if (isServer()) return;

  return JSON.parse(localStorage.getItem(`inventory_step_${profileId}`));
};

export const setGigEditStep = (gigId, step) => {
  if (isServer()) return;

  return localStorage.setItem(`gig_edit_step_${gigId}`, step);
};

export const getGigEditStep = gigId => {
  if (isServer()) return;

  return JSON.parse(localStorage.getItem(`gig_edit_step_${gigId}`));
};

export const removeGigEditStep = gigId => {
  if (isServer()) return;

  localStorage.removeItem(`gig_edit_step_${gigId}`);
};

export const setLastGigId = (gigId, status) => {
  if (isServer()) return;

  return localStorage.setItem(`last_${status}_gig_id`, gigId);
};

export const getLastGigId = status => {
  if (isServer()) return;

  return localStorage.getItem(`last_${status}_gig_id`);
};

export const setPreviousGigId = (gigId, status) => {
  if (isServer()) return;

  return localStorage.setItem(`previous_${status}_gig_id`, gigId);
};

export const getPreviousGigId = status => {
  if (isServer()) return;

  return localStorage.getItem(`previous_${status}_gig_id`);
};

export const setRedirectTo = path => {
  if (isServer()) return;

  if (path) {
    return localStorage.setItem('redirect_to', path);
  }

  localStorage.removeItem('redirect_to');
};

export const getRedirectTo = () => {
  if (isServer()) return;

  return localStorage.getItem('redirect_to');
};

export const setOffersMessage = action => {
  if (isServer()) return;

  return localStorage.setItem('offers_message', action);
};

export const getOffersMessage = () => {
  if (isServer()) return;

  return localStorage.getItem('offers_message');
};

export const setProfileOnboardingStep = action => {
  if (isServer()) return;

  return localStorage.setItem('profile_onboarding_step', action);
};

export const getProfileOnboardingStep = () => {
  if (isServer()) return;

  return JSON.parse(localStorage.getItem('profile_onboarding_step'));
};

export const getPayoutAttributesConsent = () => {
  if (isServer()) return;

  return JSON.parse(localStorage.getItem('gig_social_payout_attributes_consent'));
};

export const setPayoutAttributesConsent = consent => {
  if (isServer()) return;

  return localStorage.setItem('gig_social_payout_attributes_consent', consent);
};

export const setExtendPriceChange = extended => {
  if (isServer()) return;

  localStorage.setItem('extend_price_change', extended);
};

export const getExtendPriceChange = () => {
  if (isServer()) return;

  return JSON.parse(localStorage.getItem('extend_price_change'));
};

export const setLatestAnnouncementId = id => {
  if (isServer()) return;

  localStorage.setItem('latest_announcement_read', id);
};

export const getLatestAnnouncementId = () => {
  if (isServer()) return;

  return JSON.parse(localStorage.getItem('latest_announcement_read'));
};
