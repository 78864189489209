import React from 'react';
import PropTypes from 'prop-types';

const containerStyle = {
  width: 40,
  height: 40,
  backgroundColor: '#5b00db',
  borderRadius: '50%',
  zIndex: '-1',
};

const imgStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const IconMainContainer = ({ src, alt, errorStyle }) => (
  <div style={containerStyle} className={errorStyle ? 'error-icon-container' : ''}>
    <img style={imgStyle} src={src} alt={alt} />
  </div>
);

IconMainContainer.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  errorStyle: PropTypes.bool,
};

IconMainContainer.defaultProps = {
  errorStyle: false,
};

export default IconMainContainer;
