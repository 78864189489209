import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Fragment } from 'react';

import { updateProfile } from 'features/authSlice';
import { postRequest, putRequest } from 'utils/api';
import { nextStep } from 'features/influencerSetupSlice';
import { setInventoryStep } from 'utils/localStorage';
import TagSelection from 'components/TagSelection';
import AnimateStepChange from 'components/animations/AnimateStepChange';
import useCurrentProfile from 'hooks/useCurrentProfile';

const SelectAudience = ({ showSteps, handleSuccess, stepButtonText, shouldAnimate = true }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const profile = useCurrentProfile();
  const inputRef = useRef();

  const handleAudienceStepChange = async (names, gender) => {
    setIsLoading(true);

    try {
      await postRequest({
        endpoint: `profiles/${profile.id}/audiences`,
        data: { names },
      });

      const profileResponse = await putRequest({
        endpoint: `profiles/${profile.id}`,
        data: { profile: { audience_gender: gender } },
      });

      dispatch(
        updateProfile({
          ...profileResponse.data,
        })
      );

      if (handleSuccess) {
        handleSuccess();
      } else {
        setInventoryStep(profile?.id, 4);
        dispatch(nextStep());
        router.push('/inventory/experience');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AnimateComponent = shouldAnimate ? AnimateStepChange : Fragment;

  return (
    <AnimateComponent>
      <TagSelection
        title="audience interests"
        subtitle="Add some keywords that best describe your audience interests."
        endpoint="audiences"
        placeholder="Type to add"
        handleStepChange={handleAudienceStepChange}
        defaultSelected={profile?.audiences}
        inputRef={inputRef}
        isLoading={isLoading}
        showSteps={showSteps}
        stepButtonText={stepButtonText}
        hasAudienceGender
      />
    </AnimateComponent>
  );
};

export default SelectAudience;
