import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Accordion, AccordionSummary, MenuItem, MenuList } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { getMasquerading } from 'utils/localStorage';
import ToDosIcon from 'public/icons/toDos.svg';
import ChevronRightIcon from 'public/icons/chevron-right-white.svg';
import WalletIcon from 'public/icons/new-wallet.svg';
import UserIcon from 'public/icons/user.svg';
import JobsInIcon from 'public/icons/jobs-in.svg';
import JobsOutIcon from 'public/icons/jobs-out.svg';
import JobsInviteIcon from 'public/icons/jobs-invite.svg';
import AlertIcon from 'public/icons/loggedInFooter/alerts.svg';
import SearchIcon from 'public/icons/search.svg';
import NavLink from 'components/NavLink';
import Badge from 'components/Badge';
import styles from 'styles/components/agency/Sidebar.module.scss';

const useStyles = makeStyles({
  root: {
    padding: '0 5px 0 0',
  },

  content: {
    margin: 0,
    fontFamily: 'Avenir Heavy',
    fontSize: 16,
  },

  expanded: {
    margin: '0px !important',
    minHeight: '50px !important',
    alignItems: 'center',
  },

  expandIcon: {
    margin: 0,
    padding: 0,
    transform: 'rotate(0deg)',

    '&$expanded': {
      transform: 'rotate(90deg)',
    },
  },

  paper: {
    boxShadow: 'none',
    margin: '0 !important',
    backgroundColor: 'transparent',

    '&::before': {
      display: 'none',
    },
  },
});

const AgencySidebar = () => {
  const [masquerading, setMasquerading] = useState(false);

  const classes = useStyles();
  const unseenNotificationsCount = useSelector(state => state.utils.unseenNotificationsCount);
  const agencyOffersCount = useSelector(state => state.utils.agencyOffersCount);

  const { asPath } = useRouter();
  const isToDos = asPath.includes('/dashboard');

  useEffect(() => {
    const masquerading = getMasquerading();
    setMasquerading(masquerading);
  }, []);

  return (
    <nav className={styles.sidebar}>
      <ul className={masquerading ? styles.masqueradingTop : styles.top}>
        <Accordion className={classes.paper}>
          <AccordionSummary
            className={styles.accordion}
            classes={{
              content: classes.content,
              expanded: classes.expanded,
              expandIcon: classes.expandIcon,
              root: classes.root,
            }}
            expandIcon={<ChevronRightIcon className={styles.chevronIcon} width={20} height={20} />}
          >
            <NavLink href="/dashboard" activeClassName={styles.active} exact>
              <div className={clsx(styles.heading, { [styles.active]: isToDos })}>
                <ToDosIcon /> <h3>ToDos</h3>
              </div>
            </NavLink>
          </AccordionSummary>

          <MenuList className={styles.menuList}>
            {/* <NavLink href="/dashboard" activeClassName={styles.active} exact>
              <MenuItem>Do Today</MenuItem>
            </NavLink> */}

            <NavLink href="/dashboard" activeClassName={styles.active} exact>
              <MenuItem>Do This Week</MenuItem>
            </NavLink>

            <NavLink href="/dashboard/awaiting-approval" activeClassName={styles.active}>
              <MenuItem>Awaiting Approval</MenuItem>
            </NavLink>

            <NavLink href="/dashboard/recently-completed" activeClassName={styles.active}>
              <MenuItem>Recently Completed</MenuItem>
            </NavLink>
          </MenuList>
        </Accordion>

        <NavLink href="/manage-clients" activeClassName={styles.active}>
          <li className={styles.heading}>
            <UserIcon className={styles.userIcon} />
            <h3>Manage Clients</h3>
          </li>
        </NavLink>

        <NavLink href="/agency-wallet" activeClassName={styles.active}>
          <li className={styles.heading}>
            <WalletIcon />
            <h3>Wallet</h3>
          </li>
        </NavLink>

        <Badge
          content={agencyOffersCount.offers}
          badgeStyles={{
            top: '8px',
            right: '168px',
          }}
        >
          <NavLink href="/agency-offers" activeClassName={styles.active}>
            <li className={styles.heading}>
              <JobsInIcon />
              <h3>Incoming Offers</h3>
            </li>
          </NavLink>
        </Badge>

        <NavLink href="/agency-outgoing-offers" activeClassName={styles.active}>
          <li className={styles.heading}>
            <JobsOutIcon />
            <h3>Outgoing Offers</h3>
          </li>
        </NavLink>

        <Badge
          content={agencyOffersCount.invites}
          badgeStyles={{
            top: '8px',
            right: '168px',
          }}
        >
          <NavLink href="/agency-invites" activeClassName={styles.active}>
            <li className={styles.heading}>
              <JobsInviteIcon />
              <h3>Invites</h3>
            </li>
          </NavLink>
        </Badge>

        <NavLink href="/agency-discover" activeClassName={styles.active}>
          <li className={styles.heading}>
            <SearchIcon className={styles.searchIcon} />
            <h3>Discover</h3>
          </li>
        </NavLink>

        <Badge
          content={unseenNotificationsCount}
          badgeStyles={{
            top: '8px',
            right: '168px',
          }}
        >
          <NavLink href="/notifications" activeClassName={styles.active}>
            <li className={styles.heading}>
              <AlertIcon />
              <h3>Notifications</h3>
            </li>
          </NavLink>
        </Badge>
      </ul>
    </nav>
  );
};

export default AgencySidebar;
