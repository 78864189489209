import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Hidden } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import ShareIcon from 'public/icons/profile/share.svg';
import Toast from 'utils/toast';
import IconMainContainer from 'components/IconMainContainer';

const ShareInventoryItem = ({ url, title, name, disabled }) => {
  const [nativeShare, setNativeShare] = useState(false);

  useEffect(() => {
    if (navigator.share) {
      setNativeShare(true);
    }
  }, []);

  return (
    <>
      <Hidden smDown>
        <CopyToClipboard text={url}>
          <Button
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              Toast(
                'success',
                <div>
                  Copied <strong>{name.toLowerCase()} </strong> link to clipboard!
                </div>,
                {
                  icon: (
                    <IconMainContainer src="/icons/notifications/thumb-up.svg" alt="Thumb Up" />
                  ),
                }
              );
            }}
            color="primary"
            variant="contained"
            disabled={disabled}
          >
            <ShareIcon width={20} height={20} alt="Share" />
            <span style={{ marginLeft: 10 }}>Share</span>
          </Button>
        </CopyToClipboard>
      </Hidden>

      <Hidden mdUp>
        {nativeShare ? (
          <Button
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              navigator.share({ url, title }).catch(() => {});
            }}
            color="primary"
            variant="contained"
            disabled={disabled}
          >
            <ShareIcon width={20} height={20} alt="Share" />

            <span style={{ marginLeft: 10 }}>Share</span>
          </Button>
        ) : (
          <CopyToClipboard text={url}>
            <Button
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                Toast(
                  'success',
                  <div>
                    Copied <strong>{name.toLowerCase()} </strong> link to clipboard!
                  </div>,
                  {
                    icon: (
                      <IconMainContainer src="/icons/notifications/thumb-up.svg" alt="Thumb Up" />
                    ),
                  }
                );
              }}
              color="primary"
              variant="contained"
              disabled={disabled}
            >
              <ShareIcon width={20} height={20} alt="Share" />

              <span style={{ marginLeft: 10 }}>Share</span>
            </Button>
          </CopyToClipboard>
        )}
      </Hidden>
    </>
  );
};

ShareInventoryItem.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

ShareInventoryItem.defaultProps = {
  title: '',
  disabled: false,
};

export default ShareInventoryItem;
