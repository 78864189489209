import React from 'react';
import PropTypes from 'prop-types';

import styles from 'styles/components/SwitchButton.module.scss';

const SwitchButton = ({ assets, value, setValue }) => {
  if (assets.length <= 1) return null;

  return (
    <div className={styles.switch}>
      {assets.map((asset, index) => (
        <div className={styles.clickableArea} key={asset.id} onClick={() => setValue(index)}>
          <div
            className={styles.circle}
            index={index}
            style={value === index ? { opacity: 1 } : { opacity: 0.4 }}
          />
        </div>
      ))}
    </div>
  );
};

SwitchButton.propTypes = {
  assets: PropTypes.array.isRequired,
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default SwitchButton;
