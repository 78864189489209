import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Button, Hidden } from '@material-ui/core';
import Link from 'next/link';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';

import { isServer } from 'utils/helpers';
import { setRedirectTo } from 'utils/localStorage';
import useIsAgency from 'hooks/useIsAgency';
import WhiteLogo from 'public/images/logo-white.svg';
import NavLink from 'components/NavLink';
import styles from 'styles/components/home/Header.module.scss';

const primaryDark = '#323643';
const noOpacity = { opacity: 1 };

const Header = ({ newStyles = {} }) => {
  const { asPath } = useRouter();
  const router = useRouter();
  const isAgency = useIsAgency();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const isProfilePage = router.pathname === '/u/[username]';

  const purpleHeader =
    router.pathname === '/' ||
    router.pathname === '/shoutout' ||
    router.pathname === '/shoutoutb' ||
    router.pathname === '/links';
  const home = '/';
  const isDiscover = asPath.includes('/discover');

  useEffect(() => {
    const handleScroll = () => {
      if (isServer()) return;

      // eslint-disable-next-line no-undef
      const header = document.getElementById('header');

      if (!header) return;

      // eslint-disable-next-line no-undef
      if (window.scrollY > 50 && desktop && purpleHeader) {
        header.classList.add('smaller-header');
      } else {
        header.classList.remove('smaller-header');
      }
    };

    // eslint-disable-next-line no-undef
    window.addEventListener('scroll', handleScroll);

    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('scroll', handleScroll);
  }, [desktop, purpleHeader]);

  return (
    <header
      className={clsx(
        styles.header,
        { [newStyles.header]: !isEmpty(newStyles) },
        { [styles.noBorder]: isDiscover },
        { [styles.purpleHeader]: purpleHeader }
      )}
      id="header"
    >
      <div className={styles.headerContent} style={isAgency ? { maxWidth: 'initial' } : {}}>
        <Link href={home} passHref>
          <div className={styles.logoImg}>
            <WhiteLogo />
          </div>
        </Link>

        {/* <Hidden smDown>
          <div className={styles.links}>
            <NavLink
              href={
                router.pathname === '/shoutoutb' ? '/sign-up/advertiser?m' : '/sign-up/advertiser'
              }
              activeClassName={styles.active}
            >
              Advertisers
            </NavLink>

            <NavLink href="/sign-up/influencer" activeClassName={styles.active}>
              Influencers
            </NavLink>

            <span style={{ color: '#fff', margin: '0px 5px' }}>/</span>

            <NavLink href="/sign-up/athlete" activeClassName={styles.active}>
              Athletes
            </NavLink>
          </div>
        </Hidden> */}

        <div className={styles.buttons}>
          <NavLink
            href="/login"
            activeClassName={styles.active}
            style={purpleHeader ? { color: primaryDark } : {}}
            onClick={() => {
              if (isProfilePage) setRedirectTo(router.asPath);
            }}
          >
            <Button variant="text">LOG IN</Button>
          </NavLink>

          <NavLink
            href={router.pathname.startsWith('/shoutout') ? '/sign-up/influencer' : '/sign-up'}
            activeClassName={styles.active}
            style={purpleHeader ? noOpacity : {}}
          >
            <Button
              variant="text"
              className={clsx({ [styles.greenBtn]: purpleHeader })}
              onClick={() => {
                if (isProfilePage) setRedirectTo(router.asPath);
              }}
            >
              SIGN UP
            </Button>
          </NavLink>
        </div>
      </div>
    </header>
  );
};

export default Header;
