import React from 'react';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';

export const scale = (marks, value) => {
  const previousMarkIndex = Math.floor(value / 25);
  const previousMark = marks[previousMarkIndex];
  const remainder = value % 25;

  if (remainder === 0) return previousMark.scaledValue;

  const nextMark = marks[previousMarkIndex + 1];
  const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;

  return remainder * increment + previousMark.scaledValue;
};

export const normalizeValue = (marks, value) => {
  const nextIndex = marks.findIndex(mark => mark.scaledValue >= value);
  const prevIndex = nextIndex - 1 < 0 ? 0 : nextIndex - 1;

  const prevMark = marks[prevIndex];
  const nextMark = marks[nextIndex];

  if (nextIndex === 0 && prevIndex === 0) return 0;

  const increment = Math.floor((nextMark.scaledValue - prevMark.scaledValue) / 25);

  return prevMark.value + Math.floor((value - prevMark.scaledValue) / increment);
};

const numFormatter = num => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(0) + 'K'; // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(0) + 'M'; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
};

const useStyles = makeStyles({
  mark: {
    background: 'transparent',
  },
});

const ScaledSlider = ({ count, setCount, marks, max = 250, style = {} }) => {
  const classes = useStyles();

  const handleChange = (_, newValue) => {
    setCount(
      newValue,
      newValue.length > 1
        ? [scale(marks, newValue[0]), scale(marks, newValue[1])]
        : scale(marks, newValue)
    );
  };

  const value =
    count.length > 1
      ? [normalizeValue(marks, count[0]), normalizeValue(marks, count[1])]
      : normalizeValue(marks, count);

  return (
    <Slider
      classes={classes}
      value={value}
      min={0}
      step={1}
      max={max}
      valueLabelFormat={numFormatter}
      marks={marks}
      scale={() => {
        if (value.length > 1) {
          return [scale(marks, value[0]), scale(marks, value[1])];
        }
        scale(marks, value);
      }}
      onChange={handleChange}
      style={style}
    />
  );
};

export default ScaledSlider;
