import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import isEmpty from 'lodash/isEmpty';

import { addInfluencerNetworks, nextStep, addNetworks } from 'features/influencerSetupSlice';
import { getRequest, postRequest } from 'utils/api';
import { setInventoryStep } from 'utils/localStorage';
import { useRouter } from 'next/router';
import Stepper from 'components/Stepper';
import NetworkIcon from '../NetworkIcon';
import AnimateList from 'components/animations/AnimateList';
import AnimateListItem from 'components/animations/AnimateListItem';
import styles from 'styles/components/influencerSetup/Networks.module.scss';

const Networks = ({ showSteps, networksStyle, handleSuccess, stepButtonText, isDialog }) => {
  const [hovered, setHovered] = useState(null);
  const [selectedNetworkIds, setSelectedNetworkIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const profileId = useSelector(state => state.auth.currentUser?.profile_id);
  const networks = useSelector(state => state.influencerSetup.networks);
  const dispatch = useDispatch();
  const router = useRouter();

  const selectNetwork = id => {
    setSelectedNetworkIds(prevIds =>
      prevIds.includes(id) ? prevIds.filter(i => i !== id) : [...prevIds, id]
    );

    if (selectedNetworkIds.includes(id)) {
      setHovered(null);
    }
  };

  const handleStepChange = () => {
    setIsLoading(true);

    postRequest({
      endpoint: `influencer_networks/create_many`,
      data: {
        profile_id: profileId,
        network_ids: selectedNetworkIds,
      },
    })
      .then(response => {
        dispatch(addInfluencerNetworks(response.data));

        if (handleSuccess) {
          handleSuccess();
        } else {
          setInventoryStep(profileId, 2);
          dispatch(nextStep());
          // '/inventory/categories/ in case we return the all steps
          router.push('/inventory/profile-info');
        }
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    getRequest({
      endpoint: 'networks',
    })
      .then(networks => dispatch(addNetworks(networks)))
      .catch(() => {});

    getRequest({
      endpoint: 'influencer_networks',
      query: { profile_id: profileId },
    })
      .then(influencerNetworks => {
        dispatch(addInfluencerNetworks(influencerNetworks));
        setSelectedNetworkIds(influencerNetworks.filter(n => n.active).map(n => n.network.id));
      })
      .catch(() => {});
  }, [profileId, dispatch]);

  return (
    <div style={{ paddingTop: 40 }}>
      <h3>LET'S GET YOU SOME GIGS!</h3>
      <h1>What networks are you on?</h1>

      <AnimateList
        key={networks[0]?.id}
        className={styles.networks}
        style={!isEmpty(networksStyle) ? networksStyle : {}}
      >
        {networks.map(network => (
          <AnimateListItem
            key={network.id}
            onMouseEnter={() => setHovered(network.id)}
            onMouseLeave={() => setHovered(null)}
            onClick={() => selectNetwork(network.id)}
            className={styles.network}
          >
            <>
              <Hidden smDown>
                <NetworkIcon
                  active={selectedNetworkIds.includes(network.id)}
                  network={network}
                  hovered={hovered === network.id}
                  styles={styles}
                />
              </Hidden>

              <Hidden mdUp>
                <NetworkIcon
                  active={selectedNetworkIds.includes(network.id)}
                  network={network}
                  styles={styles}
                />
              </Hidden>
            </>
          </AnimateListItem>
        ))}
      </AnimateList>

      <Stepper
        handleStepChange={handleStepChange}
        disabled={selectedNetworkIds.length === 0}
        isLoading={isLoading}
        showSteps={showSteps}
        buttonText={stepButtonText}
        newStyles={isDialog ? styles.wrapper : {}}
      />
    </div>
  );
};

export default Networks;
