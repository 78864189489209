import React from 'react';
import Image from 'next/image';
import { useSelector, useDispatch } from 'react-redux';

import { openModal } from 'features/utilsSlice';
import Badge from 'components/Badge';
import styles from 'styles/components/home/Header.module.scss';

const CartIcon = () => {
  const cartItems = useSelector(state => state.cart.cart_items) || [];
  const isLoggedIn = useSelector(state => state.auth.loggedIn);
  const dispatch = useDispatch();

  const handleModalOpen = () => dispatch(openModal({ name: 'Cart' }));

  if (!isLoggedIn) return null;

  return (
    <div
      className={styles.cartIcon}
      onClick={e => {
        if (cartItems.length > 0) {
          e.stopPropagation();
          e.preventDefault();
          handleModalOpen();
        }

        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {cartItems?.length > 0 && (
        <Badge content={cartItems.length} badgeStyles={{ top: '11px', border: '2px solid #fff' }} />
      )}

      <Image src="/icons/basket.svg" width={18} height={18} alt="Cart" unoptimized />
    </div>
  );
};

export default CartIcon;
