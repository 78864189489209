/* eslint-disable no-undef */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { isServer } from 'utils/helpers';

const useScrollToTop = change => {
  const { pathname } = useRouter();

  useEffect(() => {
    if (isServer()) return;

    document.getElementById('__next').scrollTo(0, 0);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [pathname, change]);
};

export default useScrollToTop;
