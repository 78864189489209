export const updateAhoyToken = token => {
  if (window.ahoy) {
    ahoy.configure({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

export const trackAhoyEvent = (name, properties = {}) => {
  if (window.ahoy) {
    window.ahoy.track(name, properties);
  }
};

export const trackAhoyView = () => {
  if (window.ahoy) {
    window.ahoy.trackView();
  }
};
