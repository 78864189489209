import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, InputLabel } from '@material-ui/core';
import clsx from 'clsx';

import { getRequest } from 'utils/api';
import useCurrentProfile from 'hooks/useCurrentProfile';
import CloseIcon from 'public/icons/close-white.svg';
import Switch from './Switch';
import TagsAutocomplete from 'components/TagsAutocomplete';
import Stepper from 'components/Stepper';
import styles from 'styles/components/influencerSetup/TagSelection.module.scss';

const marginBottom = { marginBottom: 10 };
const noPadding = { padding: '20px 0 0' };
const noHorizontalPadding = { padding: '5px 0 10px 0' };

const TagSelection = ({
  title,
  subtitle,
  endpoint,
  handleStepChange,
  defaultSelected = [],
  newStyles,
  noSteps,
  callback,
  header,
  maxLength,
  isLoading,
  hasAudienceGender,
  hasPadding,
  addingTag,
  disableButton,
  text,
  showSteps = true,
  stepButtonText = 'Next',
  displayPopular = true,
  allowCustomAdd = true,
  readonly = false,
  openOnFocus,
  autoFocus,
  placeholder,
}) => {
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState(defaultSelected);
  const [audienceGender, setAudienceGender] = useState('female');
  const profile = useCurrentProfile();

  const lowerCaseSelectedTags = selectedTags.map(tag => tag.toLowerCase());

  const addTag = name => {
    if (
      lowerCaseSelectedTags.length < maxLength &&
      !lowerCaseSelectedTags.includes(name.toLowerCase())
    ) {
      setSelectedTags(prevState => [...prevState, name]);
    }
  };

  const removeTag = name => setSelectedTags(prevState => prevState.filter(a => a !== name));

  useEffect(() => {
    getRequest({
      endpoint: endpoint,
      query: { per_page: 10 },
    })
      .then(suggestedTags => setSuggestedTags(suggestedTags))
      .catch(() => {});

    return () => setSuggestedTags([]);
  }, [endpoint]);

  useEffect(() => {
    callback(selectedTags);
  }, [selectedTags]); // eslint-disable-line

  useEffect(() => {
    if (!profile) return;

    if (profile.audience_gender) {
      setAudienceGender(profile.audience_gender.toLowerCase());
    }
  }, [profile]);

  const filteredSuggestedTags = suggestedTags.filter(tag => !selectedTags.includes(tag.name));

  return (
    <div className={clsx(styles.tagSelection, { [newStyles.tagSelection]: newStyles })}>
      {title && (
        <div className={clsx(styles.title, { [newStyles.title]: newStyles })}>
          {header ? (
            <>
              <h3>YOUR {`${title}`.toUpperCase()}</h3>

              <h2>{subtitle}</h2>
            </>
          ) : (
            <h4>{title}</h4>
          )}
        </div>
      )}

      <TagsAutocomplete
        endpoint={endpoint}
        handleSelect={addTag}
        selectedOptions={selectedTags}
        hasPadding={hasPadding}
        addingTag={addingTag}
        allowCustomAdd={allowCustomAdd}
        readonly={readonly}
        placeholder={placeholder}
        openOnFocus={openOnFocus}
        autoFocus={autoFocus}
      />

      <div
        className={clsx(styles.selectedContainer, {
          [newStyles.selectedContainer]: newStyles || {},
        })}
        style={!hasPadding ? noHorizontalPadding : {}}
      >
        <div className={styles.subTitle}>
          <h4>{selectedTags.length > 0 && 'Selected'}</h4>
          <h4 className={maxLength - selectedTags.length === 0 ? 'color-red' : 'color-main'}>
            {maxLength - selectedTags.length} left
          </h4>
        </div>

        {selectedTags.length === 0 && header ? (
          <span>
            Add your {title || text} ({maxLength} max)
          </span>
        ) : (
          <div className={styles.selectedTags}>
            {selectedTags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                color="secondary"
                deleteIcon={<CloseIcon />}
                className={styles.chip}
                onDelete={() => removeTag(tag)}
                onClick={() => removeTag(tag)}
              />
            ))}
          </div>
        )}
      </div>

      {filteredSuggestedTags.length > 0 && displayPopular && (
        <div
          className={clsx(styles.popularContainer, {
            [newStyles.popularContainer]: newStyles || {},
            [styles.noPadding]: hasAudienceGender || !addingTag,
          })}
          style={!hasPadding ? noPadding : null}
        >
          <h4>Popular</h4>

          <div className={styles.popularTags}>
            {filteredSuggestedTags.map(tag => (
              <Chip
                key={tag.id}
                label={tag.name}
                color="secondary"
                variant="outlined"
                className={styles.chip}
                onClick={() => addTag(tag.name)}
                clickable
              />
            ))}
          </div>
        </div>
      )}

      {hasAudienceGender && (
        <div className={styles.select}>
          <InputLabel style={marginBottom}>Your audience gender</InputLabel>

          <Switch
            firstOption={{ label: 'Mostly Female', value: 'female' }}
            secondOption={{ label: 'Mostly Male', value: 'male' }}
            setValue={value => setAudienceGender(value)}
            value={audienceGender}
          />
        </div>
      )}

      {!noSteps && (
        <Stepper
          handleStepChange={() => handleStepChange(selectedTags, audienceGender)}
          disabled={selectedTags.length === 0 || isLoading || !audienceGender || disableButton}
          isLoading={isLoading}
          showSteps={showSteps}
          buttonText={stepButtonText}
        />
      )}
    </div>
  );
};

TagSelection.propTypes = {
  endpoint: PropTypes.string.isRequired,
  handleStepChange: PropTypes.func,
  title: PropTypes.string,
  newStyles: PropTypes.object,
  noSteps: PropTypes.bool,
  callback: PropTypes.func,
  header: PropTypes.bool,
  subtitle: PropTypes.string,
  maxLength: PropTypes.number,
  isLoading: PropTypes.bool,
  hasAudienceGender: PropTypes.bool,
  hasPadding: PropTypes.bool,
  addingTag: PropTypes.bool,
  disableButton: PropTypes.bool,
  text: PropTypes.string,
};

TagSelection.defaultProps = {
  handleStepChange: () => {},
  callback: () => {},
  newStyles: {},
  noSteps: false,
  header: true,
  subtitle: '',
  title: '',
  maxLength: 5,
  isLoading: false,
  hasAudienceGender: false,
  hasPadding: true,
  addingTag: true,
  disableButton: false,
  text: '',
};

export default TagSelection;
