import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import QRCode from 'react-qr-code';
import CopyToClipboard from 'react-copy-to-clipboard';

import { closeModal } from 'features/utilsSlice';
import { getRequest, postRequest } from 'utils/api';
import { enableTwoFactorAuth } from 'features/authSlice';
import { openCrispChat } from 'utils/helpers';
import Toast from 'utils/toast';
import useModalOpen from 'hooks/useModalOpen';
import IconMainContainer from 'components/IconMainContainer';
import TransitionDialog from 'components/TransitionDialog';
import BottomButtons from 'components/BottomButtons';
import LockIcon from 'public/icons/lock.svg';
import styles from 'styles/components/TwoFactorSettingsDialog.module.scss';

const TwoFactorSettingsDialog = ({ wallet = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [code, setCode] = useState('');
  const [secret, setSecret] = useState('');
  const [url, setUrl] = useState('');
  const [backupCodes, setBackupCodes] = useState([]);
  const open = useModalOpen('Two Factor Settings');
  const enabled = useSelector(state => state.auth.currentUser.otp_required_for_login);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    if (wallet && !enabled) {
      Toast(
        'error',
        'You can always enable this later by going to sidebar menu -> Two factor authentication',
        { icon: <div /> }
      );
    }
    setStep(1);
    dispatch(closeModal('Two Factor Settings'));
  };

  useEffect(() => {
    getRequest({
      endpoint: 'two_factor_settings',
      version: null,
      errorMessage: 'Failed to fetch two factor settings. Reload the page and try again.',
    })
      .then(({ qr_code_uri, otp_secret }) => {
        setUrl(qr_code_uri);
        setSecret(otp_secret);
      })
      .catch(() => {});
  }, []);

  const handleSubmit = () => {
    setIsLoading(true);

    postRequest({
      endpoint: 'two_factor_settings',
      version: null,
      data: {
        two_fa: {
          code,
        },
      },
      successMessage: `Successfully enabled two factor authentication.`,
      errorMessage:
        'Failed to enable two factor authentication. Enter the correct code and try again.',
    })
      .then(response => {
        dispatch(enableTwoFactorAuth());

        setIsLoading(false);
        setBackupCodes(response.data.backup_codes);
        setStep(2);
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <TransitionDialog open={open} handleClose={handleClose} noCloseIcon>
      <div className={styles.container}>
        {enabled && step === 1 ? (
          <>
            <h2>Two Factor Authentication</h2>

            <Alert className={styles.enabledAlert} severity="error" color="error">
              Your account is protected with Two Factor Authentication (2FA). <br />
              <br />
              <span>
                To disable, click{' '}
                <a href="#" onClick={() => openCrispChat()}>
                  here
                </a>{' '}
                to contact our support.
              </span>
            </Alert>
          </>
        ) : (
          <>
            <h2>Let's secure your account</h2>

            {step === 1 && (
              <div className={styles.box}>
                <div className={styles.iconContainer}>
                  <LockIcon />
                </div>
                To better protect your wallet and your account, we suggest you use Two Factor
                Authentication (2FA) and authorize every time you log in or withdraw money.
              </div>
            )}

            {step === 1 ? (
              <>
                <p>
                  <strong>Step 1:</strong> Install one of the authenticator apps, like{' '}
                  <i>Google Authenticator</i>, <i>Microsoft Authenticator</i>, <i>Authy</i> or
                  similiar.
                </p>

                <p>
                  <strong>Step 2:</strong>{' '}
                  {isMobile
                    ? 'Copy the following code and add it to your authenticator app'
                    : 'Scan the QR code below with the app'}
                </p>

                {!isMobile && (
                  <>
                    <div className={styles.QRCode}>
                      <QRCode value={url} size={200} />
                    </div>

                    <p>
                      If you cannot scan the QR code, please enter the following code in your
                      authenticator app manually:
                    </p>
                  </>
                )}

                <div className={styles.copyCode}>
                  <span>
                    <pre>{secret}</pre>
                  </span>

                  <CopyToClipboard
                    text={secret}
                    onClick={() => {
                      Toast('success', 'Copied code to clipboard!', {
                        icon: (
                          <IconMainContainer
                            src="/icons/notifications/thumb-up.svg"
                            alt="Thumb Up"
                          />
                        ),
                      });
                    }}
                  >
                    <Button color="primary" size="small" variant="contained">
                      Copy
                    </Button>
                  </CopyToClipboard>
                </div>

                <p>
                  <strong>Step 3:</strong> Enter the code generated by your auth app and click the
                  enable button
                </p>

                <TextField
                  type="number"
                  variant="outlined"
                  placeholder="Code"
                  value={code}
                  onChange={e => setCode(e.target.value)}
                />
              </>
            ) : (
              <div>
                <p>
                  <strong>Step 4:</strong> Keep these backup codes safe, in case you lose access to
                  your phone. You can use them to access your account.
                </p>

                <div className={styles.codes}>
                  {backupCodes.map(code => (
                    <pre key={code}>{code}</pre>
                  ))}
                </div>
              </div>
            )}
          </>
        )}

        <BottomButtons
          text={wallet ? 'Skip' : 'Cancel'}
          textContained={step === 2 || enabled ? 'Done' : 'Enable'}
          handleClose={handleClose}
          handleClick={step === 2 || enabled ? handleClose : handleSubmit}
          disabled={!code && !enabled}
          isLoading={isLoading}
          fixed
        />
      </div>
    </TransitionDialog>
  );
};

export default TwoFactorSettingsDialog;
