import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { closeModal } from 'features/utilsSlice';
import useModalOpen from 'hooks/useModalOpen';
import Video from './Video';
import TransitionDialog from 'components/TransitionDialog';
import styles from 'styles/components/CenteredDialog.module.scss';

const useStyles = makeStyles(theme => ({
  paper: {
    height: '80%',
    width: '90%',
    marginBottom: 'auto',
    top: '10%',
    borderRadius: 10,

    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },

    [theme.breakpoints.up('lg')]: {
      width: '30%',
    },
  },

  paperFullScreenDialog: {
    width: '100%',
  },
}));

const HelpDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useModalOpen('Help');

  const title = useSelector(state => state.utils.modalData['Help']?.title);
  const videoUrl = useSelector(state => state.utils.modalData['Help']?.url);

  const handleClose = () => dispatch(closeModal('Help'));

  return (
    <TransitionDialog
      open={open}
      handleClose={handleClose}
      paperProps={{ className: classes.paper }}
      dialogStyles={classes.paperFullScreenDialog}
      direction="up"
      desktopDirection="up"
      noCloseIcon
    >
      <div className={clsx(styles.container, styles.videoContainer)}>
        <h3>{title}</h3>

        <Video src={videoUrl} autoplay />
      </div>
    </TransitionDialog>
  );
};

export default HelpDialog;
