import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  textColorInherit: {
    minHeight: 30,
    height: 30,
    minWidth: '33%',
    color: '#323643',
    fontFamily: 'Avenir Heavy',
    fontSize: 14,
    textTransform: 'none',
    opacity: 1,
  },

  selected: {
    color: '#fff !important',
    zIndex: 2,
  },

  indicator: {
    height: 30,
    borderRadius: 15,
    backgroundColor: '#5b00db',
    zIndex: 1,
  },

  flexContainer: {
    padding: '0 10px',
  },

  root: {
    height: 46,
    borderRadius: 23,
    alignItems: 'center',
    backgroundColor: '#ebedf3',
    marginTop: 15,
  },
});

const SwitchWithThreeOptions = ({ labelOne, labelTwo, labelThree, value, setValue }) => {
  const classes = useStyles();

  return (
    <Tabs
      variant="fullWidth"
      centered
      value={value}
      onChange={(_, newValue) => setValue(newValue)}
      classes={{
        indicator: classes.indicator,
        root: classes.root,
        flexContainer: classes.flexContainer,
      }}
    >
      <Tab
        classes={{
          textColorInherit: classes.textColorInherit,
          selected: classes.selected,
        }}
        label={labelOne}
      />

      <Tab
        classes={{
          textColorInherit: classes.textColorInherit,
          selected: classes.selected,
        }}
        label={labelTwo}
      />

      <Tab
        classes={{
          textColorInherit: classes.textColorInherit,
          selected: classes.selected,
        }}
        label={labelThree}
      />
    </Tabs>
  );
};

SwitchWithThreeOptions.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  labelOne: PropTypes.string.isRequired,
  labelTwo: PropTypes.string.isRequired,
  labelThree: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default SwitchWithThreeOptions;
