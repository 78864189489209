import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid, InputAdornment, Typography, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

import LocationIcon from 'public/icons/location.svg';
import swal from 'sweetalert';

const useStyles = makeStyles({
  inputRoot: {
    padding: '0px !important',
  },
  adornedStart: {
    paddingLeft: '14px !important',
    paddingRight: '30px !important',
  },
  input: {
    padding: '0px !important',
  },
});

const LocationAutocomplete = ({
  defaultValue = '',
  address = false,
  handleChange,
  style,
  error,
  setFieldTouched,
  requiredText,
}) => {
  const styles = useStyles();

  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    defaultValue,
    debounce: 500,
  });

  const handleSelect = ({ description }) => {
    setValue(description, false);
    clearSuggestions();

    if (address) {
      handleChange({ location: description });
    }

    getGeocode({ address: description })
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        handleChange({ location: description, lat, lng });
      })
      .catch(e => {
        swal('There was an error geocoding your address. Please try again.');
      });
  };

  const options = value ? [value, ...data] : ['', ...data];

  return (
    <>
      <Autocomplete
        id="location-autocomplete"
        options={options}
        getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
        filterOptions={x => x}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        blurOnSelect
        onChange={(_, newValue, reason) => {
          if (reason === 'clear') {
            setValue('');
            clearSuggestions();
            handleChange({ location: '', lat: '', lng: '' });
          }

          if (newValue && newValue?.description) {
            handleSelect(newValue);
          }
        }}
        onInputChange={(_, newInputValue, reason) => {
          if (reason === 'clear') {
            setValue('');
            clearSuggestions();
            handleChange({ location: '', lat: '', lng: '' });
          } else {
            setValue(newInputValue);
          }
        }}
        onBlur={setFieldTouched}
        renderInput={params => (
          <>
            <InputLabel style={style ? style : {}}>
              <>
                {address ? (
                  <>
                    Shipping Address <span className="fw-n">(private, not shown anywhere)</span>
                  </>
                ) : (
                  'Location'
                )}

                {requiredText && <span className="fw-n"> (required)</span>}
              </>
            </InputLabel>

            <TextField
              {...params}
              size="small"
              InputProps={{
                ...params.InputProps,
                classes: {
                  root: styles.inputRoot,
                  adornedStart: styles.adornedStart,
                  input: styles.input,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationIcon width={14} />
                  </InputAdornment>
                ),
              }}
              placeholder={address ? 'Street' : 'Location'}
              variant="outlined"
              error={error}
              fullWidth
            />
          </>
        )}
        renderOption={option => {
          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map(match => [match.offset, match.offset + match.length])
          );

          return (
            <Grid container alignItems="center">
              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />

      {error && <span className="error-msg">is required.</span>}
    </>
  );
};

LocationAutocomplete.propTypes = {
  handleChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  address: PropTypes.bool,
  error: PropTypes.bool,
  requiredText: PropTypes.bool,
};

LocationAutocomplete.defaultProps = {
  defaultValue: '',
  address: false,
  error: false,
  requiredText: false,
};

const LocationAutocompleteWrapper = props => {
  const mapsLoaded = useSelector(state => state.utils.mapsLoaded);

  if (!mapsLoaded) return null;

  return <LocationAutocomplete {...props} />;
};

export default LocationAutocompleteWrapper;
