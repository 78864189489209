import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from 'next/router';
import {
  InputLabel,
  Button,
  Avatar,
  NativeSelect,
  InputAdornment,
  IconButton,
  CircularProgress,
  TextareaAutosize,
  TextField as MuiTextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import DateFnsUtils from '@date-io/date-fns';
import * as yup from 'yup';
import clsx from 'clsx';
import pick from 'lodash/pick';

import { identifications, initDate, normalizeInitialValues, sports, USStates } from 'utils/helpers';
import { updateCurrentUser, updateProfile } from 'features/authSlice';
import { putRequest } from 'utils/api';
import { closeModal, openModal } from 'features/utilsSlice';
import { setProfile } from 'features/profilesSlice';
import useModalOpen from 'hooks/useModalOpen';
import ChevronDownDarkIcon from 'public/icons/chevron-down-dark.svg';
import User from 'public/icons/form/user.svg';
import EditIcon from 'public/icons/edit.svg';
import AddIcon from 'public/icons/add-white.svg';
import Gallery from './profile/Gallery';
import CoverImage from './profile/CoverImage';
import AvatarInput from './AvatarInput';
import TransitionDialog from 'components/TransitionDialog';
import LocationAutocomplete from 'components/LocationAutocomplete';
import SchoolsAutocomplete from 'components/SchoolsAutocomplete';
import Switch from 'components/Switch';
import styles from 'styles/components/ProfileForm.module.scss';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'image/heic', 'image/heif'];
const defaultRequired = 'is required.';
const margin = { marginTop: -24, marginBottom: 10, marginLeft: 0 };
const negativeMarginTop = { marginTop: -5 };
const background = { backgroundColor: 'transparent' };

const date = new Date();
const maxDate = date.getFullYear() - 18;

const schema = yup.object({
  username: yup
    .string()
    .matches(/^[a-zA-Z0-9_.-]*$/, 'is invalid (no spaces or special characters allowed).')
    .required(defaultRequired),
  display_name: yup.string().required(defaultRequired),
  bio: yup.string().required(defaultRequired),
  date_of_birth: yup
    .date('is not a valid date')
    .nullable()
    .required(defaultRequired),
  location: yup.string().required(defaultRequired),
  avatar: yup
    .mixed()
    .test('fileSize', 'File size is too large.', value =>
      value ? value.size <= 10 * 1024 * 1024 : true
    )
    .test('fileType', 'Unsupported File Format', value =>
      value ? SUPPORTED_FORMATS.includes(value.type) : true
    ),
  athlete: yup.bool(),
  school_location: yup.string().required(defaultRequired),
  school_type: yup.string(),
  athlete_level: yup.string().required(defaultRequired),
  school: yup
    .string()
    .when(['school_location', 'athlete_level', 'athlete'], (location, athleteLevel, athlete) => {
      if (location === 'US' && athleteLevel === 'student_athlete' && athlete) {
        return yup.object().shape({
          id: yup.number().required(defaultRequired),
          name: yup.string().required(defaultRequired),
        });
      }

      return yup.mixed();
    }),
  school_name: yup.string().when(['school_location', 'athlete'], (location, athlete) => {
    if (location === 'International' && athlete) {
      return yup.string().required(defaultRequired);
    }

    return yup.string();
  }),
  state: yup
    .string()
    .when(['school_location', 'athlete_level', 'athlete'], (location, athleteLevel, athlete) => {
      if (location === 'US' && athleteLevel === 'student_athlete' && athlete) {
        return yup.object().shape({
          code: yup.string().required(defaultRequired),
          name: yup.string().required(defaultRequired),
        });
      }

      return yup.mixed();
    }),
  team_name: yup.string().when(['athlete_level', 'athlete'], (athleteLevel, athlete) => {
    if (athleteLevel === 'professional_athlete' && athlete) {
      return yup.string().required(defaultRequired);
    }

    return yup.string();
  }),
  sports: yup.array().when('athlete', athlete => {
    if (athlete) {
      return yup
        .array()
        .required(defaultRequired)
        .min(1)
        .max(5);
    }
  }),
});

const initialValues = {
  username: '',
  date_of_birth: null,
  bio: '',
  display_name: '',
  avatar: '',
  location: '',
  athlete: false,
  athlete_level: 'student_athlete',
  school_type: 'college',
  school_location: 'US',
  state: '',
  school_name: '',
  team_name: '',
  sports: [],
};

const EditProfileDialog = ({ children, handleClose }) => {
  const open = useModalOpen('Edit Profile');

  return (
    <TransitionDialog open={open} styles={styles} handleClose={handleClose} noCloseIcon>
      {children}
    </TransitionDialog>
  );
};

const useStyles = makeStyles({
  large: {
    width: 108,
    height: 108,
  },
  root: {
    marginTop: 10,
    marginBottom: 7,
  },
  inputRoot: {
    height: 'auto',
  },
  popupIndicator: {
    marginTop: 'unset !important',
  },
  deleteIcon: {
    width: '0.8em',
    height: '0.8em',
  },
});

const EditProfile = () => {
  const [preview, setPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState({
    gender: '',
    hasGenderError: false,
  });
  const currentUser = useSelector(state => state.auth.currentUser);
  const profileId = useSelector(state => state.utils.modalData['Edit Profile']?.profile?.id);
  const profile = useSelector(state =>
    state.auth.currentUser.profiles?.find(p => p.id === profileId)
  );

  const fileRef = useRef();
  const dispatch = useDispatch();
  const classes = useStyles();
  const open = useModalOpen('Edit Profile');
  const router = useRouter();

  const handleClose = () => {
    if (preview) setPreview(null);
    dispatch(closeModal('Edit Profile'));
  };

  const handleFileClick = () => fileRef.current.click();

  const handleSubmit = async (values, actions) => {
    setIsLoading(true);
    const data = new FormData(); // eslint-disable-line no-undef

    const keys = Object.keys(
      pick(values, [
        'username',
        'display_name',
        'avatar',
        'bio',
        'athlete_level',
        'team_name',
        'location',
        'location_latitude',
        'location_longitude',
        'date_of_birth',
      ])
    );

    keys.forEach(key => {
      if (values[key]) data.set(`profile[${key}]`, values[key]);
    });

    data.set('profile[gender]', selected.gender);
    data.set('profile[school_id]', values.school?.id || null);
    data.set('profile[athlete]', values.athlete);
    data.set('profile[school_name]', values.school_name);

    values.sports.forEach(sport => {
      data.append('profile[sports][]', sport);
    });

    const endpoint = `profiles/${profile.id}`;
    const usernameChanged = profile.username !== values.username;

    putRequest({
      endpoint,
      data,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      successMessage: `Successfully updated profile.`,
      ...actions,
    })
      .then(response => {
        setIsLoading(false);

        dispatch(updateProfile(response.data));
        dispatch(setProfile(response.data));

        handleClose();

        if (currentUser.profile_id === profile.id) {
          dispatch(updateCurrentUser({ display_name: response.data.display_name }));
        }

        if (usernameChanged) {
          router.replace(`/u/${response.data.username}`);
        }
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!profile) return;

    if (profile.gender) {
      setSelected(prevState => ({ ...prevState, gender: profile.gender, hasGenderError: false }));
    }
  }, [profile]);

  return (
    <EditProfileDialog handleClose={handleClose}>
      {open && profile && (
        <div className={styles.container}>
          <div
            className={clsx(styles.gallery, {
              [styles.whiteBackground]: profile.photos?.length > 0,
            })}
            onClick={() => dispatch(openModal({ name: 'Gallery' }))}
          >
            <div className={styles.shadow} />

            {profile.photos?.length > 0 ? (
              <CoverImage edit photos={profile?.photos} />
            ) : (
              <IconButton className="icon-btn-hover-dark">
                <AddIcon width={24} height={24} alt="Plus" />
              </IconButton>
            )}
          </div>

          <Formik
            validationSchema={schema}
            initialValues={normalizeInitialValues({
              ...initialValues,
              ...profile,
              athlete_level: profile.athlete ? profile.athlete_level : 'student_athlete',
              school_type: profile.school?.type || 'college',
              school_location: profile.school_name ? 'International' : 'US',
              state: profile.school?.state
                ? USStates.find(s => s.code === profile.school?.state)
                : '',
              school: { id: profile.school?.id, name: profile.school?.name },
            })}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({
              isSubmiting,
              values,
              actions,
              setFieldValue,
              handleBlur,
              errors,
              touched,
              setFieldTouched,
            }) => (
              <Form className={styles.form}>
                <div className={styles.avatarField}>
                  <>
                    <div onClick={handleFileClick} className={styles.avatar}>
                      <Avatar
                        src={preview || profile?.avatar_url}
                        className={classes.large}
                        alt="Avatar"
                      >
                        ''
                      </Avatar>

                      {preview || profile?.avatar_url ? (
                        <IconButton className="icon-btn-hover-dark">
                          <EditIcon width={24} height={24} alt="Edit" />
                        </IconButton>
                      ) : (
                        <IconButton className="icon-btn-hover-dark">
                          <AddIcon width={24} height={24} alt="Edit" />
                        </IconButton>
                      )}
                    </div>

                    <AvatarInput
                      fileRef={fileRef}
                      preview={preview}
                      setPreview={setPreview}
                      handleDrop={avatar => setFieldValue('avatar', avatar)}
                    />
                  </>

                  <span className={styles.avatarErrors}>{get(errors, 'avatar')}</span>
                </div>

                <span style={{ fontSize: 12, opacity: 0.8 }}>
                  (Please do not upload explicit images for your public profile)
                </span>

                <h3>Public information</h3>

                <InputLabel>Username</InputLabel>

                <Field
                  component={TextField}
                  name="username"
                  type="text"
                  placeholder="Username"
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User width={14} />
                      </InputAdornment>
                    ),
                  }}
                />

                <InputLabel>Display Name (Name of your page)</InputLabel>

                <Field
                  component={TextField}
                  name="display_name"
                  type="text"
                  placeholder="Display Name (Name of your page)"
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User width={14} />
                      </InputAdornment>
                    ),
                  }}
                />

                <LocationAutocomplete
                  defaultValue={values.location}
                  handleChange={({ location, lat, lng }) => {
                    setFieldValue('location', location);
                    setFieldValue('location_latitude', lat);
                    setFieldValue('location_longitude', lng);
                  }}
                  error={Boolean(errors.location) && touched.location}
                  setFieldTouched={() => setFieldTouched('location', true)}
                />

                <InputLabel>
                  Bio <span className="fw-n">(required)</span>
                </InputLabel>

                <TextareaAutosize
                  value={values.bio}
                  onChange={e => setFieldValue('bio', e.target.value)}
                  onBlur={() => setFieldTouched('bio', true)}
                  minRows={4}
                  maxRows={4}
                  maxLength={150}
                  placeholder="Write something about yourself..."
                  className={clsx({ ['error-border']: errors.bio && touched.bio })}
                  style={background}
                />

                <ErrorMessage
                  component="span"
                  name="bio"
                  className="error-msg"
                  style={negativeMarginTop}
                />

                <h3>Private information</h3>

                <InputLabel>What do you identify as?</InputLabel>

                <NativeSelect
                  fullWidth
                  name="gender"
                  error={selected.hasGenderError}
                  className={styles.select}
                  value={selected.gender}
                  onChange={e =>
                    setSelected(prevState => ({
                      ...prevState,
                      gender: e.target.value,
                      hasGenderError: !e.target.value,
                    }))
                  }
                  IconComponent={ChevronDownDarkIcon}
                >
                  <option value="">Select</option>

                  {identifications.map((gender, index) => (
                    <option key={index} value={gender}>
                      {gender}
                    </option>
                  ))}
                </NativeSelect>

                {selected.hasGenderError && (
                  <span style={margin} className="error-msg">
                    is required.
                  </span>
                )}

                <InputLabel>
                  Date of birth <span className="fw-n">(private, not shown anywhere)</span>
                </InputLabel>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    clearable
                    variant="dialog"
                    disableFuture
                    openTo="year"
                    format="MM/dd/yyyy"
                    placeholder="MM/DD/YYYY"
                    id="date-picker-inline"
                    views={['year', 'month', 'date']}
                    inputVariant="outlined"
                    value={initDate(values.date_of_birth)}
                    onChange={date => setFieldValue('date_of_birth', date)}
                    onKeyDown={e => e.preventDefault()}
                    onBlur={() => setFieldTouched('date_of_birth', true)}
                    error={errors.date_of_birth && touched.date_of_birth}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    minDate={'1950-01-01'}
                    maxDate={`${maxDate}-12-31`}
                  />
                </MuiPickersUtilsProvider>

                <ErrorMessage name="date_of_birth" component="span" className="error-msg" />

                <h3>Are you an athlete?</h3>

                <Switch
                  firstOption={{ label: 'Yes', value: true }}
                  secondOption={{
                    label: 'No',
                    value: false,
                  }}
                  setValue={value => setFieldValue('athlete', value)}
                  value={values.athlete}
                />

                {values.athlete && (
                  <>
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                      <InputLabel>Athlete Type</InputLabel>

                      <Switch
                        firstOption={{ label: 'Student Athlete', value: 'student_athlete' }}
                        secondOption={{
                          label: 'Professional Athlete',
                          value: 'professional_athlete',
                        }}
                        setValue={value => {
                          setFieldValue('athlete_level', value);

                          if (value === 'professional_athlete') {
                            if (values.sports.length > 1) {
                              setFieldValue('sports', values.sports.slice(0, 1));
                            }
                          }
                        }}
                        value={values.athlete_level}
                      />
                    </div>

                    {values.athlete_level === 'student_athlete' && (
                      <>
                        <InputLabel>Select location of school</InputLabel>

                        <NativeSelect
                          fullWidth
                          name="school_location"
                          className={styles.select}
                          value={values.school_location}
                          onChange={e => {
                            if (e.target.value === 'International') {
                              setFieldValue('state', '');
                              setFieldValue('school', {});
                            } else {
                              setFieldValue('school_name', '');
                            }

                            setFieldValue('school_location', e.target.value);
                          }}
                          IconComponent={ChevronDownDarkIcon}
                        >
                          <option value="US">United States</option>
                          <option value="International">International</option>
                        </NativeSelect>

                        <div>
                          <InputLabel>School Type</InputLabel>

                          <NativeSelect
                            fullWidth
                            name="school_type"
                            className={styles.select}
                            value={values.school_type}
                            onChange={e => setFieldValue('school_type', e.target.value)}
                            IconComponent={ChevronDownDarkIcon}
                          >
                            <option value="college">College / University</option>
                            <option value="school">Middle school / High school</option>
                          </NativeSelect>
                        </div>

                        {values.school_location === 'US' && (
                          <>
                            <InputLabel>School State</InputLabel>

                            <Autocomplete
                              id="state"
                              classes={{
                                popupIndicator: classes.popupIndicator,
                              }}
                              ChipProps={{
                                classes: {
                                  deleteIcon: classes.deleteIcon,
                                },
                              }}
                              value={values.state}
                              onChange={(_, value, __) => {
                                let newValue = value || {};

                                setFieldValue('state', newValue).then(() => {
                                  setFieldTouched('state');
                                });

                                setFieldValue('school', {}).then(() => {
                                  setFieldTouched('school');
                                });
                              }}
                              options={USStates}
                              getOptionLabel={option => option?.name || ''}
                              blurOnSelect
                              getOptionSelected={(option, value) => option.code === value?.code}
                              renderInput={params => (
                                <MuiTextField
                                  {...params}
                                  variant="standard"
                                  classes={{ root: classes.textFieldRoot }}
                                  InputProps={{
                                    ...params.InputProps,
                                    name: 'state',
                                    placeholder: 'Select your school state',
                                    classes: {
                                      adornedStart: classes.adornedStart,
                                      input: classes.input,
                                    },
                                    error: Boolean(touched?.state && errors?.state),
                                    onBlur: handleBlur,
                                  }}
                                />
                              )}
                            />

                            {touched?.state && errors?.state && (
                              <span className="error-msg ml-0" style={{ marginTop: -27 }}>
                                is required.
                              </span>
                            )}

                            {values.state && (
                              <>
                                <InputLabel>School</InputLabel>

                                <SchoolsAutocomplete
                                  name="school"
                                  handleBlur={handleBlur}
                                  state={values.state?.code}
                                  schoolType={values.school_type}
                                  handleSelect={option => {
                                    setFieldValue('school', {
                                      id: option.id,
                                      name: option.name,
                                    }).then(() => {
                                      setFieldTouched('school', true);
                                    });

                                    setFieldValue('school_name', '').then(() => {
                                      setFieldTouched('school_name', true);
                                    });
                                  }}
                                  school={values.school}
                                  error={Boolean(touched?.school && errors?.school)}
                                />

                                {touched?.school && errors?.school && (
                                  <span className="error-msg ml-0" style={{ marginTop: -27 }}>
                                    is required.
                                  </span>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {values.school_location !== 'US' && (
                          <>
                            <InputLabel>School Name</InputLabel>

                            <Field
                              component={TextField}
                              name="school_name"
                              type="text"
                              placeholder="Type name of school"
                              variant="standard"
                              color="primary"
                            />
                          </>
                        )}
                      </>
                    )}

                    {values.athlete_level === 'professional_athlete' && (
                      <>
                        <InputLabel>Team</InputLabel>

                        <Field
                          component={TextField}
                          name="team_name"
                          type="text"
                          placeholder="Team Name"
                          variant="standard"
                          color="primary"
                        />
                      </>
                    )}

                    <InputLabel>
                      {values.athlete_level === 'student_athlete' ? 'Sports ' : 'Primary Sport'}
                    </InputLabel>

                    <Autocomplete
                      multiple={values.athlete_level === 'student_athlete'}
                      id="sports"
                      classes={{
                        popupIndicator: classes.popupIndicator,
                      }}
                      ChipProps={{
                        classes: {
                          deleteIcon: classes.deleteIcon,
                        },
                      }}
                      value={
                        values.athlete_level === 'student_athlete'
                          ? values.sports
                          : values.sports[0] || null
                      }
                      onChange={(_, value, __) => {
                        if (values.athlete_level === 'student_athlete') {
                          if (value.length > 5) return;

                          setFieldValue('sports', value);
                        } else {
                          const newValue = value ? [value] : [];
                          setFieldValue('sports', newValue);
                        }
                      }}
                      options={sports}
                      getOptionLabel={option => option || ''}
                      renderInput={params => (
                        <MuiTextField
                          {...params}
                          variant="standard"
                          InputProps={{
                            ...params.InputProps,
                            name: 'sports',
                            placeholder:
                              values.athlete_level === 'student_athlete'
                                ? values.sports.length === 0
                                  ? 'Sports (type the name, select up to 5)'
                                  : ''
                                : 'Primary Sport',
                            classes: {
                              root: classes.inputRoot,
                              adornedStart: classes.adornedStart,
                              input: classes.input,
                            },
                            error: Boolean(touched?.sports && errors?.sports),
                            onBlur: handleBlur,
                          }}
                        />
                      )}
                    />

                    {touched?.sports && errors?.sports && (
                      <span className="error-msg ml-0" style={{ marginTop: -27 }}>
                        is required.
                      </span>
                    )}
                  </>
                )}

                <div className={styles.buttons}>
                  <Button onClick={handleClose} color="primary" fullWidth>
                    Cancel
                  </Button>

                  <Button
                    onClick={() => handleSubmit(values, actions)}
                    variant="contained"
                    color="primary"
                    disabled={
                      isSubmiting ||
                      isLoading ||
                      !selected.gender ||
                      !values.bio ||
                      !isEmpty(errors)
                    }
                    fullWidth
                  >
                    {isLoading ? <CircularProgress color="primary" /> : 'Done'}
                  </Button>
                </div>

                <Gallery
                  profile={profile}
                  setProfile={profile => dispatch(setProfile(profile))}
                  edit
                />
              </Form>
            )}
          </Formik>
        </div>
      )}
    </EditProfileDialog>
  );
};

EditProfile.propTypes = {
  setProfile: PropTypes.func,
};

EditProfile.defaultProps = {
  setProfile: () => {},
};

export default EditProfile;
