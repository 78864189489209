import { useMediaQuery } from '@material-ui/core';

import useIsAgency from 'hooks/useIsAgency';
import useIsInfluencer from 'hooks/useIsInfluencer';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import InfluencerDesktopSidebar from './sidebar/InfluencerDesktopSidebar';
import AdvertiserDesktopSidebar from './sidebar/AdvertiserDesktopSidebar';
import AgencySidebar from 'components/agency/Sidebar';

const PageContent = ({ children }) => {
  const isAgency = useIsAgency();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const isInfluencer = useIsInfluencer();
  const isLoggedIn = useIsLoggedIn();
  const showAgencySidebar = isAgency && isDesktop;
  const showSidebar = isDesktop && isLoggedIn;

  return (
    <div style={showSidebar ? { display: 'flex', flex: 1 } : { flex: 1 }}>
      {showAgencySidebar && <AgencySidebar />}
      {isInfluencer && !isAgency && showSidebar && <InfluencerDesktopSidebar />}
      {!isInfluencer && showSidebar && <AdvertiserDesktopSidebar />}

      <div style={showAgencySidebar || showSidebar ? { width: 'calc(100% - 220px)' } : {}}>
        {children}
      </div>
    </div>
  );
};

export default PageContent;
