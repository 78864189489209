import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';

const FullScreenLoader = ({ progress }) => {
  if (progress === 100) return null;

  return (
    <div className="fullscreen-loader-container">
      <LinearProgress variant="determinate" color="secondary" value={progress} />
      <span>UPLOADING</span>

      <div id="full-screen-loader-portal"></div>
    </div>
  );
};

FullScreenLoader.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default FullScreenLoader;
