import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { InputLabel } from '@material-ui/core';

import { postRequest, putRequest } from 'utils/api';
import { updateProfile } from 'features/authSlice';
import { nextStep } from 'features/influencerSetupSlice';
import { setProfile } from 'features/profilesSlice';
import { setInventoryStep } from 'utils/localStorage';
import TagSelection from 'components/TagSelection';
import Switch from 'components/Switch';
import useCurrentProfile from 'hooks/useCurrentProfile';

const left = { textAlign: 'left', marginTop: 15, marginBottom: 10 };
const paddingBottom160 = { paddingBottom: 160 };
const padding = { padding: '0 25px' };

const SelectCategory = ({
  noSteps,
  hasTitle,
  hasPadding,
  callback,
  handleSuccess,
  stepButtonText = 'Next',
  showSteps = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [adult, setAdult] = useState();
  const dispatch = useDispatch();
  const router = useRouter();
  const inputRef = useRef();
  const profile = useCurrentProfile();

  const handleStepChange = names => {
    setIsLoading(true);

    Promise.all([
      postRequest({
        endpoint: `profiles/${profile.id}/categories`,
        data: { names },
      }),
      putRequest({
        endpoint: `profiles/${profile.id}`,
        data: { profile: { adult } },
      }),
    ])
      .then(([categoriesResponse, profileResponse]) => {
        dispatch(
          updateProfile({
            ...profileResponse.data,
            id: profile.id,
            categories: categoriesResponse.data,
            adult,
          })
        );

        if (handleSuccess) {
          handleSuccess();
        } else {
          setInventoryStep(profile?.id, 3);
          dispatch(nextStep());
          router.push('/inventory/audience');
        }

        dispatch(
          setProfile({
            ...profileResponse.data,
            categories: categoriesResponse.data,
            adult,
          })
        );
      })
      .catch(e => {
        console.error(e);
        setIsLoading(false);
      });
  };

  useEffect(() => setAdult(profile?.adult), [profile?.adult]);

  return (
    <div style={hasPadding ? paddingBottom160 : null}>
      <TagSelection
        title={hasTitle ? 'categories' : ''}
        text="categories"
        subtitle="Choose up to three categories that best represent you"
        endpoint="categories"
        inputRef={inputRef}
        placeholder="Tap to select"
        handleStepChange={handleStepChange}
        callback={callback}
        maxLength={3}
        defaultSelected={profile?.categories}
        addingTag={false}
        noSteps={noSteps}
        hasPadding={hasPadding}
        isLoading={isLoading}
        showSteps={showSteps}
        stepButtonText={stepButtonText}
        displayPopular={false}
        allowCustomAdd={false}
        openOnFocus
        autoFocus={!profile?.completed}
        readonly
      />

      <div style={hasPadding ? padding : null}>
        <InputLabel style={left}>Are you willing to sell to OnlyFans models?</InputLabel>

        <Switch
          firstOption={{ label: 'Yes', value: true }}
          secondOption={{ label: 'No', value: false }}
          setValue={value => setAdult(value)}
          value={adult}
        />
      </div>
    </div>
  );
};

SelectCategory.propTypes = {
  hasSteps: PropTypes.bool,
  hasTitle: PropTypes.bool,
  hasPadding: PropTypes.bool,
  callback: PropTypes.func,
  setIsModelRole: PropTypes.func,
};

SelectCategory.defaultProps = {
  noSteps: false,
  hasTitle: true,
  hasPadding: true,
  callback: () => {},
  setIsModelRole: () => {},
};

export default SelectCategory;
