import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Avatar } from '@material-ui/core';
import Link from 'next/link';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';

import { isServer, normalizePrimaryInterest } from 'utils/helpers';
import useIsAgency from 'hooks/useIsAgency';
import useCurrentProfile from 'hooks/useCurrentProfile';
import useIsInfluencer from 'hooks/useIsInfluencer';
import WhiteLogo from 'public/images/logo-white.svg';
import NoAvatarImage from 'public/images/no-avatar.svg';
import WhiteAlertIcon from 'public/icons/alert-white.svg';
import Badge from 'components/Badge';
import Sidebar from 'components/sidebar/Sidebar';
import NavLink from 'components/NavLink';
import CartIcon from 'components/cart/CartIcon';
import styles from 'styles/components/home/Header.module.scss';

const avatarSize = { width: 30, height: 30, marginRight: 25 };
const avatarFullSize = { width: '100%', height: '100%' };
const cartStyle = { minWidth: 20, width: 20 };

const DesktopLoggedInHeader = ({ newStyles = {} }) => {
  const currentInterest = normalizePrimaryInterest(
    useSelector(state => state.auth.currentUser?.primary_interest)
  );

  const [primaryInterest, setPrimaryInterest] = useState(currentInterest);
  const { asPath } = useRouter();
  const router = useRouter();
  const currentProfile = useCurrentProfile();
  const currentUser = useSelector(state => state.auth.currentUser);
  const isAgency = useIsAgency();
  const isInfluencer = useIsInfluencer();
  const unseenNotificationsCount = useSelector(state => state.utils.unseenNotificationsCount);
  const isBuyer = primaryInterest === 'As Buyer';
  const isHome =
    router.pathname === '/' || router.pathname === '/shoutout' || router.pathname === '/shoutoutb';
  const home = '/dashboard';
  const isDiscover = asPath.includes('/discover');

  useEffect(() => {
    const handleScroll = () => {
      if (isServer()) return;

      // eslint-disable-next-line no-undef
      const header = document.getElementById('header');

      if (!header) return;

      // eslint-disable-next-line no-undef
      if (window.scrollY > 50 && isHome) {
        header.classList.add('smaller-header');
      } else {
        header.classList.remove('smaller-header');
      }
    };

    // eslint-disable-next-line no-undef
    window.addEventListener('scroll', handleScroll);

    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isHome]);

  useEffect(() => {
    if (currentInterest !== primaryInterest) setPrimaryInterest(currentInterest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInterest]);

  return (
    <header
      className={clsx(
        styles.header,
        { [newStyles.header]: !isEmpty(newStyles) || {} },
        { [styles.noBorder]: isDiscover || {} }
      )}
      id="header"
    >
      <div className={styles.headerContent} style={isAgency ? { maxWidth: 'initial' } : {}}>
        <Link href={home} passHref>
          <div className={clsx(styles.logoImg, styles.loggedInPosition)}>
            <WhiteLogo />
          </div>
        </Link>

        <div className={styles.profileLinks}>
          {!isAgency && (
            <>
              <Badge
                content={unseenNotificationsCount}
                badgeStyles={{
                  top: '-5px',
                  right: '13px',
                }}
              >
                <NavLink href="/alerts" activeClassName={styles.active}>
                  <WhiteAlertIcon />
                </NavLink>
              </Badge>
            </>
          )}

          {!isAgency && (
            <>
              {((isInfluencer && isBuyer) || !isInfluencer) && <CartIcon style={cartStyle} />}

              {isInfluencer && (
                <Link href={`/u/${currentProfile?.username}`} passHref>
                  <Avatar size="small" style={avatarSize} src={currentProfile?.avatar_url}>
                    <NoAvatarImage style={avatarFullSize} />
                  </Avatar>
                </Link>
              )}
            </>
          )}

          {!isInfluencer && (
            <Link href={`/c/${currentUser.username}`} passHref>
              <Avatar size="small" style={avatarSize} src={currentUser?.avatar_url}>
                <NoAvatarImage style={avatarFullSize} />
              </Avatar>
            </Link>
          )}

          <Sidebar primaryInterest={primaryInterest} setPrimaryInterest={setPrimaryInterest} />
        </div>
      </div>
    </header>
  );
};

export default DesktopLoggedInHeader;
