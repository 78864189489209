import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { format } from 'date-fns';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import clsx from 'clsx';
import upperFirst from 'lodash/upperFirst';
import Image from 'next/image';

import { openModal } from 'features/utilsSlice';
import { formatLargeNumber, screenshotsMapping } from 'utils/helpers';
import getStatus from 'utils/screenshotVerification';
import CheckIcon from 'public/icons/check.svg';
import PadlockIcon from 'public/icons/padlock-red.svg';
import compressImage from 'utils/compressImage';
import notificationStyles from 'styles/components/Notification.module.scss';
import ExternalLink from 'components/ExternalLink';
import styles from 'styles/components/profileVerification/DropzoneField.module.scss';

const nameKindMapping = {
  profile_screenshot: ['profile'],
  location_screenshot: ['location'],
  gender_screenshot: ['age_range'],
  age_screenshot: ['age_range'],
  analytics_screenshot: ['age_range', 'location'],
};

const nameButtonText = {
  profile_screenshot: '',
  location_screenshot: 'location',
  gender_screenshot: 'age',
  age_screenshot: 'age',
  analytics_screenshot: 'analytics',
};

const buttonStyle = {
  marginLeft: 0,
  marginTop: 10,
  height: 30,
  padding: '6px 9px',
  width: 90,
};

const Error = ({ influencerNetwork }) => {
  if (!influencerNetwork) return null;

  return (
    <>
      {Object.keys(screenshotsMapping).includes(influencerNetwork.network.name) && (
        <ExternalLink
          preview="url"
          mobileUrl={screenshotsMapping[influencerNetwork.network.name].url}
          desktopUrl={screenshotsMapping[influencerNetwork.network.name].url}
          page={`${influencerNetwork.network.name} Screenshot Guide`}
          target="_blank"
          rel="noreferrer"
          stopPropagation
        >
          <div
            style={{ marginBottom: 0, cursor: 'pointer' }}
            className={clsx(notificationStyles.notification, { [notificationStyles.red]: true })}
          >
            <span className={clsx(notificationStyles.icon, { [notificationStyles.redIcon]: true })}>
              <Image
                src={'/icons/notifications/reject.svg'}
                alt="image"
                width={30}
                height={30}
                unoptimized
              />
            </span>

            <div className={notificationStyles.text}>
              <h3 style={{ color: '#323643' }}>Upload failed</h3>

              <p style={{ textAlign: 'left' }}>
                Click here to see where to find the correct screenshot
              </p>
            </div>
          </div>
        </ExternalLink>
      )}
    </>
  );
};

const ScreenshotDropzoneField = ({
  name,
  influencerNetwork,
  title,
  handleDrop,
  disabled,
  compressOptions = {
    quality: 0.8,
  },
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const dispatch = useDispatch();

  const status = influencerNetwork[`${name}_status`];
  const verified = status === 'verified' || status === 'pending_verification';
  const stats = influencerNetwork.stats.filter(stat => nameKindMapping[name].includes(stat.kind));
  const error = influencerNetwork[name]?.error;

  const getScreenshotUrl = name => {
    if (name === 'profile_screenshot') {
      return influencerNetwork?.profile_screenshot_url;
    } else if (name === 'username_screenshot') {
      return influencerNetwork?.username_screenshot_url;
    } else if (name === 'location_screenshot') {
      return influencerNetwork?.location_screenshot_url;
    } else if (name === 'gender_screenshot') {
      return influencerNetwork?.gender_screenshot_url;
    } else if (name === 'age_screenshot') {
      return influencerNetwork?.age_screenshot_url;
    }

    return influencerNetwork?.analytics_screenshot_url;
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpg, image/jpeg, image/png',
    onDrop: acceptedFiles => {
      if (disabled) return;
      setIsLoading(true);

      const file = acceptedFiles[0];

      compressImage(file, compressOptions)
        .then(compressedFile => {
          const preview = URL.createObjectURL(compressedFile);
          setPreview(preview);
          handleDrop(name, { file: compressedFile }).finally(() => setIsLoading(false));
        })
        .catch(() => {
          const preview = URL.createObjectURL(file);
          setPreview(preview);
          handleDrop(name, { file }).finally(() => setIsLoading(false));
        });
    },
  });

  const handleShowData = e => {
    e.preventDefault();
    e.stopPropagation();

    if (stats.length === 0) return;

    const title =
      name === 'analytics_screenshot'
        ? 'Analytics Data'
        : `${influencerNetwork.network.name}: ${nameKindMapping[name][0]
            .split('_')
            .map(s => upperFirst(s))
            .join(' ')} Data`;

    dispatch(openModal({ name: 'Profile Verification Data', data: { stats, title } }));
  };

  useEffect(
    () => () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div
      {...getRootProps({
        onClick: event => disabled && event.stopPropagation(),
      })}
      className={clsx(styles.dropzone, {
        [styles.pending]: status === 'pending_verification',
        [styles.verified]: status === 'verified',
        [styles.rejected]: status === 'rejected',
      })}
    >
      <input {...getInputProps()} />

      <div className={styles.content}>
        <div className={styles.contentInfo}>
          {(preview || getScreenshotUrl(name)) && (
            <div style={{ position: 'relative' }}>
              <img
                src={preview || getScreenshotUrl(name)}
                width={90}
                height={106}
                alt="Screenshot"
              />

              {status === 'verified' && (
                <div className={styles.checkIcon}>
                  <CheckIcon />
                </div>
              )}
            </div>
          )}

          <div className={styles.info}>
            <span className={styles.title}>
              {title}

              {getStatus(influencerNetwork, name)}
            </span>

            {name === 'username_screenshot' &&
              influencerNetwork.username_screenshot_status === 'verified' && (
                <>
                  <span>Username: {influencerNetwork.username}</span>
                  <span>
                    Your Page link:{' '}
                    <a
                      onClick={e => e.stopPropagation()}
                      href={influencerNetwork.social_profile_url}
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ color: '#5b00db' }}
                    >
                      {influencerNetwork.social_profile_url}
                    </a>
                  </span>
                </>
              )}

            {name === 'profile_screenshot' && (
              <>
                {influencerNetwork.username !== `unverified_${influencerNetwork.profile_id}` && (
                  <span>Username: {influencerNetwork.username}</span>
                )}

                {influencerNetwork.followers > 0 && (
                  <span>Followers: {formatLargeNumber(influencerNetwork.followers)}</span>
                )}

                {influencerNetwork.likes > 0 && (
                  <span>Likes: {formatLargeNumber(influencerNetwork.likes)}</span>
                )}

                {influencerNetwork.posts > 0 && (
                  <span>Posts: {formatLargeNumber(influencerNetwork.posts)}</span>
                )}

                {influencerNetwork.subscribers > 0 && (
                  <span>Subscribers: {formatLargeNumber(influencerNetwork.subscribers)}</span>
                )}

                {influencerNetwork.fans > 0 && (
                  <span>Fans: {formatLargeNumber(influencerNetwork.fans)}</span>
                )}

                {influencerNetwork.top_creator_percentage > 0 && (
                  <span>Top creator: {influencerNetwork.top_creator_percentage}%</span>
                )}
              </>
            )}

            {(name === 'gender_screenshot' || name === 'analytics_screenshot') &&
              influencerNetwork.popular_gender &&
              influencerNetwork.gender_percentage > 0 && (
                <span>
                  Gender: {influencerNetwork.gender_percentage}% {influencerNetwork.popular_gender}
                </span>
              )}

            {influencerNetwork[`${name}_last_updated`] && (
              <span>
                Updated at:{' '}
                {format(new Date(influencerNetwork[`${name}_last_updated`]), 'MM/dd/yy')}
              </span>
            )}
          </div>
        </div>

        {verified && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Button variant="contained" color="primary" style={buttonStyle}>
              Update
            </Button>

            {stats.length > 0 &&
              ((name === 'gender_screenshot' && influencerNetwork.network.name !== 'TikTok') ||
                name !== 'gender_screenshot') && (
                <Button
                  variant="outlined"
                  color="primary"
                  style={buttonStyle}
                  onClick={handleShowData}
                >
                  View {nameButtonText[name]} data
                </Button>
              )}
          </div>
        )}

        {!verified && !disabled && (
          <span style={{ marginTop: 5 }}>
            Drop file or{' '}
            <span style={{ color: '#5b00db', fontFamily: 'Avenir Heavy', cursor: 'pointer' }}>
              browse
            </span>
          </span>
        )}

        {disabled && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PadlockIcon />{' '}
            <span style={{ marginLeft: 7 }}>Locked until previous screenshot uploaded</span>
          </div>
        )}

        {!isLoading && error && status !== 'pending_verification' && (
          <div className={styles.error}>
            <Error influencerNetwork={influencerNetwork} />
          </div>
        )}
      </div>

      {isLoading && <LinearProgress className={styles.progress} />}
    </div>
  );
};

ScreenshotDropzoneField.propTypes = {
  name: PropTypes.string.isRequired,
  handleDrop: PropTypes.func.isRequired,
  title: PropTypes.string,
};

ScreenshotDropzoneField.defaultProps = {
  title: 'Profile',
};

export default ScreenshotDropzoneField;
