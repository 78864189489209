import axios from 'axios';
import { getAccessToken } from './localStorage';

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL || 'http://localhost:5000',
  headers: {
    'Content-Type': 'application/json',
  },
});

const ignoredPaths = ['/sign_in', '/sign_up', '/password', '/confirmations'];

instance.interceptors.request.use(config => {
  const token = getAccessToken();

  if (!ignoredPaths.some(path => config.url.endsWith(path)) || config.sendToken) {
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }

  return config;
});

export default instance;
