import React from 'react';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import groupBy from 'lodash/groupBy';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { closeModal } from 'features/utilsSlice';
import { formatLargeNumber } from 'utils/helpers';
import useModalOpen from 'hooks/useModalOpen';
import styles from 'styles/components/profileVerification/DataDialog.module.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  paper: {
    height: '80%',
    marginTop: 'auto',

    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
}));

const DataView = ({ stats, type }) => (
  <TableContainer component={Paper} elevation={0}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ fontWeight: 'bold' }}>
            {type === 'age_range' ? 'Age Range' : 'Location'}
          </TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Percentage</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Count</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {stats
          .sort((a, b) => b.size - a.size)
          .map(stat => (
            <TableRow key={stat.id}>
              <TableCell component="th" scope="row">
                {stat.name}
              </TableCell>

              <TableCell component="th" scope="row">
                {stat.percentage}%
              </TableCell>

              <TableCell component="th" scope="row">
                {formatLargeNumber(stat.size)}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const DataDialog = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const open = useModalOpen('Profile Verification Data');
  const { title, stats } = useSelector(
    state => state.utils.modalData['Profile Verification Data'] || {}
  );

  const groupedStats = groupBy(stats, stat => stat.kind);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => dispatch(closeModal('Profile Verification Data'))}
      TransitionComponent={Transition}
      className={clsx(styles.dialog, classes.paper)}
    >
      <AppBar className={styles.appBar}>
        <Toolbar style={{ paddingLeft: 15 }}>
          <h3 className={styles.title}>{title}</h3>
        </Toolbar>
      </AppBar>

      {Object.entries(groupedStats).map(([type, stats]) => (
        <DataView key={type} type={type} stats={stats} />
      ))}

      <div className={styles.buttonContainer}>
        <div>
          <Button
            fullWidth
            onClick={() => dispatch(closeModal('Profile Verification Data'))}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DataDialog;
