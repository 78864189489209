import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import clsx from 'clsx';

const maxLength = 15;

const NetworkIcon = ({ active, network, styles, hovered, hasName, width, height }) => (
  <div className={clsx({ [styles.hoverImg]: active || hovered }, 'network-field')}>
    <Image
      src={active || hovered ? network.active_icon_url : network.icon_url}
      width={width}
      height={height}
      alt={network.name}
      unoptimized
    />
    {hasName && (
      <span className={clsx({ 'min-width': network.name.length > maxLength })}>{network.name}</span>
    )}
  </div>
);

NetworkIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  network: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
  hovered: PropTypes.bool,
  hasName: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

NetworkIcon.defaultProps = {
  hovered: false,
  hasName: true,
  width: 50,
  height: 50,
};

export default NetworkIcon;
