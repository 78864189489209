import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DocumentHead from 'components/DocumentHead';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, IconButton } from '@material-ui/core';

import { getRequest, postRequest } from 'utils/api';
import { nextStep } from 'features/influencerSetupSlice';
import { updateInventoryInfluencerNetwork } from 'features/influencerSetupSlice';
import { updateInfluencerNetwork } from 'features/profilesSlice';
import { screenshotsMapping } from 'utils/helpers';
import CloseIcon from 'public/icons/close-main.svg';
import ChevronRight from 'public/icons/chevron-circle-green.svg';
import useScrollToTop from 'hooks/useScrollToTop';
import Toast from 'utils/toast';
import ProfileScreenshots from 'components/profileVerification/ProfileScreenshots';
import IconMainContainer from 'components/IconMainContainer';
import DataDialog from 'components/profileVerification/DataDialog';
import ConfirmationDialog from 'components/ConfirmationDialog';
import ExternalLink from 'components/ExternalLink';
import Stepper from 'components/Stepper';
import useCurrentProfile from 'hooks/useCurrentProfile';
import styles from 'styles/components/profileVerification/ProfileVerification.module.scss';

const buttonStyle = { height: 40, marginBottom: 25 };

const ProfileVerification = ({ showSteps, isDialog, handleSuccess, handleClose }) => {
  const [influencerNetwork, setInfluencerNetwork] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const influencerNetworkId = useSelector(
    state => state.utils.modalData['Inventory Edit']?.influencerNetworkId
  );
  const dispatch = useDispatch();
  const blobsRef = useRef([]);
  const profile = useCurrentProfile();

  const disabled =
    influencerNetwork?.profile_screenshot_status === 'unverified' ||
    (influencerNetwork?.network.name === 'Facebook' &&
      influencerNetwork?.profile_screenshot_status === 'unverified' &&
      influencerNetwork?.username_screenshot_status === 'unverified');

  const handleApprove = () => dispatch(nextStep());

  useScrollToTop();

  const handleStepChange = () => {
    if (handleSuccess) {
      dispatch(updateInventoryInfluencerNetwork(influencerNetwork));
      dispatch(
        updateInfluencerNetwork({
          ...influencerNetwork,
          profileUsername: profile?.username_lower,
        })
      );
      handleSuccess();
    } else {
      dispatch(nextStep());
    }
  };

  const handleDrop = (name, value) => {
    blobsRef.current.push(value.preview);

    const data = new FormData(); // eslint-disable-line no-undef

    data.set('file', value.file);
    data.set('type', name);

    return postRequest({
      endpoint: `influencer_networks/${influencerNetworkId}/verify?with_stats=true`,
      data,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
      showErrors: false,
    })
      .then(response => {
        const { success, message, data, ...influencerNetwork } = response.data;

        setInfluencerNetwork(prevState => ({
          ...prevState,
          [name]: {
            ...prevState[name],
            data,
            error: success ? null : message || 'Something went wrong. Please try again.',
          },
          ...influencerNetwork,
        }));

        if (success) {
          dispatch(updateInventoryInfluencerNetwork(influencerNetwork));
          dispatch(
            updateInfluencerNetwork({
              ...influencerNetwork,
              profileUsername: profile?.username_lower,
            })
          );
        }
      })

      .catch(error => {
        const message = error?.response?.data?.message || 'Something went wrong. Please try again.';

        Toast('error', message, {
          icon: <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />,
        });
      });
  };

  const removeFile = name => {
    setInfluencerNetwork(prevState => ({ ...prevState, [name]: null }));
  };

  useEffect(() => {
    if (!influencerNetworkId) return;

    getRequest({
      endpoint: `influencer_networks/${influencerNetworkId}?with_stats=true`,
    })
      .then(influencerNetwork => setInfluencerNetwork(influencerNetwork))
      .catch(() => {});
  }, [influencerNetworkId]);

  useEffect(
    () => () => {
      blobsRef.current.forEach(file => URL.revokeObjectURL(file));
    },
    [dispatch]
  );

  if (!influencerNetwork) return null;

  return (
    <>
      <DocumentHead>
        <title>Profile Verification | GigSocial</title>
      </DocumentHead>

      <Grid container className={styles.profileVerification} justifyContent="center">
        <Grid item xs={11} className={styles.content}>
          {isDialog && (
            <IconButton onClick={handleClose} className={styles.closeIcon}>
              <CloseIcon />
            </IconButton>
          )}

          <h3>ACCOUNT VERIFICATION</h3>

          <h2>Take and upload screenshots</h2>

          {Object.keys(screenshotsMapping).includes(influencerNetwork.network.name) && (
            <ExternalLink
              preview="url"
              mobileUrl={screenshotsMapping[influencerNetwork.network.name].url}
              desktopUrl={screenshotsMapping[influencerNetwork.network.name].url}
              page={`${influencerNetwork.network.name} Screenshot Guide`}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                color="primary"
                variant="contained"
                endIcon={<ChevronRight />}
                style={buttonStyle}
              >
                Screenshot guide
              </Button>
            </ExternalLink>
          )}

          <p style={{ marginBottom: 15, padding: '0 5px' }}>
            Please upload the screenshots requested below to verify your account. This information
            will be used to help match you with advertiser preferences. The screenshots must be
            taken from a mobile app. Click the “Screenshots Guide” for help.
          </p>

          <ProfileScreenshots
            influencerNetwork={influencerNetwork}
            setInfluencerNetwork={setInfluencerNetwork}
            handleDrop={handleDrop}
            removeFile={removeFile}
          />
        </Grid>
      </Grid>

      <Stepper
        steps={[1, 2, 3, 4, 5, 6, 7]}
        buttonText={showSteps ? 'Finish' : 'Done'}
        handleStepChange={handleStepChange}
        newStyles={styles.fullWrapper}
        showSteps={showSteps}
        skipStep={!isDialog}
        disabled={disabled}
        setOpenConfirmation={setOpenConfirmation}
      />

      <DataDialog />

      {openConfirmation && (
        <ConfirmationDialog
          title="You will not be able to be matched with any brands for the networks you have not uploaded screenshots for. Please finish uploading screenshots at a later time."
          handleApprove={handleApprove}
          approveButtonText="OK"
          declineButtonText="Cancel"
        />
      )}
    </>
  );
};

ProfileVerification.propTypes = {
  handleSuccess: PropTypes.func,
  isDialog: PropTypes.bool,
  showSteps: PropTypes.bool,
};

ProfileVerification.defaultProps = {
  handleSuccess: null,
  isDialog: false,
  showSteps: true,
};

export default ProfileVerification;
