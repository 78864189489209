import { createSlice } from '@reduxjs/toolkit';

const defaultInventory = {
  weekly_availability: 1,
  inventory_items: [],
};

const defaultInventoryItem = {
  live_time_unit: '',
  live_time_duration: '',
  min_guaranteed_views: 10_000,
  average_views: 10_000,
  price: '',
};

export const gigSetupSlice = createSlice({
  name: 'gigSetup',
  initialState: {
    step: 1,
    previousStep: 0,
    networks: [],
    gigNetworks: [],
    currentNetwork: null,
    inventories: [],
    inventorySpaces: [],
    currentInventorySpace: null,
    currentInventory: defaultInventory,
    currentInventoryItem: defaultInventoryItem,
    gigItems: [],
  },

  reducers: {
    nextStep: state => {
      state.step += 1;

      state.previousStep = state.step - 1;
    },

    goToStep: (state, action) => {
      state.previousStep = state.step;
      state.step = action.payload;

      if (action.payload === 1) {
        state.currentNetwork = null;
        state.inventories = [];
        state.inventorySpaces = [];
      }
    },

    setStep: (state, action) => {
      state.step = action.payload;
    },

    addNetworks: (state, action) => {
      state.networks = action.payload;
    },

    addGigNetworks: (state, action) => {
      state.gigNetworks = action.payload;
    },

    addInventories: (state, action) => {
      state.inventories = action.payload;
    },

    addInventorySpaces: (state, action) => {
      state.inventorySpaces = action.payload;
    },

    selectNetwork: (state, action) => {
      state.currentNetwork = action.payload;
    },

    setGigItems: (state, action) => {
      state.gigItems = action.payload;
    },

    addGigItem: (state, action) => {
      const items = state.gigItems.filter(item => item.id !== action.payload.id);
      state.gigItems = [...items, action.payload];
    },

    removeGigItem: (state, action) => {
      state.gigItems = state.gigItems.filter(item => item.id !== action.payload);
    },

    addInventoryItem: (state, { payload }) => {
      state.currentInventory.inventory_items[payload.index] = payload;
      state.currentInventoryItem = defaultInventoryItem;
    },

    updateInventory: (state, action) => {
      state.currentInventory = { ...state.currentInventory, ...action.payload };
    },

    updateInventoryItem: (state, action) => {
      state.currentInventoryItem = { ...state.currentInventoryItem, ...action.payload };
    },

    destroyInventoryItem: (state, action) => {
      state.currentInventory.inventory_items[action.payload]['_destroy'] = true;
    },
  },
});

export const {
  nextStep,
  goToStep,
  setStep,
  addNetworks,
  addGigNetworks,
  addInventories,
  addInventorySpaces,
  selectNetwork,
  addInventoryItem,
  updateInventory,
  updateInventoryItem,
  destroyInventoryItem,
  addGigItem,
  setGigItems,
  removeGigItem,
} = gigSetupSlice.actions;

export default gigSetupSlice.reducer;
