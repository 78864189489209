import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  TextField,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Button,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import pluralize from 'pluralize';
import clsx from 'clsx';

import {
  addInventoryItem,
  closeInventoryItemModal,
  updateInventoryItem,
} from 'features/influencerSetupSlice';
import { getRequest } from 'utils/api';
import { formatLargeNumber, isValid, roundNumber } from 'utils/helpers';
import { openModal } from 'features/utilsSlice';
import { getExtendPriceChange } from 'utils/localStorage';
import CloseIcon from 'public/icons/close-main.svg';
import UpdatePriceModal from './UpdatePriceModal';
import ScaledSlider from 'components/ScaledSlider';
import ActivityToggleButton from 'components/ActivityToggleButton';
import styles from 'styles/components/influencerSetup/InventoryItemModal.module.scss';

const viewsMarks = [
  {
    value: 0,
    scaledValue: 0,
    label: '0',
  },
  {
    value: 25,
    scaledValue: 10000,
    label: '10k',
  },
  {
    value: 50,
    scaledValue: 25000,
    label: '25k',
  },
  {
    value: 75,
    scaledValue: 50000,
    label: '50k',
  },
  {
    value: 100,
    scaledValue: 150000,
    label: '150k',
  },
  {
    value: 125,
    scaledValue: 300000,
    label: '300k',
  },
  {
    value: 150,
    scaledValue: 500000,
    label: '500k',
  },
  {
    value: 175,
    scaledValue: 1000000,
    label: '1M',
  },
  {
    value: 200,
    scaledValue: 2000000,
    label: '2M',
  },
  {
    value: 225,
    scaledValue: 5000000,
    label: '5M',
  },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const buttonStyle = { padding: 0, margin: 0 };
const marginRight = { marginRight: 'auto' };
const noMarginBottom = { marginBottom: 0 };
const flex = { display: 'flex', justifyContent: 'space-between' };

const useStyles = makeStyles(theme => ({
  paper: {
    height: '92%',
    marginTop: 'auto',

    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
}));

const InventoryItemModal = ({ edit = false, selectedInventoryItem }) => {
  const [liveTimeOptions, setLiveTimeOptions] = useState([]);
  const [correctedPrice, setCorrectedPrice] = useState(0);

  const {
    currentInventorySpace,
    inventoryItemModalOpen,
    currentInventory,
    currentInventoryItem,
  } = useSelector(state => state.influencerSetup);
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const extendPriceChange = getExtendPriceChange();

  const activeInventoryItem = currentInventory?.inventory_items?.filter(
    inventoryItem => inventoryItem.active
  );

  const validate = inventoryItem => {
    if (!currentInventorySpace?.offer) return isValid(inventoryItem, ['description']);

    return (
      isValid(inventoryItem, ['description']) &&
      +inventoryItem.max_offer_price > +inventoryItem.min_offer_price
    );
  };

  const saveInventoryItem = (data = {}) => {
    if (validate(currentInventoryItem)) {
      dispatch(
        addInventoryItem({
          ...currentInventoryItem,
          average_views: currentInventorySpace?.has_views ? currentInventoryItem.average_views : 0,
          min_guaranteed_views: currentInventorySpace?.has_views
            ? currentInventoryItem.min_guaranteed_views
            : 0,
          ...data,
        })
      );

      if (currentInventory.inventory_items.length === 0) {
        createAllFrom(currentInventoryItem);
      }
    } else {
      // TODO: Show error messages
    }
  };

  const createAllFrom = inventoryItem => {
    liveTimeOptions
      .filter(option => !option.default)
      .forEach((option, index) => {
        const newInventoryItem = {
          ...inventoryItem,
          live_time_option_id: option.id,
          live_time_duration: option.duration,
          live_time_unit: option.unit,
          index: index + 1,
          price: +inventoryItem.price * option.price_preset_ratio,
          average_views: currentInventorySpace?.has_views
            ? +inventoryItem.average_views * option.price_preset_ratio
            : 0,
          min_guaranteed_views: currentInventorySpace?.has_views
            ? +inventoryItem.min_guaranteed_views * option.price_preset_ratio
            : 0,
        };

        if (inventoryItem.min_offer_price)
          newInventoryItem['min_offer_price'] =
            +inventoryItem.min_offer_price * option.price_preset_ratio;
        if (inventoryItem.max_offer_price)
          newInventoryItem['max_offer_price'] =
            +inventoryItem.max_offer_price * option.price_preset_ratio;

        dispatch(addInventoryItem(newInventoryItem));
      });
  };

  const liveTimeOption = () =>
    filteredOptions().find(
      option =>
        option.duration === currentInventoryItem.live_time_duration &&
        option.unit === currentInventoryItem.live_time_unit
    );

  const filteredOptions = useCallback(() => {
    const taken = currentInventory.inventory_items
      .filter(item => item.index !== currentInventoryItem.index)
      .filter(item => !item['_destroy'])
      .map(item => item.live_time_option_id);

    if (currentInventoryItem.id || currentInventory.inventory_items.length > 0) {
      return liveTimeOptions.filter(option => !taken.some(t => t === option.id));
    }

    return liveTimeOptions.filter(option => option.default);
  }, [currentInventory.inventory_items, currentInventoryItem, liveTimeOptions]);

  const handleKeyPress = e => {
    if (e.keyCode === 13 && e.target.value) {
      saveInventoryItem();
    }

    if (
      e.keyCode === 13 &&
      correctedPrice === +currentInventoryItem.price &&
      activeInventoryItem.length > 1
    ) {
      dispatch(openModal({ name: 'Update Price' }));
    }
  };

  const formatLiveTime = () => {
    const option = liveTimeOption();

    if (option && !option.none) {
      return `${option?.duration} ${pluralize(option?.unit, option?.duration)}`;
    }

    return `1 ${currentInventorySpace.name}`;
  };

  useEffect(() => {
    if (!currentInventorySpace) return;

    getRequest({
      endpoint: 'live_time_options',
      query: { inventory_space_id: currentInventorySpace.id, per_page: 100 },
    })
      .then(options => {
        setLiveTimeOptions(options);
      })
      .catch(() => {});
  }, [currentInventorySpace]);

  useEffect(() => {
    const options = filteredOptions();

    if (!currentInventoryItem.id && options.length === 0) return;

    const option = options.find(o => o.default) || options[0];
    const shouldUpdate =
      !currentInventoryItem.live_time_duration &&
      !currentInventoryItem.live_time_unit &&
      inventoryItemModalOpen;

    if (option && shouldUpdate) {
      dispatch(
        updateInventoryItem({
          live_time_option_id: option.id,
          live_time_duration: option.duration,
          live_time_unit: option.unit,
        })
      );
    }
  }, [
    currentInventoryItem.id,
    currentInventoryItem.live_time_duration,
    currentInventoryItem.live_time_unit,
    dispatch,
    filteredOptions,
    inventoryItemModalOpen,
  ]);

  useEffect(() => {
    if (
      !currentInventoryItem?.id &&
      currentInventorySpace?.license &&
      !currentInventoryItem?.price
    ) {
      dispatch(
        updateInventoryItem({
          price: 1,
        })
      );
    }

    if (
      !currentInventoryItem?.id &&
      currentInventorySpace?.offer &&
      !currentInventoryItem?.min_offer_price &&
      !currentInventoryItem?.max_offer_price
    ) {
      dispatch(
        updateInventoryItem({
          min_offer_price: 50,
          max_offer_price: 50000,
          price: 0,
        })
      );
    }
  }, [
    currentInventoryItem?.id,
    currentInventoryItem?.max_offer_price,
    currentInventoryItem?.min_offer_price,
    currentInventoryItem?.price,
    currentInventorySpace?.offer,
    currentInventorySpace?.license,
    dispatch,
  ]);

  const option = liveTimeOption();

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={inventoryItemModalOpen}
        onClose={() => dispatch(closeInventoryItemModal())}
        TransitionComponent={Transition}
        className={clsx(styles.dialog, classes.paper)}
      >
        <AppBar className={styles.appBar}>
          <Toolbar style={flex}>
            <div style={{ flex: 1 }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => dispatch(closeInventoryItemModal())}
                aria-label="close"
                style={buttonStyle}
              >
                <CloseIcon style={marginRight} width={15} height={15} />
              </IconButton>
            </div>

            <h3 className={styles.title}>{edit ? 'Update price option' : 'Add price option'}</h3>

            {edit ? (
              <ActivityToggleButton
                active={currentInventoryItem.active}
                resourceKey="inventory_item"
                endpoint={`inventory_items/${currentInventoryItem.id}`}
                callback={({ active }) => {
                  saveInventoryItem({ active });
                  dispatch(closeInventoryItemModal());
                }}
              />
            ) : (
              <div style={{ flex: 1 }} />
            )}
          </Toolbar>
        </AppBar>

        <div className={styles.modalContent}>
          {!currentInventorySpace?.offer && (
            <>
              <h4 className={clsx(styles.label)}>
                Price for{' '}
                {option && (
                  <span style={{ color: '#5b00db', textTransform: 'uppercase' }}>
                    {formatLiveTime()}
                  </span>
                )}{' '}
                {!option?.none && !option?.sub && 'live time'}
              </h4>

              <TextField
                type="number"
                variant="outlined"
                value={roundNumber(currentInventoryItem.price)}
                style={noMarginBottom}
                onChange={e => {
                  dispatch(updateInventoryItem({ price: e.target.value.replace(/[,.]/g, '') }));
                  setCorrectedPrice(+e.target.value);
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onKeyUp={handleKeyPress}
              />
            </>
          )}

          {currentInventorySpace?.offer && (
            <>
              <h4 className={clsx(styles.label)}>
                Min Offer Price for{' '}
                {option && (
                  <span style={{ color: '#5b00db', textTransform: 'uppercase' }}>
                    {formatLiveTime()}
                  </span>
                )}{' '}
                {!option?.none && !currentInventorySpace?.event_attendance && 'live time'}
              </h4>

              <TextField
                type="number"
                variant="outlined"
                value={roundNumber(currentInventoryItem.min_offer_price)}
                onChange={e => {
                  dispatch(
                    updateInventoryItem({ min_offer_price: e.target.value.replace(/[,.]/g, '') })
                  );
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onKeyUp={handleKeyPress}
              />

              <h4 className={clsx(styles.label)}>
                Max Offer Price for{' '}
                {option && (
                  <span style={{ color: '#5b00db', textTransform: 'uppercase' }}>
                    {formatLiveTime()}
                  </span>
                )}{' '}
                {!option?.none && !currentInventorySpace?.event_attendance && 'live time'}
              </h4>

              <TextField
                type="number"
                variant="outlined"
                value={roundNumber(currentInventoryItem.max_offer_price)}
                style={noMarginBottom}
                error={
                  +currentInventoryItem.max_offer_price <= +currentInventoryItem.min_offer_price
                }
                onChange={e => {
                  dispatch(
                    updateInventoryItem({ max_offer_price: e.target.value.replace(/[,.]/g, '') })
                  );
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onKeyUp={handleKeyPress}
              />
            </>
          )}

          {currentInventorySpace?.has_views && (
            <>
              <div>
                <div className="slider-title">
                  <h4>Average views</h4>

                  <span>{formatLargeNumber(currentInventoryItem.average_views)}</span>
                </div>

                <ScaledSlider
                  count={currentInventoryItem.average_views}
                  setCount={(_, scaledValue) => {
                    dispatch(updateInventoryItem({ average_views: scaledValue }));
                  }}
                  max={225}
                  marks={viewsMarks}
                  style={noMarginBottom}
                />
              </div>

              <div>
                <div className="slider-title">
                  <h4>Minimum guaranteed views</h4>

                  <span>{formatLargeNumber(currentInventoryItem.min_guaranteed_views)}</span>
                </div>

                <ScaledSlider
                  count={currentInventoryItem.min_guaranteed_views}
                  setCount={(_, scaledValue) => {
                    dispatch(updateInventoryItem({ min_guaranteed_views: scaledValue }));
                  }}
                  max={225}
                  marks={viewsMarks}
                />
              </div>
            </>
          )}
        </div>

        <div className={styles.buttons}>
          <Button onClick={() => dispatch(closeInventoryItemModal())}>Cancel</Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (
                correctedPrice === +currentInventoryItem.price &&
                activeInventoryItem.length > 1
              ) {
                dispatch(openModal({ name: 'Update Price' }));
              }
              saveInventoryItem();
            }}
            disabled={!validate(currentInventoryItem) || +currentInventoryItem.price < 0}
          >
            Save
          </Button>
        </div>
      </Dialog>

      {correctedPrice !== +currentInventoryItem.price && !extendPriceChange && (
        <UpdatePriceModal
          selectedInventoryItem={selectedInventoryItem}
          correctedPrice={correctedPrice}
          setCorrectedPrice={setCorrectedPrice}
        />
      )}
    </div>
  );
};

InventoryItemModal.propTypes = {
  selectedInventoryItem: PropTypes.object.isRequired,
  edit: PropTypes.bool,
};

export default InventoryItemModal;
