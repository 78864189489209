import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';

import directUpload from 'utils/directUpload';
import compressImage from 'utils/compressImage';
import FullScreenLoader from 'components/milestones/FullScreenLoader';
import styles from 'styles/components/DropzoneField.module.scss';

const DropzoneFieldMultiple = ({
  setUploads,
  uploads,
  accept = 'image/*',
  handleClick,
  disabled,
  performDirectUpload = false,
  compress = true,
  compressOptions = { quality: 0.8 },
}) => {
  const [progress, setProgress] = useState({});
  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    accept,
    onDrop: async acceptedFiles => {
      const promises = await Promise.allSettled(
        acceptedFiles.map(async file => {
          let preview;
          let finalFile;

          if (['image/heic', 'image/heif'].includes(file?.type)) {
            const blob = await require('heic2any')({
              blob: file,
              toType: 'image/jpeg',
              quality: 0.8,
              multiple: false,
            }).catch(e => {
              console.log('Error converting heic to jpeg', e);
            });

            const convertedFile = new File([blob], file.name, { type: blob.type });

            if (compress && (compressOptions?.maxHeight || compressOptions?.maxWidth)) {
              const compressedFile = await compressImage(convertedFile, {
                ...compressOptions,
                quality: 1,
              }).catch(() => {
                console.log('Error compressing image #1', { file: file });
                preview = URL.createObjectURL(convertedFile);
                finalFile = convertedFile;
              });

              preview = URL.createObjectURL(compressedFile);
              finalFile = compressedFile;
            } else {
              preview = URL.createObjectURL(convertedFile);
              finalFile = convertedFile;
            }
          } else if (['image/jpeg', 'image/jpg', 'image/png'].includes(file?.type)) {
            if (compress) {
              const compressedFile = await compressImage(file, compressOptions).catch(() => {
                preview = URL.createObjectURL(file);
                finalFile = file;
                console.log('Error compressing image #2', { file: file });
              });
              preview = URL.createObjectURL(compressedFile);
              finalFile = compressedFile;
            } else {
              preview = URL.createObjectURL(file);
              finalFile = file;
            }
          } else {
            preview = URL.createObjectURL(file);
            finalFile = file;
          }

          return { file: finalFile, preview };
        })
      );

      const files = promises
        .filter(promise => promise.status === 'fulfilled')
        .map(promise => promise.value);

      if (!performDirectUpload) {
        setUploads(files);
        return;
      }

      setLoading(true);

      const results = await Promise.allSettled(
        files.map(async file => {
          const blob = await directUpload({
            file: file.file,
            handleProgress: event => {
              setProgress(prevProgress => ({
                ...prevProgress,
                [file.file.name]: Math.round((event.loaded * 100) / event.total),
              }));
            },
          }).catch(() => {
            console.log('Error in direct upload', { file: file });
          });

          return { blob, ...file };
        })
      );

      setUploads(
        results.filter(result => result.status === 'fulfilled').map(result => result.value)
      );
      setLoading(false);
      setProgress({});
    },
    disabled: disabled,
  });

  useEffect(
    () => () => {
      uploads.forEach(upload => {
        if (upload.preview) {
          URL.revokeObjectURL(upload.preview);
        }
      });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const progressValue = loading
    ? Object.values(progress).reduce((acc, curr) => acc + curr, 0) / Object.keys(progress).length
    : 0;

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={styles.dropzoneField} onClick={handleClick}>
          <span>Drop file or</span>

          <Button color="primary" variant="contained" disabled={disabled}>
            Browse
          </Button>
        </div>
      </div>

      {loading && <FullScreenLoader progress={progressValue} />}
    </>
  );
};

export default DropzoneFieldMultiple;
