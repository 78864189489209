import React from 'react';
import Image from 'next/image';
import { Avatar, Chip, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { format } from 'date-fns';

import { formatLargeNumber, formatPrice } from 'utils/helpers';
import NoAvatarImage from 'public/images/no-avatar.svg';
import styles from 'styles/components/Wallet.module.scss';

const size = { width: 50, height: 50 };
const alertStyle = { width: 'auto', margin: '0 15px' };
const avatarFullSize = { width: '100%', height: '100%' };

const AffiliateTransactions = ({ transactions = [], isLoading = false }) => {
  if (transactions.length === 0 && !isLoading) {
    return (
      <Alert style={alertStyle} severity="info">
        No referred influencers
      </Alert>
    );
  }

  if (transactions?.length === 0 && isLoading) return <CircularProgress color="primary" />;

  return (
    <div className={styles.content} style={{ paddingBottom: 20 }}>
      <h2>Influencers</h2>

      {transactions.map(transaction => (
        <div className={styles.transaction} key={transaction.id}>
          <Avatar src={transaction.avatar_url} alt="Profile" style={size}>
            <NoAvatarImage style={avatarFullSize} />
          </Avatar>

          <div className={styles.details}>
            <div>
              <span className={styles.title}>
                {transaction.display_name}

                <div style={{ marginLeft: 5 }}>
                  <Chip
                    label={transaction.completed ? 'Profile Completed' : 'No Screenshots'}
                    className={transaction.completed ? 'light-purple-chip' : 'light-red-chip'}
                  />
                </div>
              </span>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 5,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                {transaction.influencer_networks.map(influencerNetwork => (
                  <>
                    <Image
                      src={
                        influencerNetwork.verified
                          ? influencerNetwork.network_active_icon
                          : influencerNetwork.network_icon
                      }
                      width={20}
                      height={20}
                      alt={influencerNetwork.network_name}
                      unoptimized
                    />

                    {influencerNetwork.top_creator_percentage > 0 ? (
                      <span className="color-main" style={{ fontWeight: 'bold' }}>
                        Top {influencerNetwork.top_creator_percentage}%
                      </span>
                    ) : (
                      <span>{formatLargeNumber(influencerNetwork.max_followers)}</span>
                    )}
                  </>
                ))}
              </div>

              <span className={styles.date}>
                Current % earning:{' '}
                <span className="color-main">{transaction.referral_fee_percentage * 100}%</span>
                <br />
                Signed up on:{' '}
                <span className="color-main">
                  {format(new Date(transaction.created_at), 'MMM d yyyy')}
                </span>
              </span>
            </div>

            <div className={styles.price} style={{ paddingRight: 20 }}>
              {formatPrice(transaction.amount)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AffiliateTransactions;
