export const NETWORK_COLORS = {
  Instagram: '#c32aa3',
  TikTok: '#010101',
  Twitter: '#000000',
  Facebook: '#1877f2',
  YouTube: '#FF0000',
  OnlyFans: '#00ADEF',
  LoyalFans: '#e22525',
  Podcast: '#9f59e5',
  'Image & Likeness': '#8828e8',
  'Blog/Website': '#5517a3',
};
