import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { screenshotNetworkMapping, urlRegex } from 'utils/helpers';
import { postRequest } from 'utils/api';
import getStatus from 'utils/screenshotVerification';
import Toast from 'utils/toast';
import CheckIcon from 'public/icons/check-circle-green.svg';
import ExclamationIcon from 'public/icons/exclamation-circle.svg';
import ClockIcon from 'public/icons/clock-circle.svg';
import IconMainContainer from 'components/IconMainContainer';
import HelpDialog from 'components/HelpDialog';
import NetworkIcon from 'components/NetworkIcon';
import ScreenshotDropzoneField from 'components/profileVerification/ScreenshotDropzoneField';
import styles from 'styles/components/profileVerification/ProfileVerification.module.scss';

const buttonStyle = {
  height: 30,
  marginTop: 'auto',
  padding: '6px 20px',
  width: 'fit-content',
};

const useStyles = makeStyles({});

const ProfileScreenshots = ({
  influencerNetwork,
  setInfluencerNetwork,
  handleDrop,
  removeFile,
}) => {
  const classes = useStyles();

  const requiredScreenshots = screenshotNetworkMapping[influencerNetwork.network.name];

  const handleVerifyUrl = () => {
    postRequest({
      endpoint: `influencer_networks/${influencerNetwork.id}/verify_url`,
      data: { url: influencerNetwork.username },
      successMessage: 'Successfully sent for verification.',
    })
      .then(() => {
        setInfluencerNetwork(prevState => ({
          ...prevState,
          status: 'pending_verification',
          profile_verification_status: 'pending_verification',
        }));
      })
      .catch(() => {
        Toast('error', 'Invalid url.', {
          icon: <IconMainContainer src="/icons/notifications/times.svg" alt="Times" errorStyle />,
        });
      });
  };

  const renderScreenshot = screenshot => {
    const onlyFansAnalytics =
      screenshot.name === 'analytics_screenshot' &&
      ['OnlyFans'].includes(influencerNetwork.network.name) &&
      ['unverified', 'rejected'].includes(influencerNetwork.profile_screenshot_status);

    const facebookAnalytics =
      influencerNetwork.network.name === 'Facebook' &&
      screenshot.name !== 'username_screenshot' &&
      ['unverified', 'rejected'].includes(influencerNetwork.username_screenshot_status);

    return (
      <div className={styles.network}>
        <ScreenshotDropzoneField
          key={screenshot.name}
          name={screenshot.name}
          influencerNetwork={influencerNetwork}
          handleDrop={handleDrop}
          removeFile={removeFile}
          title={screenshot.label}
          disabled={onlyFansAnalytics || facebookAnalytics}
        />
      </div>
    );
  };

  const showProgress = requiredScreenshots.some(s =>
    ['verified', 'pending_verification'].includes(influencerNetwork[s.status_key])
  );

  return (
    <div>
      {influencerNetwork?.automatic_verification ? (
        <div className={styles.networkContainer}>
          <div className={styles.actions} style={!showProgress ? { display: 'none' } : {}}>
            {showProgress &&
              requiredScreenshots.map((screenshot, index) => (
                <div
                  key={index}
                  className={styles.label}
                  style={
                    influencerNetwork[screenshot.status_key] === 'verified' ? { opacity: 0.5 } : {}
                  }
                >
                  {influencerNetwork[screenshot.status_key] === 'verified' && (
                    <CheckIcon width={16} height={16} />
                  )}

                  {influencerNetwork[screenshot.status_key] === 'pending_verification' && (
                    <ClockIcon />
                  )}

                  {['rejected', 'unverified'].includes(
                    influencerNetwork[screenshot.status_key]
                  ) && <ExclamationIcon />}

                  <span style={{ fontFamily: 'Avenir-Heavy', marginLeft: 5 }}>
                    {screenshot.label}
                  </span>
                </div>
              ))}
          </div>

          <div className={styles.networkName}>
            <div style={{ display: 'flex', alignItem: 'center' }}>
              <NetworkIcon
                active={true}
                network={influencerNetwork.network}
                styles={styles}
                hasName={false}
              />

              <span className={styles.name} style={{ marginLeft: 10 }}>
                {influencerNetwork.network.name}
              </span>
            </div>
          </div>

          {requiredScreenshots.map((screenshot, index) => (
            <div key={index}>{renderScreenshot(screenshot)}</div>
          ))}
        </div>
      ) : (
        <div className={styles.urlContainer}>
          <div className={styles.networkName}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <NetworkIcon
                active={true}
                network={influencerNetwork.network}
                styles={styles}
                hasName={false}
              />

              <span className={styles.name} style={{ marginLeft: 10 }}>
                {influencerNetwork.network.name}
              </span>
            </div>
          </div>

          <div
            className={clsx(styles.urlBox, {
              [styles.pending]: influencerNetwork.status === 'pending_verification',
              [styles.verified]: influencerNetwork.status === 'verified',
              [styles.rejected]: influencerNetwork.status === 'rejected',
            })}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 15,
              }}
            >
              <h5>URL</h5>

              <span className={styles.title}>{getStatus(influencerNetwork)}</span>
            </div>

            <TextField
              type="url"
              variant="outlined"
              className={classes.root}
              value={
                influencerNetwork.username === `unverified_${influencerNetwork.profile_id}`
                  ? ''
                  : influencerNetwork.username
              }
              onChange={e => {
                setInfluencerNetwork(prevState => ({
                  ...prevState,
                  username: e.target.value,
                }));
              }}
              placeholder={`${influencerNetwork?.network?.name} URL`}
            />

            <Button
              onClick={handleVerifyUrl}
              color="primary"
              variant="contained"
              style={buttonStyle}
              disabled={!influencerNetwork.username.match(urlRegex)}
            >
              {['pending_verification', 'verified'].includes(influencerNetwork.status)
                ? 'Update'
                : 'Verify'}
            </Button>
          </div>
        </div>
      )}

      <HelpDialog />
    </div>
  );
};

ProfileScreenshots.propTypes = {
  influencerNetwork: PropTypes.object.isRequired,
  setInfluencerNetwork: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
};

export default ProfileScreenshots;
