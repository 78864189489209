import { useEffect, useRef, useContext } from 'react';
import CableContext from './Context';

const Cable = ({
  onReceived,
  onInitialized,
  onConnected,
  onDisconnected,
  onRejected,
  channel,
  data = {},
}) => {
  const consumer = useContext(CableContext);
  const subscription = useRef(null);

  useEffect(() => {
    if (!consumer || subscription.current) return;

    subscription.current = consumer.cable.subscribeTo(channel, data);

    subscription.current.on('message', data => {
      onReceived(data);
    });

    subscription.current.on('initialized', () => {
      if (onInitialized) onInitialized();
    });

    subscription.current.on('connected', () => {
      if (onConnected) onConnected();
    });

    subscription.current.on('disconnected', () => {
      if (onDisconnected) onDisconnected();
    });

    subscription.current.on('rejected', () => {
      if (onRejected) onRejected();
    });

    return () => {
      if (subscription.current) {
        subscription.current.disconnect();
        subscription.current = null;
      }
    };
  }, [consumer]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default Cable;
