import { createSlice } from '@reduxjs/toolkit';

export const filterInventorySlice = createSlice({
  name: 'filterInventory',
  initialState: {
    selectedNetworkIds: [],
    selectedSpaces: [],
  },

  reducers: {
    selectSpace: (state, action) => {
      const name = action.payload;

      const spaces = state.selectedSpaces.includes(name)
        ? state.selectedSpaces.filter(n => n !== name)
        : [...state.selectedSpaces, name];

      state.selectedSpaces = spaces;
    },

    setFilterState: (state, action) => {
      state.selectedNetworkIds = action.payload.networkIds;
      state.selectedSpaces = action.payload.spaces;
    },
  },
});

export const { setFilterState, selectSpace } = filterInventorySlice.actions;

export default filterInventorySlice.reducer;
