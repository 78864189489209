import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { InputLabel, Button, CircularProgress, Hidden } from '@material-ui/core';
import { autoPlay } from 'react-swipeable-views-utils';
import Alert from '@material-ui/lab/Alert';
import SwipeableViews from 'react-swipeable-views';
import * as yup from 'yup';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import slugify from 'slugify';

import { setCart } from 'features/cartSlice';
import { formatPrice, initDate, normalizeInitialValues, urlRegex } from 'utils/helpers';
import { getRequest, postRequest, putRequest } from 'utils/api';
import { getUuid } from 'utils/localStorage';
import useCurrentProfile from 'hooks/useCurrentProfile';
import CartAddIcon from 'public/icons/cart-add.svg';
import AddAssets from '../AddAssets';
import AdditionalOptions from '../AdditionalOptions';
import QuantityAndPricing from './QuantityAndPricing';
import EventAttendance from './EventAttendance';
import SpecialInstructions from '../SpecialInstructions';
import OrderDetails from './OrderDetails';
import TransitionDialog from 'components/TransitionDialog';
import SwitchButton from 'components/SwitchButton';
import DropzoneField from 'components/gigSetup/DropzoneField';
import ShareInventoryItem from 'components/ShareInventoryItem';
import closeIconStyles from 'styles/components/OrderDialog.module.scss';
import styles from 'styles/components/gigSetup/GigItemDialog.module.scss';

const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/heic',
  'image/heif',
  'video/mp4',
  'video/mov',
  'video/mpeg',
  'video/quicktime',
];

const SUPPORTED_FORMATS_SPECIAL_INSTRUCTIONS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/heic',
  'image/heif',
  'video/mp4',
  'video/mov',
  'video/mpeg',
  'video/quicktime',
  'application/pdf',
];

const defaultRequired = 'is required.';

const schema = yup.object({
  quantity: yup.number().required(defaultRequired),
  frequency: yup.string().required(defaultRequired),
  min_guaranteed_views: yup.number().required(defaultRequired),
  caption: yup.string(),
  url: yup
    .string()
    .matches(urlRegex, 'Please enter a valid URL.')
    .nullable(),
  special_instructions: yup.string(),
  inventory_item_id: yup.number().required(defaultRequired),
  thumbnail: yup
    .mixed()
    .test('fileSize', 'File size is too large.', value => {
      // URL or file
      if (typeof value === 'string') return value.length > 0;

      return value ? value.size <= 10 * 1024 * 1024 : true;
    })
    .test('fileType', 'Unsupported file format.', value => {
      if (typeof value === 'string') return value.length > 0;

      return value
        ? SUPPORTED_FORMATS.filter(f => f.startsWith('image')).includes(value.type)
        : true;
    }),
  new_assets: yup.array().of(
    yup.object({
      file: yup
        .mixed()
        .required(defaultRequired)
        .test('fileSize', 'File size is too large.', value =>
          value ? value.size <= 50 * 1024 * 1024 : true
        )
        .test('fileType', 'Unsupported file format.', value =>
          value ? SUPPORTED_FORMATS.includes(value.type) : true
        ),
      preview: yup.mixed().required(defaultRequired),
    })
  ),
  new_special_instructions_assets: yup.array().of(
    yup.object({
      file: yup
        .mixed()
        .required(defaultRequired)
        .test('fileSize', 'File size is too large.', value =>
          value ? value.size <= 20 * 1024 * 1024 : true
        )
        .test('fileType', 'Unsupported file format.', value =>
          value ? SUPPORTED_FORMATS_SPECIAL_INSTRUCTIONS.includes(value.type) : true
        ),
      preview: yup.mixed().required(defaultRequired),
    })
  ),
});

const initialValues = {
  quantity: 1,
  variable_quantity: 1,
  frequency: 'weekly',
  min_guaranteed_views: 1000,
  caption: '',
  url: '',
  special_instructions: '',
  new_assets: [],
  new_special_instructions_assets: [],
  thumbnail: '',
  offered_price: 0,
  start_times: [],
  location: '',
  travel_included: false,
  lodging_included: false,
  meals_included: false,
};

const marginTop20 = { marginTop: 20 };
const noMarginTop = { marginTop: 0 };
const compressOptions = { quality: 0.8, maxWidth: 750, maxHeight: 750 };

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const OrderDialog = ({
  inventoryId,
  open,
  handleClose,
  cartItem,
  fromInventory,
  username,
  profileOwner,
  displayName,
}) => {
  const [assetsType, setAssetsType] = useState(cartItem?.assets_type);
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const cartItems = cart.cart_items || [];
  const isLoggedIn = useSelector(state => state.auth.loggedIn);
  const role = useSelector(state => state.auth.currentUser.role);
  const profile = useCurrentProfile();
  const router = useRouter();
  const uuid = getUuid();

  const [value, setValue] = useState(0);
  const [inventory, setInventory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deletedAssetIds, setDeletedAssetIds] = useState([]);
  const [checked, setChecked] = useState({
    caption: false,
    special_instructions: false,
    hasAssets: false,
    url: false,
  });

  const space = inventory?.inventory_space;
  const network = inventory?.influencer_network.network;
  const cartProfileDisplayName = cartItems.length > 0 ? cartItems[0].profile?.display_name : null;
  const isGG = space?.name === 'Guaranteed Gains';

  const canOrder =
    cartItems.length === 0 ||
    get(inventory, 'influencer_network.profile_id') === cartItems[0].profile?.id ||
    (cart.guaranteed_gains && cartItems.every(item => item.guaranteed_gains));

  const onlyGuaranteedGains =
    (cart.guaranteed_gains && !space?.guaranteed_gains) ||
    (!cart.guaranteed_gains && cartItems.length > 0 && space?.guaranteed_gains);

  const defaultItemId = cartItem
    ? cartItem.inventory_item.id
    : +router.query?.ii ||
      inventory?.inventory_items.filter(i => i.active).find(i => i.default)?.id ||
      inventory?.inventory_items.filter(i => i.active)[0]?.id;

  const getInventoryItem = id => inventory?.inventory_items.find(i => i.id === id);

  const getPrice = values => {
    const item = getInventoryItem(values.inventory_item_id);
    const price = space.offer ? values.offered_price : item?.price;

    return formatPrice(price * values.quantity * values.variable_quantity);
  };

  const handleChangeIndex = index => setValue(index);

  const handleChange = (event, setFieldValue) => {
    const name = event.target.name;
    const checked = event.target.checked;

    if (!checked) {
      if (name === 'caption') {
        setFieldValue('caption', '');
      } else if (name === 'url') {
        setFieldValue('url', '');
      } else if (name === 'special_instructions') {
        setFieldValue('special_instructions', '');
        setFieldValue('new_special_instructions_assets', []);
      } else {
        setFieldValue('min_guaranteed_views', 0);
      }
    }

    setChecked(prev => ({ ...prev, [name]: checked }));
  };

  const handleCloseOrderDialog = () => {
    setChecked({
      caption: false,
      special_instructions: false,
    });

    handleClose();
  };

  const handleSubmit = async (values, actions) => {
    setIsLoading(true);

    const data = new FormData(); // eslint-disable-line no-undef

    const cartItemkeys = Object.keys(
      pick(values, ['quantity', 'frequency', 'inventory_item_id', 'offered_price'])
    );

    cartItemkeys.forEach(key => {
      if (values[key]) data.set(`cart_item[${key}]`, values[key]);
    });

    const milestoneKeys = Object.keys(
      pick(values, [
        'caption',
        'url',
        'special_instructions',
        'variable_quantity',
        'location',
        'location_latitude',
        'location_longitude',
        'travel_included',
        'meals_included',
        'lodging_included',
      ])
    );

    values.new_assets.forEach(asset =>
      data.append(`milestone[assets][]`, asset?.blob?.signed_id || asset.file)
    );

    values.start_times.forEach(time => data.append(`milestone[start_times][]`, time));

    values.new_special_instructions_assets.forEach(asset =>
      data.append(`milestone[special_instructions_assets][]`, asset?.blob?.signed_id || asset.file)
    );

    deletedAssetIds.forEach(id => data.append(`milestone[delete_attachment_ids][]`, id));

    milestoneKeys.forEach(key => {
      if (
        values[key] ||
        [
          'special_instructions',
          'caption',
          'url',
          'travel_included',
          'lodging_included',
          'meals_included',
        ].includes(key)
      )
        data.set(`milestone[${key}]`, values[key]);
    });

    data.set('milestone[assets_type]', space?.has_assets ? assetsType : 'no_assets');

    data.set('cart[thumbnail]', values.thumbnail);

    if (router.pathname === '/m/[username]' || router.pathname === '/discover') {
      data.set('cart[matching_checkout]', true);
    }

    if (!isLoggedIn) data.set('cart[uuid]', uuid);

    const request = cartItem ? putRequest : postRequest;

    const notLoggedInEndpoint = cartItem
      ? `carts/${uuid}/cart_items/${cartItem.id}`
      : `carts/${uuid}/cart_items`;

    const loggedInEndpoint = cartItem
      ? `carts/${cart.id}/cart_items/${cartItem.id}`
      : `carts/${cart.id}/cart_items`;

    const endpoint = isLoggedIn ? loggedInEndpoint : notLoggedInEndpoint;

    if (!isLoggedIn && !cart.id) {
      const response = await postRequest({
        endpoint: 'carts',
        data: { cart: { uuid } },
      });

      dispatch(setCart(response.data));
    }

    request({
      endpoint,
      data,
      successMessage: cartItem
        ? 'Successfully updated the cart item.'
        : 'Successfully added an item to the cart.',
      ...actions,
    })
      .then(response => {
        dispatch(setCart(response.data));
        handleCloseOrderDialog();
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const removeAsset = asset => setDeletedAssetIds([...deletedAssetIds, asset.id]);

  useEffect(() => {
    if (!inventoryId) return;

    getRequest({
      endpoint: `inventories/${inventoryId}`,
    })
      .then(inventory => {
        setInventory(inventory);
      })
      .catch(() => {});
  }, [inventoryId]);

  useEffect(() => {
    if (!cartItem) return;

    let update = {};

    if (cartItem.caption) {
      update['caption'] = true;
    }
    if (cartItem.special_instructions) {
      update['special_instructions'] = true;
    }
    if (cartItem.url) {
      update['url'] = true;
    }

    setChecked(prevState => ({ ...prevState, ...update }));
  }, [cartItem?.caption, cartItem?.special_instructions, cartItem?.url, cartItem]);

  useEffect(() => {
    if (cartItem && assetsType) {
      setAssetsType(cartItem.assets_type);
    }
  }, [cartItem]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (cartItem) return;

    setAssetsType('assets_provided');
  }, [cartItem]);

  const isVideo = type => type.includes('video/');
  const buttonText = cartItem ? 'Update' : 'Add to Cart';
  const canHideCover = (role === 'influencer' && !!profile?.avatar_url) || space?.has_url;

  const isValid = (values, errors) => {
    const captionValid = !checked.caption || values.caption;
    const urlValid = !checked.url || values.url;
    const instructionsValid = !checked.special_instructions || values.special_instructions;
    const noErrors = isEmpty(errors);
    const thumbnailValid =
      (space.digital_signature && inventory?.assets?.length > 0) ||
      !!profile?.avatar_url ||
      space.has_url ||
      space.fan ||
      values.thumbnail;
    const assetsRequired =
      space.has_assets && (assetsType === 'assets_provided' || checked.hasAssets);
    const assetsUploaded =
      values.new_assets.length > 0 ||
      cartItem?.assets?.filter(a => !deletedAssetIds.includes(a.id)).length > 0;
    const assetsValid = assetsRequired ? assetsUploaded : true;
    const offerValid = space.offer ? !!values.offered_price : true;
    const datesValid = space.event_attendance
      ? values.start_times.length >= values.quantity &&
        values.start_times.every(time => time >= new Date())
      : true;
    const locationValid = space.event_attendance ? values.location.length > 0 : true;

    return (
      captionValid &&
      urlValid &&
      instructionsValid &&
      assetsValid &&
      thumbnailValid &&
      offerValid &&
      datesValid &&
      locationValid &&
      noErrors &&
      !isLoading
    );
  };

  if (!inventory) return null;

  return (
    <TransitionDialog open={open} handleClose={handleCloseOrderDialog} styles={closeIconStyles}>
      {!canOrder && fromInventory && (
        <Alert severity="info" className={styles.alert}>
          <h3>Your cart has items from {cartProfileDisplayName}.</h3>

          <span>
            You can only order from <strong>one</strong> influencer at a time.
          </span>
        </Alert>
      )}

      {onlyGuaranteedGains && (
        <Alert severity="info" className={styles.alert}>
          <h3>You can ONLY order guaranteed gains in ONE cart.</h3>
        </Alert>
      )}

      {canOrder && !onlyGuaranteedGains && (
        <>
          <Formik
            validationSchema={schema}
            initialValues={normalizeInitialValues({
              ...initialValues,
              inventory_item_id: defaultItemId,
              thumbnail: cart.thumbnail_url,
              quantity: space.custom_quantity ? space.default_quantity : initialValues.quantity,
              variable_quantity: space.custom_variable_quantity
                ? space.default_variable_quantity
                : initialValues.variable_quantity,
              offered_price:
                cartItem?.offered_price || getInventoryItem(defaultItemId)?.min_offer_price || 0,
              ...cartItem,
              start_times: cartItem?.start_times.map(time => initDate(time)) || [],
            })}
            enableReinitialize
          >
            {({ setFieldValue, setFieldTouched, values, actions, errors }) => {
              const inventoryItem = getInventoryItem(values.inventory_item_id);
              const digitalSignature = space.digital_signature;
              const price = getPrice(values);
              const showSummary =
                inventoryItem?.live_time_unit !== 'none' && !space.event_attendance;
              const summaryQuantity = space.has_quantity
                ? values.quantity
                : values.variable_quantity;

              const networkName = inventory?.influencer_network?.network?.name;
              const spaceName = inventory?.inventory_space?.name;

              const metaUrl =
                networkName && spaceName
                  ? `${process.env.NEXT_PUBLIC_URL}/u/${username}/${slugify(networkName, {
                      lower: true,
                    })}/${inventory.id}/${slugify(spaceName, { lower: true })}/${inventoryItem?.id}`
                  : `${process.env.NEXT_PUBLIC_URL}/u/${username}`;

              return (
                <Form>
                  {digitalSignature && inventory?.assets?.length > 0 && (
                    <div style={{ position: 'relative', height: 415 }}>
                      <div className={styles.topShadow} />
                      <div className={styles.bottomShadow} />

                      <Hidden mdUp>
                        <SwipeableViews onChangeIndex={handleChangeIndex} index={value}>
                          {[...inventory?.assets]
                            ?.sort((a, b) => {
                              if (a.name === 'digital_signature_sport.jpg') {
                                return -1;
                              }

                              if (b.name === 'digital_signature_sport.jpg') {
                                return 1;
                              }

                              return 0;
                            })
                            .map((asset, index) => (
                              <img
                                key={index}
                                src={asset?.url}
                                width="100%"
                                height="auto"
                                style={{ objectFit: 'cover', zIndex: 1, height: 415 }}
                                alt="Media"
                              />
                            ))}
                        </SwipeableViews>

                        <SwitchButton
                          assets={inventory?.assets}
                          value={value}
                          setValue={setValue}
                        />
                      </Hidden>

                      <Hidden smDown>
                        <AutoPlaySwipeableViews onChangeIndex={handleChangeIndex} index={value}>
                          {[...inventory?.assets]
                            ?.sort((a, b) => {
                              if (a.name === 'digital_signature_sport.jpg') {
                                return -1;
                              }

                              if (b.name === 'digital_signature_sport.jpg') {
                                return 1;
                              }

                              return 0;
                            })
                            .map((asset, index) => (
                              <img
                                key={index}
                                src={asset?.url}
                                width="100%"
                                height="auto"
                                style={{ objectFit: 'cover', zIndex: 1, height: 415 }}
                                alt="Media"
                              />
                            ))}
                        </AutoPlaySwipeableViews>

                        <SwitchButton
                          assets={inventory?.assets}
                          value={value}
                          setValue={setValue}
                        />
                      </Hidden>
                    </div>
                  )}

                  {digitalSignature && inventory?.assets?.length === 0 && (
                    <div>
                      <div className={styles.topShadow} />
                      <div className={styles.bottomShadow} />

                      <Alert
                        style={{ position: 'relative', height: 200, borderRadius: 0 }}
                        severity="info"
                      >
                        {profileOwner ? (
                          <span>Need to set up a digital signature again.</span>
                        ) : (
                          <span>
                            Cannot order a digital signature at this moment. The influencer needs to
                            set it up again.
                          </span>
                        )}
                      </Alert>
                    </div>
                  )}

                  <div
                    className={styles.container}
                    style={digitalSignature ? { marginTop: '-51px' } : {}}
                  >
                    <OrderDetails
                      inventoryItem={inventoryItem}
                      digitalSignature={digitalSignature}
                      network={network}
                      space={space}
                      assets={inventory?.assets}
                      description={inventory?.description}
                      displayName={displayName}
                    />

                    <QuantityAndPricing
                      space={space}
                      quantity={values.quantity}
                      frequency={values.frequency}
                      variableQuantity={values.variable_quantity}
                      offeredPrice={+values.offered_price}
                      inventoryItemId={values.inventory_item_id}
                      startTimes={values.start_times}
                      setFieldValue={setFieldValue}
                      inventory={inventory}
                      showSummary={showSummary}
                      summaryQuantity={summaryQuantity}
                      inventoryItem={inventoryItem}
                      getInventoryItem={getInventoryItem}
                      digitalSignature={digitalSignature}
                      profileOwner={profileOwner}
                    />

                    {space.has_assets && !profileOwner && (
                      <AddAssets
                        space={space}
                        assetsType={assetsType}
                        setAssetsType={setAssetsType}
                        newAssets={values.new_assets}
                        setFieldValue={setFieldValue}
                        item={cartItem}
                        removeAsset={removeAsset}
                        isVideo={isVideo}
                        errors={errors}
                        deletedAssetIds={deletedAssetIds}
                        setFieldTouched={setFieldTouched}
                        checked={checked.hasAssets}
                        spaceName={space.name}
                      />
                    )}

                    {!digitalSignature && !profileOwner && !canHideCover && !space.fan && (
                      <>
                        <InputLabel style={marginTop20}>
                          {space?.has_assets ? ' Upload cover image' : 'Your brand image or logo'}{' '}
                          <span className="fw-n">(required)</span>
                        </InputLabel>

                        <div style={{ marginBottom: 15 }}>
                          How your order appears to the influencer
                        </div>

                        <DropzoneField
                          file={values.thumbnail}
                          accept="image/jpg, image/jpeg, image/png"
                          setFile={file => setFieldValue('thumbnail', file)}
                          noPreview={
                            cart?.thumbnail_url && (
                              <div className={styles.imgContainer}>
                                <Image
                                  src={cart?.thumbnail_url}
                                  width={74}
                                  height={74}
                                  alt="Thumbnail"
                                  unoptimized
                                />
                              </div>
                            )
                          }
                          compressOptions={compressOptions}
                          compress
                        />

                        <span className={styles.error}>{errors.thumbnail}</span>

                        {!values.thumbnail && (
                          <span className="error-msg" style={noMarginTop}>
                            is required.
                          </span>
                        )}
                      </>
                    )}

                    {!profileOwner && (
                      <AdditionalOptions
                        space={space}
                        checked={checked}
                        caption={values.caption}
                        url={values.url}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        errors={errors}
                      />
                    )}

                    {space.event_attendance && !profileOwner && (
                      <EventAttendance
                        location={values.location}
                        travelIncluded={values.travel_included}
                        mealsIncluded={values.meals_included}
                        lodgingIncluded={values.lodging_included}
                        setFieldValue={setFieldValue}
                      />
                    )}

                    {!profileOwner && (
                      <SpecialInstructions
                        specialInstructions={values.special_instructions}
                        newSpecialInstructions={values.new_special_instructions_assets}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        item={cartItem}
                        isVideo={isVideo}
                        space={space}
                        checked={checked}
                        errors={errors}
                        deletedAssetIds={deletedAssetIds}
                        removeAsset={removeAsset}
                      />
                    )}

                    <div className={styles.footer}>
                      <span className={styles.price}>{price} </span>

                      {profileOwner ? (
                        <ShareInventoryItem
                          url={metaUrl}
                          title={`${space.name} | GigSocial`}
                          name={space.name}
                          disabled={digitalSignature && inventory?.assets?.length === 0}
                        />
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          endIcon={<CartAddIcon>send</CartAddIcon>}
                          onClick={() => handleSubmit(values, actions)}
                          disabled={
                            !isValid(values, errors) ||
                            (space.has_url && !isGG && !values.url) ||
                            (space.caption_name === 'Text' && !values.caption) ||
                            (digitalSignature && inventory?.assets?.length === 0)
                          }
                        >
                          {isLoading ? <CircularProgress size={30} /> : buttonText}
                        </Button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </TransitionDialog>
  );
};

OrderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  username: PropTypes.string,
  inventoryId: PropTypes.number,
  cartItem: PropTypes.object,
  fromInventory: PropTypes.bool,
};

OrderDialog.defaultProps = {
  cartItem: null,
  inventoryId: null,
  fromInventory: false,
  username: '',
};

OrderDialog.whyDidYouRender = true;

export default OrderDialog;
