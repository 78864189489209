import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Image from 'next/image';
import clsx from 'clsx';

import { closeModal } from 'features/utilsSlice';
import TransitionDialog from 'components/TransitionDialog';
import useModalOpen from 'hooks/useModalOpen';
import CardForm from 'components/cart/CardForm';
import PayPalForm from 'components/cart/PayPalForm';
import ApplePayForm from 'components/cart/ApplePayForm';
import GooglePayForm from 'components/cart/GooglePayForm';
import SavedCards from 'components/cart/SavedCards';
import styles from 'styles/components/cart/PaymentDialog.module.scss';

const optionWidthMapping = {
  card: 140,
  'apple-pay': 80,
  'google-pay': 80,
  'pay-pal': 120,
  bitcoin: 120,
  wallet: 80,
};

const paymentOptionComponent = {
  card: <CardForm />,
  'apple-pay': <ApplePayForm />,
  'google-pay': <GooglePayForm />,
  'pay-pal': <PayPalForm />,
  bitcoin: <></>,
};

const PaymentDialog = ({ paymentOption }) => {
  const [cardId, setCardId] = useState(null);
  const open = useModalOpen('Payment');
  const isLoggedIn = useSelector(state => state.auth.loggedIn);
  const dispatch = useDispatch();

  const handleClose = () => dispatch(closeModal('Payment'));

  return (
    <TransitionDialog open={open} handleClose={handleClose} styles={styles}>
      <div className={styles.checkout}>
        <h3>Checkout</h3>

        {paymentOption === 'card' && isLoggedIn && (
          <SavedCards cardId={cardId} setCardId={setCardId} />
        )}

        {!cardId && (
          <div className={styles.card}>
            <div className={styles.header}>
              <h4>{paymentOption?.toUpperCase()} DETAILS</h4>

              <div className={clsx(styles.icon, styles[paymentOption])}>
                <Image
                  src={`/icons/payment/${paymentOption}.svg`}
                  width={optionWidthMapping[paymentOption]}
                  height={39}
                  alt={paymentOption}
                  unoptimized
                />
              </div>
            </div>

            {paymentOptionComponent[paymentOption]}
          </div>
        )}
      </div>
    </TransitionDialog>
  );
};

export default PaymentDialog;
