import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import DocumentHead from 'components/DocumentHead';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { Grid, Button, InputAdornment, CircularProgress } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PhoneInput from 'react-phone-number-input';
import clsx from 'clsx';
import * as yup from 'yup';
import 'react-phone-number-input/style.css';
import isEmpty from 'lodash/isEmpty';

import { signIn } from 'features/authSlice';
import { postRequest } from 'utils/api';
import { closeModal, setFooter, setRoleFilter } from 'features/utilsSlice';
import { getTokenFromResponse, identifyFullStoryUser, setCrispUser } from 'utils/helpers';
import {
  saveAccessToken,
  setLastSignInRole,
  getLastSignInRole,
  setRedirectTo,
  getRedirectTo,
  getUuid,
  deleteUuid,
  setCurrentUserLS,
} from 'utils/localStorage';
import useScrollToTop from 'hooks/useScrollToTop';
import useModalOpen from 'hooks/useModalOpen';
import Password from 'public/icons/form/password.svg';
import PasswordEyeIcon from 'public/icons/eye.svg';
import SlashEyeIcon from 'public/icons/eye-hide-purple.svg';
import Email from 'public/icons/form/email.svg';
import TwoFactorCountdown from 'components/TwoFactorCountdown';
import SwitchWithThreeOptions from 'components/SwitchWithThreeOptions';
import styles from 'styles/components/auth/AuthForm.module.scss';

const defaultRequired = 'is required.';
const marginBottom = { marginBottom: 30 };
const width = { width: '100%' };

const schema = yup.object().shape({
  email: yup.string().when('role', role => {
    if (role === 'influencer') {
      return yup.string();
    }

    return yup
      .string()
      .email('is not a valid email.')
      .required(defaultRequired);
  }),

  phone: yup.number().when('role', role => {
    if (role === 'influencer') {
      return yup.number().required(defaultRequired);
    }

    return yup.number().nullable();
  }),

  password: yup.string().when('role', role => {
    if (role === 'fan') {
      return yup.string().nullable();
    }

    return yup
      .string()
      .required(defaultRequired)
      .min(6, 'Must contain at least 6 characters');
  }),

  checked: yup.boolean(),
  role: yup.string().required(),
  otp_attempt: yup.string(),
});

const initialValues = {
  phone: undefined,
  email: '',
  password: '',
  checked: false,
  role: 'influencer',
  otp_attempt: '',
};

const roleValueMapping = {
  influencer: 0,
  advertiser: 1,
  fan: 2,
};

const roleLabelMapping = {
  0: 'influencer',
  1: 'advertiser',
  2: 'fan',
};

const Login = ({ isDialog = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState('influencer');
  const theme = useTheme();
  const dispatch = useDispatch();
  const router = useRouter();
  const whichDialogOpen = useModalOpen('Which Are You');
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const redirectTo = getRedirectTo();
  const uuid = getUuid();

  useScrollToTop();

  const handleClickShowPassword = () => setShowPassword(prev => !prev);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleFanLogin = (email, actions) => {
    postRequest({
      endpoint: 'magic_links',
      data: { email },
      errorMessage: 'Invalid email. Please try again.',
      successMessage:
        'We have sent you a magic link to your email. Please check your inbox and follow the instructions to login.',
      version: null,
      ...actions,
    })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRegularLogin = async (phone, email, password, checked, otp_attempt, actions) => {
    try {
      const response = await postRequest({
        endpoint: 'sign_in',
        data: {
          user: {
            login: role === 'influencer' ? phone : email,
            password,
            remember_me: checked,
            otp_attempt,
          },
          uuid,
        },
        errorMessage:
          step === 1
            ? `Invalid ${role === 'influencer' ? 'phone' : 'email'} or password.`
            : 'Invalid one time password',
        version: null,
        ...actions,
      });

      const token = getTokenFromResponse(response);

      if (token) saveAccessToken(token);

      setLastSignInRole(response.data.role);

      dispatch(signIn(response.data));
      setCurrentUserLS(response.data);
      setCrispUser(response.data);
      identifyFullStoryUser(response.data);

      if (uuid) {
        deleteUuid();
        dispatch(closeModal('Login'));

        if (whichDialogOpen) dispatch(closeModal('Which Are You'));

        dispatch(setRoleFilter('advertiser'));
      } else {
        dispatch(setRoleFilter(response.data.role));
      }

      setIsLoading(false);

      if (isDialog) return;

      router.push(redirectTo || '/dashboard');

      if (redirectTo) setRedirectTo(null);
    } catch (error) {
      const message = error?.response?.data?.error;
      setIsLoading(false);

      if (message === 'Two-factor authentication required.') {
        setStep(2);
      }
    }
  };

  const handleSubmit = async ({ phone, email, password, checked, otp_attempt }, actions) => {
    setIsLoading(true);

    if (role === 'fan') {
      handleFanLogin(email, actions);
    } else {
      handleRegularLogin(phone, email, password, checked, otp_attempt, actions);
    }
  };

  useEffect(() => {
    const lastSignInRole = getLastSignInRole();

    if (lastSignInRole) {
      setRole(lastSignInRole);
    } else {
      setRole('influencer');
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      dispatch(setFooter(false));
    }

    return () => dispatch(setFooter(true));
  }, [dispatch, isMobile]);

  return (
    <Grid
      container
      className={clsx(styles.container, { [styles.paddingTop]: isDialog })}
      direction="column"
      alignItems="center"
    >
      <DocumentHead>
        <title>Login | GigSocial</title>
      </DocumentHead>

      <h3>LOGIN</h3>

      <h1 style={isDialog ? { fontSize: 24 } : {}}>Good to see you again!</h1>

      <Grid
        item
        xs={11}
        sm={6}
        md={isDialog ? 11 : 4}
        lg={isDialog ? 11 : 3}
        xl={isDialog ? 7 : 2}
        style={width}
      >
        <div style={marginBottom}>
          <SwitchWithThreeOptions
            labelOne="Influencer"
            labelTwo="Advertiser"
            labelThree="Fan"
            setValue={value => {
              setRole(roleLabelMapping[value]);
              setStep(1);
            }}
            value={roleValueMapping[role]}
          />
        </div>

        <Formik
          validationSchema={schema}
          initialValues={{ ...initialValues, role }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ handleBlur, setFieldValue, values, touched, errors, isSubmiting }) => (
            <Form className={styles.form}>
              {step === 1 && (
                <>
                  {role === 'influencer' ? (
                    <>
                      <PhoneInput
                        name="phone"
                        placeholder="Phone Number"
                        onChange={phone => setFieldValue('phone', phone)}
                        value={values.phone}
                        onBlur={handleBlur}
                        defaultCountry="US"
                        defaultChecked
                        className={clsx(styles.phoneInput, {
                          [styles.phoneInputError]: touched.phone && !values.phone,
                        })}
                      />
                      <ErrorMessage component="span" className={styles.error} name="phone" />
                    </>
                  ) : (
                    <Field
                      component={TextField}
                      name="email"
                      type="email"
                      placeholder="Email"
                      variant="outlined"
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email width={14} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}

                  {role !== 'fan' && (
                    <Field
                      component={TextField}
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      placeholder="Password"
                      variant="outlined"
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Password width={14} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <div
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              style={{ height: 20, cursor: 'pointer' }}
                            >
                              {showPassword ? (
                                <PasswordEyeIcon width={20} height={20} />
                              ) : (
                                <SlashEyeIcon width={20} height={20} />
                              )}
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}

                  {role !== 'fan' && (
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="checked"
                      Label={{ label: 'Remember me' }}
                      color="primary"
                    />
                  )}
                </>
              )}

              {step === 2 && (
                <div>
                  <Field
                    autoFocus
                    component={TextField}
                    name="otp_attempt"
                    type="text"
                    value={values.otp_attempt}
                    placeholder="OTP"
                    variant="outlined"
                    color="primary"
                  />

                  <TwoFactorCountdown />
                </div>
              )}

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading || isSubmiting || !isEmpty(errors)}
                fullWidth
                style={{ marginTop: 10 }}
              >
                {isLoading ? <CircularProgress color="primary" /> : 'Login'}
              </Button>
            </Form>
          )}
        </Formik>

        {!isDialog && (
          <div className={styles.bottomLinks}>
            <div>
              Don’t have an account?<Link href="/sign-up"> Sign Up!</Link>
            </div>

            <Link href="/forgot-password">Forgot password?</Link>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default Login;
