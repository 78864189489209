import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { signOut } from 'features/authSlice';
import { getMasquerading } from 'utils/localStorage';

const MasqueradeHeader = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [masquerading, setMasquerading] = useState(false);

  useEffect(() => {
    const masquerading = getMasquerading();
    setMasquerading(masquerading);
  }, []);

  const stopMasquerading = () => {
    setMasquerading(false);
    localStorage.removeItem('gig_social_masquerading');
    localStorage.removeItem('gig_social_access_token');
    dispatch(signOut());
    router.replace('/');
  };

  if (!masquerading) return null;

  return (
    <div className="acting-container" style={{ backgroundColor: '#c8ccd9' }}>
      <div style={{ opacity: 0.8 }}>
        You are currently masquerading as a different user -{' '}
        <span onClick={stopMasquerading} className="color-main hover">
          EXIT
        </span>
      </div>
    </div>
  );
};

export default MasqueradeHeader;
